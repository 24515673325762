import React, { useEffect, useState } from 'react';
import { useDebounce } from 'hooks/useDebounce'; // Adjust the import path as needed
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { Tooltip } from 'primereact/tooltip';

export const DiscrepancyFilter = ({ isGlobalFileManager, searchKey, setSearchKey, filterCase, setFilterCase }) => {
  const [searchInput, setSearchInput] = useState(searchKey);
  const debouncedSearch = useDebounce(searchInput, 300); // 300ms debounce delay

  // Update the actual searchKey when debounced value changes
  useEffect(() => {
    setSearchKey(debouncedSearch);
  }, [debouncedSearch, setSearchKey]);

  return (
    <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 col-12 FM-filter">
      <div className="filter-wrap d-flex align-items-center pe-2 ps-lg-2 ps-2 py-2 w-100">
        <div className="d-flex align-items-center filtersearch pe-lg-4 autowidth p-2 p-lg-0">
          <div className="p-input-icon-left input-shadow w-100 ">
            <i className="icon-search F-size16" />
            <TextSnippetWrapper
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              className="p-inputtext p-component input-search"
            />
          </div>
        </div>
        {isGlobalFileManager && (
          <div className="d-flex align-items-center ms-auto">
            <div className="text-bold black-600 ps-lg-0 pe-2 text-nowrap">Filters :</div>
            <div className="me-2 filter-wraper">
              <CaseListDropDown
                value={filterCase || null}
                onChange={(e) => setFilterCase(e)}
                placeholder="Case/Lead Name"
                noOptionsMessage="No Cases/Leads Found"
              />
            </div>
            <div className="pointer">
              <i
                className="fas fa-sync-alt reset"
                onClick={() => {
                  setSearchInput('');
                  setFilterCase(null);
                }}
              ></i>
              <Tooltip target=".reset" position="top">
                Reset
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DiscrepancyFilter;
