import React, { useRef, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { confirmDialog } from 'primereact/confirmdialog';

import NestedSubTask from './NestedSubTask';
import ImageComponent from 'shared/ImageComponent';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import { ModalHeader } from 'shared/ModalHeader';
import { assignToItemTemplate } from '../TaskBoard/Board/PriorityDropdownTemplate';
import { getTaskDetails } from 'services/CaseServices';
import {
  deepEqual,
  findArrayDifferences,
  formatContactList,
  formatStageName,
  getPriorityIcon,
  getUserStatusLabel,
  handleRedirection,
  parseRemiderPayload,
  parseReminderData,
  sortByKey,
} from 'utils/utils';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { useToggle } from 'hooks/useToggle';
import { getClientUserList } from 'services/case/caseServices';
import { getTenantIdFromLS } from 'utils/localStorageUtils';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';
import Reminders from 'modules/calendar/Components/CreateEventModal/Components/EventReminders';
import { generateFullName } from 'utils/generateFullNameUtils';

const tenantId = getTenantIdFromLS();

const paTemplate = (option) => {
  if (option?.status === 'ARCHIVED') {
    return `${option?.practice_area_name} (Archived)`;
  }
  return option?.practice_area_name;
};

const formatTaskdata = (data) => {
  const groups = data?.groups?.filter((v) => v?.group_id) || [];
  return {
    ...data,
    reminder: data?.reminder?.map((v) => ({ value: v })),
    assignTo: [
      ...data?.assign_to
        ?.filter((v) => Boolean((v?.cognito_username || v?.firm_user_id) && v?.is_individual_user))
        ?.map((v) => v?.assignee_id),
      ...groups.map((v) => v?.group_id),
    ],
    reminder: parseReminderData(data?.reminder),
    sub_task: data?.sub_task?.map((st) => ({ ...st, assign_to: st?.assign_to?.map((v) => v?.assignee_id), assignTo: st?.assign_to })),
  };
};

const ViewTask = (props) => {
  const { data } = props;
  const { handleCreateTasks, handleDeleteTask, updateTask, setSelectedTask, taskStageState, isFromCase, isClosed, isFromLead } =
    useTaskContext();
  const userContext = useUserDetailsContext();
  const { practiceAreaList } = usePracticeAreaList(true, 10, 'all');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({ defaultValues: formatTaskdata(data) });
  const menuRef = useRef();
  const history = useHistory();
  const isGlobal = !Boolean(isFromCase || isFromLead);

  const [editingFields, setEditingFields] = useState([]);
  const taskData = watch();
  const [contactList, setContactList] = useState([]);
  const [taskDetails, setTaskDetails] = useState();
  const [duplicate, setDuplicate] = useToggle(false);

  const contactswithoutGroups = contactList?.find((v) => v?.code === 'Users')?.items || [];

  useEffect(() => {
    getTaskDetails(data?.task_id).then((res) => {
      const formattedData = formatTaskdata({ ...res.data });
      setTaskDetails(formattedData);

      reset(formattedData);
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    const condition = Boolean(data?.case_id);
    getClientUserList({
      caseId: data?.case_id || null,
      is_mandatory_lawft_access: false,
      is_team_member_list: condition,
      all_contacts: condition,
      is_active: true,
      is_include_groups: true,
      active_groups: false,
    })
      // .then((response) => setContactList(formatContactList(response.data)))
      .then((response) => {
        let finalUserList = response?.data;
        // finalUserList = response?.data?.filter(
        //   (group) =>
        //     (group?.res_type === 'user-group' && group?.status === 'ARCHIVED' && taskDetails?.group_list.includes(group.user_group_id)) ||
        //     group?.status === 'ACTIVE' ||
        //     group?.res_type !== 'user-group'
        // );

        finalUserList = response?.data?.filter((user) => {
          const contactUserList = user.access_level === 'client';

          const isActiveUser = user?.is_confirmed && user?.status === 'ACTIVE';
          const isArchivedConfirmed = user.res_type !== 'user-group' && (user.status === 'ARCHIVED' || user.is_confirmed === false);

          const isIndividualUser = taskDetails?.assign_to?.some(
            (firmUser) => firmUser?.is_individual_user && firmUser?.cognito_username === user?.client_cognito_username
          );

          const isActiveGroup = user.res_type === 'user-group' && user.status === 'ACTIVE';
          const isArchiveGroupList =
            user.res_type === 'user-group' && user?.status === 'ARCHIVED' && taskDetails.group_list.includes(user.user_group_id);

          return contactUserList || isActiveUser || (isArchivedConfirmed && isIndividualUser) || isActiveGroup || isArchiveGroupList;
        });

        const formattedList = formatContactList(finalUserList);
        const sortedList = formattedList?.map((item) =>
          item?.code === 'Users' ? { ...item, items: sortByKey(item.items, 'first_name') } : item
        );
        setContactList(sortedList);
      })
      .catch((error) => console.log(error));
  }, [data?.case_id, taskDetails]);

  const onSubmit = (formData) => {
    const filteredSubTask = formData?.sub_task
      ?.filter((task) => Boolean(task.sub_task_description))
      ?.map((st) => {
        return {
          ...st,
          assign_to: contactswithoutGroups
            ?.filter((v) => st?.assign_to?.includes(v?.assignee_id))
            ?.map((user) => ({
              assignee_id: user?.client_cognito_username || user?.firm_user_id || user?.contact_id,
              assignee_name: generateFullName(user),
              email: user?.email,
              firm_user_id: user?.firm_user_id,
              access_level: user?.access_level,
              cognito_username: user?.client_cognito_username,
            })),
          assignTo: undefined,
        };
      });
    const practiceArea = Array.isArray(formData?.practice_area)
      ? formData?.practice_area
      : formData?.practice_area
      ? [formData?.practice_area]
      : [];
    let dataToSent = {
      case_id: formData.case_id,
      pk: formData.pk,
      sk: formData.sk,
      assign_to: formData?.assign_to,
      priority: formData.priority,
      practice_area: practiceArea,
      task_stage: formData.task_stage ?? 'to_do',
      accessible_list: formData?.assign_to?.map((v) => v.assignee_id),
      task_name: formData.task_name,
      reminder: parseRemiderPayload(formData?.reminder),
      due_date: convertToTimezoneFormat(formData.due_date, userContext?.userDetails?.timezone),
      case_name: formData?.case_name,
      description: formData?.description,
      sub_task: filteredSubTask,
      groups: formData?.groups,
      is_lead: isFromLead ? isFromLead : formData?.is_lead,
    };

    if (!deepEqual(data?.sub_task, filteredSubTask)) {
      // subtask was ticked (either partially completed or fully completed)
      // do task stage automation depending upon number of subtasks ticked/total subtasks
      // by determining the length of totoalSubTaskCompleted list
      let totoalSubTaskCompleted = formData?.sub_task?.filter((item) => item?.sub_task_status === true);
      if (totoalSubTaskCompleted?.length === 0) {
        dataToSent = {
          ...dataToSent,
          task_stage: data?.dynamic_task_stage ? data?.dynamic_task_stage : 'to_do',
        };
      } else if (totoalSubTaskCompleted?.length < formData?.sub_task?.length) {
        dataToSent = { ...dataToSent, task_stage: 'in_progress' };
      } else if (totoalSubTaskCompleted?.length === formData?.sub_task?.length) {
        dataToSent = { ...dataToSent, task_stage: 'done' };
      }
    }
    if (!duplicate) {
      updateTask(data?.task_id, dataToSent);
    } else handleCreateTasks(dataToSent);
    onHide();
  };

  const handleFieldClick = (fieldName) => {
    if (!editingFields.includes(fieldName) && !isClosed) {
      setEditingFields([...editingFields, fieldName]);
    }
  };

  const onHide = () => {
    setDuplicate(false);
    setSelectedTask(null);
  };

  const redirectToPage = (e, isLead = false) => {
    e.preventDefault();
    const { case_id } = data || {};
    let route = '';
    if (isLead) {
      route = case_id ? `/leads/${case_id}` : '/leads';
    } else {
      route = case_id ? `/cases/${case_id}` : '/cases';
    }

    handleRedirection(history, route);
  };

  const onClickTaskCaseName = (e) => {
    const { case_name, case_id, is_lead } = taskData;
    isFromLead ? e.preventDefault() : case_name === 'TBD' || is_lead ? redirectToPage(e, true) : redirectToPage(e);
    // case_name && case_id ? redirectToPage(e, is_lead) : e.preventDefault();
  };

  const updateAssignToField = (newArray) => {
    const oldArray = getValues('assignTo');
    const assign_to = getValues('assign_to') || [];

    const groups = getValues('groups') || [];
    const { added, removed } = findArrayDifferences(oldArray, newArray);
    const contacts = contactList.reduce((arr, val) => [...arr, ...val.items], []);

    if (added?.[0]) {
      const addedContact = contacts?.find((v) => v?.assignee_id === added?.[0] || v?.group_id?.includes(added?.[0]));

      if (addedContact?.group_id) {
        const { group_name, user_group_id, sk } = addedContact;
        setValue('groups', [
          ...groups,
          {
            group_name,
            group_id: user_group_id,
            sk: sk,
          },
        ]);
      } else if (addedContact?.assignee_id) {
        const { assignee_id, assignee_name, contact_id, email, client_cognito_username, firm_user_id, access_level } = addedContact;
        const contactIndex = assign_to?.findIndex((c) => c?.assignee_id === addedContact?.assignee_id);

        if (contactIndex !== -1) {
          setValue(
            'assign_to',
            assign_to.map((c, i) => {
              if (i === contactIndex) {
                return { ...c, is_individual_user: true };
              } else return c;
            })
          );
        } else {
          setValue('assign_to', [
            ...assign_to,
            {
              assignee_id,
              assignee_name,
              cognito_username: client_cognito_username,
              contact_id,
              firm_user_id,
              email,
              is_individual_user: true,
              access_level: access_level,
            },
          ]);
        }
      }
    }
    if (removed?.[0]) {
      const removedContact = contacts?.find((v) => v?.assignee_id === removed?.[0] || v?.group_id?.includes(removed?.[0]));
      if (removedContact?.group_id) {
        setValue(
          'groups',
          groups?.filter((g) => g.group_id !== removedContact.group_id)
        );
      } else if (removedContact?.assignee_id) {
        const newArray = [];

        assign_to.forEach((item) => {
          if (removedContact?.assignee_id === item.assignee_id && !!item?.is_group_user === false) {
            // Skip this item, effectively removing it
            return;
          }

          if (removedContact?.assignee_id === item.assignee_id && !!item?.is_group_user === true) {
            newArray.push({
              ...item,
              is_individual_user: false,
            });
          } else newArray.push(item);
        });
        setValue('assign_to', newArray);
      }
    }
  };

  const items = [
    {
      label: 'Delete',
      command: () => {
        confirmDialog({
          message: 'Do you want to delete this task?',
          header: 'Delete Confirmation',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          accept: () => handleDeleteTask(data),
        });
        onHide();
      },
    },
    {
      label: 'Duplicate',
      command: () => {
        handleFieldClick('task_name');
        setValue('task_name', getValues('task_name') + ' - Duplicate');
        setDuplicate(true);
      },
    },
  ];

  const RequiredValidation = ({ field }) => {
    return (
      <>{errors?.[field]?.type === 'required' && <InputErrorMessage>{errors?.[field]?.message || 'Field Required'}</InputErrorMessage>}</>
    );
  };

  return (
    <Dialog visible={true} onHide={onHide} header={ModalHeader} style={{ width: '40vw' }} footer={<></>} className="new-task-popup">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="col-lg-10 col-10 mb-3" onClick={() => handleFieldClick('task_name')}>
            <div className="modal-heading F-size16 text-bold">
              {editingFields.includes('task_name') ? (
                <TextSnippetWrapper
                  type="text"
                  className="border border-0 w-100 input-shadow"
                  placeholder="Enter Task Title"
                  {...register('task_name', { required: 'Task Title is required' })}
                />
              ) : (
                taskData.task_name
              )}
            </div>
            <RequiredValidation field={'task_name'} />
          </div>
          {!isClosed && (
            <div className="col-lg-2 col-2 d-flex justify-content-end align-items-center">
              <div className="p-d-flex p-ai-center">
                <i className="pi pi-ellipsis-v pointer" onClick={(e) => menuRef.current.toggle(e)} id="threedots" />
                <Menu model={items} popup ref={menuRef} id="popup_menu" />
              </div>
            </div>
          )}
        </div>

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Status <span className="alert-star">*</span>
          </div>
          <div className="col-md-8 col-12 edit-field" onClick={() => handleFieldClick('task_stage')}>
            {editingFields.includes('task_stage') ? (
              <Controller
                name="task_stage"
                control={control}
                rules={{ required: 'Status is Required' }}
                render={({ field }) => (
                  <Dropdown
                    options={taskStageState || []}
                    optionLabel={(v) => formatStageName(v?.task_stage_name)}
                    className="w-100 input-shadow F-size14 input-height"
                    optionValue="task_stage_name"
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
            ) : (
              formatStageName(taskData?.task_stage)
            )}
          </div>
          <RequiredValidation field={'task_stage'} />
        </div>
        {taskData?.case_name && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">Case</div>
            <div
              className={`col-md-8 col-12  ${
                taskData?.case_name && taskData?.case_id && !Boolean(isFromCase) && !isFromLead ? 'pointer text-primary-dark ' : ''
              }`}
              onClick={onClickTaskCaseName}
            >
              {taskData?.case_name}
            </div>
          </div>
        )}

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Assignees <span className="alert-star">*</span>
          </div>
          <div className="col-md-8 col-12">
            {editingFields.includes('assign_to') ? (
              <Controller
                name="assignTo"
                control={control}
                rules={{ required: 'Assign To is Required' }}
                render={({ field }) => (
                  <MultiSelect
                    options={contactList}
                    className="w-100 input-shadow F-size14 input-height"
                    filter
                    filterPlaceholder="Search"
                    placeholder="Select Assignee"
                    display="chip"
                    optionLabel="assignee_name"
                    optionValue={(v) => v?.assignee_id}
                    // optionDisabled={checkOptionDisabled}
                    itemTemplate={assignToItemTemplate}
                    value={field.value}
                    onChange={(e) => {
                      updateAssignToField(e.value);
                      field.onChange(e.value);
                    }}
                    showSelectAll={false}
                    id={field.name}
                    resetFilterOnHide
                    optionGroupLabel="assignee_name"
                    optionGroupChildren="items"
                  />
                )}
              />
            ) : (
              <>
                {taskData?.assign_to
                  ?.filter((v) => (v?.cognito_username || v?.firm_user_id) && v?.is_individual_user)
                  .map((item, i) => {
                    return (
                      <div
                        className="d-flex align-items-center py-1 p-avatar-clickable"
                        key={i}
                        onClick={() => handleFieldClick('assign_to')}
                      >
                        <ImageComponent
                          filePath={
                            item?.contact_id || item?.firm_user_id
                              ? `${tenantId}/${item?.contact_id ? item?.contact_id : item?.firm_user_id}/profile_image`
                              : null
                          }
                          fileName={item.assignee_name}
                          className="me-2"
                          style={{
                            backgroundColor: '#2196F3',
                            color: '#ffffff',
                            border: `2px solid ${item?.calendar_color ?? '#ffff'}`,
                          }}
                        />
                        {item?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
                        {item.assignee_name}
                        {getUserStatusLabel(item)}
                        <span>{item.assignee_id === taskData.created_by ? ' (created By)' : ''}</span>
                      </div>
                    );
                  })}
                {taskData?.groups?.map((item, i) => {
                  return (
                    <div
                      className="d-flex align-items-center py-1 p-avatar-clickable"
                      key={i}
                      onClick={() => handleFieldClick('assign_to')}
                    >
                      <ImageComponent
                        fileName={item.group_name}
                        className="me-2"
                        style={{ backgroundColor: '#2196F3', color: '#ffffff', border: `2px solid #ffff` }}
                      />
                      {/* {item.group_name} <span>{item.group_status === 'Deleted' ? ' (Deleted)' : ''}</span> */}
                      {item.group_name} <span>{item.group_status === 'ARCHIVED' ? '   (Archived)' : ''}</span>
                    </div>
                  );
                })}
              </>
            )}
            <RequiredValidation field={'assignTo'} />
          </div>
        </div>

        <div className="d-flex align-items-top py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium line_height3">Sub Tasks</div>
          <div className="col-md-8 col-12">
            <NestedSubTask {...{ control, register, errors, contactswithoutGroups }} />
          </div>
        </div>

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Priority <span className="alert-star">*</span>
          </div>
          <div className="col-md-8 col-12">
            {editingFields.includes('priority') ? (
              <Controller
                name="priority"
                control={control}
                rules={{ required: 'Priority is Required' }}
                render={({ field }) => (
                  <PriorityDropdown
                    className="input-shadow w-100 input-height"
                    filter
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
            ) : (
              <div onClick={() => handleFieldClick('priority')}>
                <i className={`${getPriorityIcon(taskData.priority)} pe-1`}></i>
                {taskData.priority}
              </div>
            )}
            <RequiredValidation field={'priority'} />
          </div>
        </div>

        {!Boolean(taskData?.case_name) && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 p-medium">Practice Area</div>
            <div className="col-md-8 col-12" onClick={() => handleFieldClick('practice_area')}>
              {editingFields.includes('practice_area') ? (
                <Controller
                  name="practice_area"
                  control={control}
                  render={({ field }) => {
                    const selectedValue = Array.isArray(field.value) ? field.value[0] : field.value;

                    return (
                      <Dropdown
                        className="input-shadow w-100 input-height"
                        filter
                        id={field.name}
                        value={selectedValue}
                        options={practiceAreaList?.filter((option) => field?.value?.includes(option.sk) || option.status === 'ACTIVE')}
                        optionLabel="practice_area_name"
                        optionValue="sk"
                        onChange={(e) => field.onChange([e.value])} // Convert back to array for consistency
                        showClear
                        optionDisabled={(item) => item?.status !== 'ACTIVE'}
                        itemTemplate={paTemplate}
                      />
                    );
                  }}
                />
              ) : (
                // <>{practiceAreaList?.find((item) => item?.sk === taskData?.practice_area)?.practice_area_name}</>
                <>
                  {Array.isArray(taskData?.practice_area) && taskData?.practice_area.length > 0
                    ? practiceAreaList?.find((item) => item?.sk === taskData?.practice_area[0])?.practice_area_name || '-'
                    : '-'}
                </>
              )}
            </div>
          </div>
        )}

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Due Date <span className="alert-star">*</span>
          </div>
          <div className="col-md-8 col-12" onClick={() => handleFieldClick('due_date')}>
            {editingFields.includes('due_date') ? (
              <Controller
                name="due_date"
                control={control}
                rules={{ required: 'Due Date is Required' }}
                render={({ field }) => (
                  <Calendar
                    className="filter-calender input-shadow"
                    dateFormat="mm/dd/yy"
                    showIcon
                    monthNavigator
                    yearNavigator
                    yearRange="2023:3000"
                    minDate={new Date()}
                    id={field.name}
                    value={moment(field.value).toDate()}
                    onChange={(e) => field.onChange(e.value)}
                  />
                )}
              />
            ) : (
              moment(taskData?.due_date).format('MM/DD/YYYY')
            )}
            <RequiredValidation field={'due_date'} />
          </div>
        </div>

        <div className="d-flex align-items-center py-2 flex-wrap">
          <Reminders control={control} watch={watch} isGlobal={isGlobal} />
        </div>
        <div className="d-flex align-items-start py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">Description</div>
          <div className="col-md-8 col-12">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextSnippetWrapper
                  type="textarea"
                  rows="5"
                  className="w-100 input-shadow"
                  id="description"
                  disabled={isClosed}
                  {...field}
                />
              )}
            />
          </div>
        </div>

        {!isClosed && (
          <div className="footer-btn">
            <Button type="reset" className="me-2 btn p-button-secondary outline" onClick={onHide}>
              Cancel
            </Button>
            <Button className="p-button-secondary me-2" type="submit">
              {duplicate ? 'Create' : 'Save'}
            </Button>
          </div>
        )}
      </form>
    </Dialog>
  );
};

export default ViewTask;
