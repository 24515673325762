import React from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';

import ListView from './ListView/ListView';
import GridView from './GridView/GridView';

import PreviewModal from './Modals/PreviewModal/PreviewModal';
import AddTagModal from './Modals/AddTagModal';
import ShareFileModal from './Modals/ShareFileModal';
import RenameModal from './Modals/RenameModal';
import MoveItemModal from './Modals/MoveItemModal';
import DeleteConfirmationModal from './Modals/DeleteConfirmationModal';
import BulkDeleteConfirmationModal from './Modals/BulkDeleteConfirmationModal';
import BulkMoveItemModal from './Modals/BulkMoveItemModal';

import FileUploadModal from './Modals/File Upload/FileUploadModal';
import FileVersionsModal from './Modals/FileVersionsModal/FileVersionsModal';
import CreateFolderModal from './Modals/CreateFolderModal';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import CreateDocumentModal from './Modals/CreateDocumentModal';
import SignatureModal from './Modals/SignatureModal/SignatureModal';
import SignatureStatusModal from './Modals/SignatureModal/signatureStatus';
import FileSummarizeModal from './Modals/SummarizeModal';
import DiscrepancyModal from './Modals/DiscrepancyModal/DiscrepancyModal';
import CreateCourtEventModal from './Modals/CourtNotice/CreateCourtEventModal';
// import MultiCheckOptions from './ListView/Templates/MultiCheckOptions';

function FileManagerView() {
  const {
    currentView,
    fileManagerFilters,
    getFiles,
    breadCrumbs,
    setBreadCrumbs,
    filterBreadCrumbs,
    modals,
    onFilterChange,
    pageLimit,
    isGlobalFileManager,
    caseDetails,
    isClientFileManager,
    query,
    // selectedRows,
    // setFileManagerFilters,
  } = useFileManagerContext();

  const {
    showPreview,
    showShareFileModal,
    showAddTag,
    showRenameModal,
    moveItemModal,
    showDeleteModal,
    showBulkDeleteModal,
    showBulkMoveModal,
    showFileUploadModal,
    showUploadedVersionsModal,
    showCreateFolderModal,
    showCreateDocumentModal,
    showSignatureModal,
    showEsignStatusModal,
    showSummarizeModal,
    showDiscrepancyModal,
    showCreateEventModal,
  } = modals;

  const home = {
    icon: 'pi pi-home',
    command: (e) => {
      // setSelectedRowData("");

      let obj = {
        case_id: isGlobalFileManager ? fileManagerFilters?.case : caseDetails?.case_id,
        selectedFolder: fileManagerFilters.selectedFolder,
        selectedType: fileManagerFilters.fileType,
        tag: fileManagerFilters.tag,
        query: query,
        folder_id: 'home',
        fileStatus: fileManagerFilters?.fileStatus,
      };
      // getFiles(obj, currentView, pageLimit);
      let data = {
        target: {
          name: 'selectedFolder',
        },
        value: e.object_id,
      };
      // setFileManagerFilters();
      onFilterChange(data, clearBreadcrumbs);
    },
  };

  function clearBreadcrumbs() {
    setBreadCrumbs([]);
  }

  return (
    <>
      <BreadCrumb
        // className="d-none"
        model={breadCrumbs?.map((item) => {
          item.command = (e) => {
            // setSelectedRowData("");

            let obj = {
              case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters.case : caseDetails?.case_id,
              selectedFolder: e.item.value,
              selectedType: fileManagerFilters.fileType,
              tag: fileManagerFilters.tag,
              query: query,
              folder_id: e.item.value,
              fileStatus: fileManagerFilters?.fileStatus,
            };
            getFiles(obj, currentView, pageLimit);
            filterBreadCrumbs(e.item.value);
          };
          return item;
        })}
        home={home}
      />
      {/* {selectedRows.length > 0 && <MultiCheckOptions />} */}

      <div className="rounded casefile-wrap footer-pb datatable-responsive">{currentView === 'list' ? <ListView /> : <GridView />}</div>
      {showPreview && <PreviewModal />}
      {showAddTag && <AddTagModal />}
      {showShareFileModal && <ShareFileModal />}
      {showRenameModal && <RenameModal />}
      {moveItemModal && <MoveItemModal />}
      {showDeleteModal && <DeleteConfirmationModal />}
      {showBulkDeleteModal && <BulkDeleteConfirmationModal />}
      {showBulkMoveModal && <BulkMoveItemModal />}

      {showFileUploadModal && <FileUploadModal />}
      {showUploadedVersionsModal && <FileVersionsModal />}
      {showCreateFolderModal && <CreateFolderModal />}
      {showCreateDocumentModal && <CreateDocumentModal />}
      {showDiscrepancyModal && <DiscrepancyModal />}
      {showSignatureModal && <SignatureModal />}
      {showEsignStatusModal && <SignatureStatusModal />}
      {showSummarizeModal && <FileSummarizeModal />}
      {showCreateEventModal && <CreateCourtEventModal />}
    </>
  );
}

export default FileManagerView;
