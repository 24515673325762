import React from 'react';

import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';

import ImageComponent from 'shared/ImageComponent';

import { getTenantIdFromLS } from 'utils/localStorageUtils';
import { handleRedirection } from 'utils/utils';

function AttendeesPreview(props) {
  const { selectedGroups, selectedFirmUsers, selectedContacts, history } = props;
  const tenantId = getTenantIdFromLS();
  const dataPreview = (data, type) => {
    const renderNameColumn = (item) => {
      switch (type) {
        case 'groups':
          return (
            <div
              className="d-flex text-primary-dark text-decoration-none cursor-pointer"
              onClick={() => {
                let url = '/settings/groups';
                handleRedirection(history, url);
              }}
            >
              <ImageComponent
                filePath={`${tenantId}/${item?.client_cognito_username}/profile_image`}
                fileName={item.group_name}
                fileSize="small.jpg"
              />
              <div className="mx-2 align-items-center d-flex">{item.group_name}</div>
            </div>
          );
        case 'firm_users':
          return (
            <div
              className="d-flex redirection-link-no-text-transform"
              onClick={() => {
                const url = item?.firm_user_id ? `/settings/team-management/${item.firm_user_id}` : '/settings/team-management';
                handleRedirection(history, url);
              }}
            >
              <ImageComponent filePath={item?.profile_image} fileName={item.name} fileSize="small.jpg" />
              <div className="mx-2 align-items-center d-flex">{item.name}</div>
            </div>
          );
        default:
          return (
            <div
              className="d-flex redirection-link-no-text-transform"
              onClick={() => {
                let url = `/contacts`;
                if (item?.contact_id) url = `/contacts/${item?.contact_id}`;
                handleRedirection(history, url);
              }}
            >
              <ImageComponent filePath={item?.profile_image} fileName={item.name} fileSize="small.jpg" />
              <div className="mx-2 align-items-center d-flex">{item.name}</div>
            </div>
          );
      }
    };
    return data.map((item, id) => {
      return (
        <tbody key={id}>
          <td className="attendee-names">{renderNameColumn(item)}</td>
          <td className="text-center">
            <div>
              <RadioButton
                inputId="required"
                name={`is_attendance_required`}
                value="required"
                checked={item.is_attendance_required === 'required'}
                className="disabledCursor"
                disabled={true}
              />
            </div>
          </td>
          <td className="text-center">
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="optional"
                name="gender"
                value="optional"
                checked={item.is_attendance_required === 'optional'}
                className="disabledCursor"
                disabled={true}
              />
            </div>
          </td>
          <td className="text-center">
            <InputSwitch
              className="disabledCursor"
              id={'send_reminder'}
              value={item.send_reminder}
              checked={item.send_reminder}
              placeholder="Meeting URL"
              disabled={true}
            />
          </td>
        </tbody>
      );
    });
  };
  return (
    <>
      {Boolean(selectedGroups?.length) && (
        <div className="d-flex align-items-center py-2">
          <div className="col-12">
            <table className="table attendees-table">
              <thead>
                <tr>
                  <th className="attendee-names text-muted">Groups</th>
                  <th className="text-center">
                    <label className="text-muted">Required</label>
                  </th>
                  <th className="text-center">
                    <label className="text-muted">Optional</label>
                  </th>
                  <th className="text-center">
                    <label className="text-muted">Send Reminder</label>
                  </th>
                </tr>
              </thead>
              {dataPreview(selectedGroups, 'groups')}
            </table>
          </div>
        </div>
      )}
      {Boolean(selectedFirmUsers?.length) && (
        <div className="d-flex align-items-center py-2">
          <div className="col-12">
            <table className="table attendees-table">
              <thead>
                <tr>
                  <th className="attendee-names text-muted">Firm Users</th>
                  <th className="text-center">
                    <label className="text-muted">Required</label>
                  </th>
                  <th className="text-center">
                    <label className="text-muted">Optional</label>
                  </th>
                  <th className="text-center">
                    <label className="text-muted">Send Reminder</label>
                  </th>
                </tr>
              </thead>
              {dataPreview(selectedFirmUsers, 'firm_users')}
            </table>
          </div>
        </div>
      )}
      {Boolean(selectedContacts?.length) && (
        <div className="d-flex align-items-center py-2">
          <div className="col-12">
            <table className="table attendees-table">
              <thead>
                <tr>
                  <th className="attendee-names text-muted">Contacts</th>
                  <th className="text-center">
                    <label className="text-muted">Required</label>
                  </th>
                  <th className="text-center">
                    <label className="text-muted">Optional</label>
                  </th>
                  <th className="text-center">
                    <label className="text-muted">Send Reminder</label>
                  </th>
                </tr>
              </thead>
              {dataPreview(selectedContacts, 'contacts')}
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default AttendeesPreview;
