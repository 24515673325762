import React from 'react';
import { Avatar } from 'primereact/avatar';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { timeDifference } from 'services/notification/notificationService';
import { useUserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';

const NotificationContent = ({ data, markAsRead, isClient }) => {
  const { userDetails } = useUserDetailsContext();
  const notificationClientContent = () => {
    const { notification_type } = data || {};
    if (notification_type === 'invitation') {
      return (
        <div className={data?.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0 '}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <i className="fas fa-tasks mt-1 text-muted px-3"></i>
              <div className="d-flex">
                <div className="d-flex">
                  <div className="d-flex flex-wrap">
                    {data?.body?.event_name && (
                      <div className="mx-2">
                        <span className={data.is_read ? '' : 'fw-bold'}>
                          Invitation for the event <b>{data.body.event_name}</b>{' '}
                        </span>
                      </div>
                    )}

                    {data?.body?.event_time && (
                      <div className="mx-2">
                        <span className={data.is_read ? 'text-hint black-500 ms-0' : 'text-hint black-500 ms-0 text-bold'}>
                          {handleDateTimeOffset(
                            userDetails.timezone,
                            data?.body?.event_time,
                            constants.month_date_year_time_seconds_12_format
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between ps-2 mt-2">
                {data?.body?.case_name && (
                  <div className="ms-4">
                    <span className={data?.is_read ? '' : 'text-bold'}>{data?.body?.case_name}</span>
                  </div>
                )}
                {data?.time && (
                  <div className="text-muted small">
                    <span className={data.is_read ? 'text-hint black-500 ms-0' : 'text-hint black-500 ms-0 text-bold'}>
                      {/* {timeDifference(new Date(data.time + 'Z'), new Date())} ago */}
                      {timeDifference(new Date(data?.time), new Date())} ago
                    </span>
                  </div>
                )}
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'reminder') {
      return (
        <div className={data.is_read ? 'p-2' : 'unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="col-12 col-sm-9 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="far fa-calendar-alt text-muted px-3 mt-2 align-self-start"></i>
                <div className="">
                  {data?.body && (
                    <div className="mx-2">
                      <b> {data.body.message}</b>
                    </div>
                  )}
                  {data?.body && data?.body?.event_name && (
                    <div className="mx-2">
                      <b> {data.body.event_name}</b>
                    </div>
                  )}
                  {data?.body?.due_date && (
                    <div className="mx-2">
                      <span className={data.is_read ? '' : 'text-bold'}>
                        Due Date :{' '}
                        {handleDateTimeOffset(userDetails.timezone, data.body.due_date, constants.month_date_year_time_seconds_12_format)}
                      </span>
                    </div>
                  )}
                  {data?.body && data?.body?.event_time && (
                    <div className="mx-2">
                      {handleDateTimeOffset(userDetails?.timezone, data.body.event_time, constants.month_date_year_time_seconds_12_format)}
                    </div>
                  )}

                  <div className="d-flex flex-column">
                    {data?.body?.case_name && data?.body?.case_name !== '-' && <div className="ms-2">{data?.body?.case_name}</div>}

                    {data?.time && (
                      <div className="text-mutted small mx-2">
                        <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    markAsRead(e, data);
                  }}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'secure_message') {
      return (
        <div className={data.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-9">
              <div className="d-flex">
                {data?.body?.created_by_name?.first_name && (
                  <Avatar
                    label={data?.body?.created_by_name?.first_name?.charAt(0)}
                    className="me-3 mt-1 ms-1"
                    size="normal"
                    style={{ backgroundColor: '#9c27b0', color: '#ffffff' }}
                    shape="circle"
                  />
                )}
                <span>
                  <span className="text-bold">{`${data.body.created_by_name.first_name} ${data.body.created_by_name.last_name}`}</span>
                  <span className="fw-normal"> send a new message</span>
                  {data?.time && (
                    <div className="text-mutted small">
                      {/* <span>{timeDifference(new Date(data?.body?.timestamp + ' UTC'), new Date())} ago</span> */}
                      <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                    </div>
                  )}
                </span>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'assignment') {
      return (
        <div className={data?.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <i className="fas fa-tasks mt-1 text-muted px-3"></i>
              <div>
                <div className="d-flex flex-wrap">
                  {data?.body?.task_name && (
                    <div className="mx-2">
                      <span className={data.is_read ? '' : 'text-bold'}>
                        A task has been assigned to you: <span className="text-bold">{data.body.task_name}</span>{' '}
                      </span>
                    </div>
                  )}

                  {data?.body?.due_date && (
                    <div className="mx-2">
                      <span className={data.is_read ? '' : 'text-bold'}>
                        Due Date :{' '}
                        {handleDateTimeOffset(userDetails.timezone, data.body.due_date, constants.month_date_year_time_seconds_12_format)}
                      </span>
                    </div>
                  )}
                </div>
                {/* TODO: "-" should be handled when creating task */}
                {data?.body?.case_name && data?.body?.case_name !== '-' && (
                  <div className="ms-2">
                    <span>{data.body.case_name + ' Case'}</span>
                  </div>
                )}
              </div>
            </div>
            {!data?.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'ai_summary_message') {
      return (
        <div className={data.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-9 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="far fa-calendar-alt text-muted px-3 mt-2 align-self-start"></i>
                <div className="">
                  {data?.body && (
                    <div className="mx-2">
                      <b> {'AI File Summary'}</b>
                    </div>
                  )}
                  {data?.body && data?.body?.summary?.file_name && (
                    <div className="mx-2">
                      <b> {data.body.summary.file_name}</b>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    markAsRead(e, data);
                  }}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (data?.body?.message) {
      return (
        <div className={data?.is_read ? 'p-2 border' : 'p-2 border unread-notification rounded-0 border-0'}>
          <div className="d-flex align-items-center">
            <div className="align-self-start">
              {data?.body?.contact_name || data?.body?.client_name ? (
                <span className="ms-2">
                  <Avatar
                    label={data?.body?.contact_name?.charAt(0) || data?.body?.client_name?.charAt(0)}
                    className="me-1 mt-1 "
                    size="normal"
                    style={{
                      backgroundColor: '#9c27b0 !important',
                      color: '#fff !important',
                    }}
                    shape="circle"
                  />
                </span>
              ) : (
                <i className="fa fa-regular fa-bell text-muted px-3 mt-1"></i>
              )}
            </div>

            <div className="ms-2">
              {data?.body?.message || null}
              {data?.body?.case_name && (
                <div>
                  <div>
                    Case: <span className="text-bold">{data?.body?.case_name}</span>
                  </div>

                  {data?.time && (
                    <div className="text-mutted small">
                      <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                    </div>
                  )}
                </div>
              )}

              {data?.body?.updated_at && (
                <div className="text-mutted small mx-4 justify-content-end">
                  <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.body?.updated_at), new Date())} ago</span>
                </div>
              )}
              {data?.body?.created_at && (
                <div className="text-mutted small mx-4 justify-content-end">
                  <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.body?.created_at), new Date())} ago</span>
                </div>
              )}
            </div>

            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  const notificationContent = () => {
    const { notification_type } = data || {};
    if (notification_type === 'invitation') {
      return (
        <div className={data?.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <i className="fas fa-tasks mt-1 text-muted px-3"></i>
              <div className="d-flex">
                <div className="d-flex">
                  <div>
                    {data?.body?.event_name && (
                      <div className="mx-2">
                        <span className={data?.is_read ? '' : 'text-bold'}>
                          Invitation for the event <b>{data.body.event_name}</b>{' '}
                        </span>
                      </div>
                    )}

                    {data?.body?.event_time && (
                      <div className="mx-2">
                        <span className={data.is_read ? '' : 'text-bold'}>
                          {handleDateTimeOffset(
                            userDetails.timezone,
                            data?.body?.event_time,
                            constants.month_date_year_time_seconds_12_format
                          )}
                        </span>
                      </div>
                    )}

                    {data?.body?.case_name && (
                      <div className="mx-2">
                        <span>{data?.body?.case_name}</span>
                      </div>
                    )}

                    {data?.time && (
                      <div className="text-muted small mx-2">
                        <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    markAsRead(e, data);
                  }}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'reminder') {
      return (
        <div className={data.is_read ? 'p-2' : 'unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="col-12 col-sm-9 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="far fa-calendar-alt text-muted px-3 mt-2 align-self-start"></i>
                <div className="">
                  {data?.body && (
                    <div className="mx-2">
                      <b> {data.body.message}</b>
                    </div>
                  )}
                  {data?.body && data?.body?.event_name && (
                    <div className="mx-2">
                      <b> {data.body.event_name}</b>
                    </div>
                  )}
                  {data?.body?.due_date && (
                    <div className="mx-2">
                      <span className={data.is_read ? '' : 'text-bold'}>
                        Due Date :{' '}
                        {handleDateTimeOffset(userDetails.timezone, data.body.due_date, constants.month_date_year_time_seconds_12_format)}
                      </span>
                    </div>
                  )}
                  {data?.body && data?.body?.event_time && (
                    <div className="mx-2">
                      {handleDateTimeOffset(userDetails?.timezone, data.body.event_time, constants.month_date_year_time_seconds_12_format)}
                    </div>
                  )}

                  <div className="d-flex flex-column">
                    {data?.body?.case_name && data?.body?.case_name !== '-' && <div className="ms-2">{data?.body?.case_name}</div>}

                    {data?.time && (
                      <div className="text-mutted small mx-2">
                        <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    markAsRead(e, data);
                  }}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'secure_message') {
      return (
        <div className={data.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-9">
              <div className="d-flex">
                {data?.body?.created_by_name?.first_name && (
                  <Avatar
                    label={data?.body?.created_by_name?.first_name?.charAt(0)}
                    className="me-3 mt-1 ms-1"
                    size="normal"
                    style={{ backgroundColor: '#9c27b0', color: '#ffffff' }}
                    shape="circle"
                  />
                )}
                <span>
                  <span className="text-bold">{`${data.body.created_by_name.first_name} ${data.body.created_by_name.last_name}`}</span>
                  <span className="fw-normal"> send a new message</span>
                  {data?.time && (
                    <div className="text-mutted small">
                      <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                    </div>
                  )}
                </span>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'sidebar_message') {
      return (
        <div className={data.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-9">
              <div className="d-flex">
                <div className="align-self-start">
                  <i className="fa fa-regular fa-bell text-muted px-3 mt-1 me-2"></i>
                </div>
                {/* <Avatar
                      label={data?.body?.created_by_name?.first_name?.charAt(0)}
                      className="me-1 mt-1 "
                      size="normal"
                      style={{ backgroundColor: '#9c27b0', color: '#ffffff' }}
                      shape="circle"
                    /> */}
                <span>
                  {data?.case_description?.case_description?.case_name && (
                    <span className="text-bold">Case: {`${data?.case_description?.case_description?.case_name}`}</span>
                  )}
                  <br />
                  <span className="fw-normal">New sidebar message received</span>
                  {data?.time && (
                    <div className="text-mutted small">
                      <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                    </div>
                  )}
                </span>
                {/* )} */}
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'assignment') {
      return (
        <div className={data?.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <i className="fas fa-tasks mt-1 text-muted p-3 pt-1"></i>
              <div className="d-flex">
                <div className="d-flex">
                  <div className="d-flex flex-wrap">
                    {data?.body?.task_name && (
                      <div className="mx-2">
                        <span className={data.is_read ? '' : 'text-bold'}>
                          A task has been assigned to you: <span className="text-bold">{data.body.task_name}</span>{' '}
                        </span>
                      </div>
                    )}

                    {data?.body?.due_date && (
                      <div className="mx-2">
                        <span className={data.is_read ? '' : 'text-bold'}>
                          Due Date :{' '}
                          {handleDateTimeOffset(userDetails.timezone, data.body.due_date, constants.month_date_year_time_seconds_12_format)}
                        </span>
                      </div>
                    )}
                    <div className="ms-2">
                      {/* TODO: "-" should be handled when creating task */}
                      {data?.body?.case_name && data?.body?.case_name !== '-' && <div>{data.body.case_name + ' Case'}</div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!data?.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'automation_error_logs') {
      if (data?.body) {
        let { message, count } = data?.body || {};

        if (message && count) {
          message = `${message} (${count})`;
        }

        if (message) {
          return (
            <div className={data?.is_read ? 'p-0 notification-msg' : 'p-0 unread-notification rounded-0 border-0'}>
              <div className="d-flex justify-content-between">
                <div className="align-self-start">
                  <i className="fa fa-regular fa-bell text-muted px-3 mt-1 me-2"></i>
                </div>
                <div className="">{message || 'N/A'}</div>

                {!data.is_read && (
                  <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                    <a
                      href="/"
                      onClick={(e) => markAsRead(e, data)}
                      className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                    >
                      Mark as read
                    </a>
                  </div>
                )}
              </div>
            </div>
          );
        }
      }
    } else if (notification_type === 'ai_summary_message') {
      return (
        <div className={data.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-9 d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="far fa-calendar-alt text-muted px-3 mt-2 align-self-start"></i>
                <div className="">
                  {data?.body && (
                    <div className="mx-2">
                      <b> {'AI File Summary'}</b>
                    </div>
                  )}
                  {data?.body && data?.body?.summary?.file_name && (
                    <div className="mx-2">
                      <b> {data.body.summary.file_name}</b>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    markAsRead(e, data);
                  }}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'incoming_email') {
      return (
        <div className={data.is_read ? 'p-2 notification-msg' : 'p-2 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between">
            <div className="col-12 col-sm-9">
              <div className="d-flex">
                <Avatar
                  label={data?.body?.email_from?.user_email?.charAt(0)}
                  className="me-3 me-1 mt-1 "
                  size="normal"
                  style={{ backgroundColor: '#9c27b0', color: '#ffffff' }}
                  shape="circle"
                />
                <span>
                  <span className="text-bold">{`${data?.body?.email_from?.user_name || data?.body?.email_from?.user_email}`}</span>
                  <span className="fw-normal"> send a new email</span>
                  <div className="text-mutted small">
                    <span className="text-hint black-500 ms-0">
                      {timeDifference(new Date(data?.time || data?.body?.timestamp), new Date())} ago
                    </span>
                  </div>
                </span>
              </div>
            </div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (data?.body?.message) {
      return (
        <div className={data?.is_read ? 'notification-msg' : 'unread-notification rounded-0 border-0'}>
          <div className=" d-flex  align-items-center">
            <div className="align-self-start">
              {data?.body?.contact_name || data?.body?.client_name ? (
                <span className="ms-2">
                  <Avatar
                    label={data?.body?.contact_name?.charAt(0) || data?.body?.client_name?.charAt(0)}
                    className="me-1 mt-1 "
                    size="normal"
                    style={{
                      backgroundColor: '#9c27b0 !important',
                      color: '#fff !important',
                    }}
                    shape="circle"
                  />
                </span>
              ) : (
                <i className="fa fa-regular fa-bell text-muted px-3 mt-1"></i>
              )}
            </div>
            <div className="ms-2">
              <div className="d-flex">
                {data?.body?.contact_name && (
                  <span className="">
                    <span className="text-bold">{data?.body?.contact_name}</span>
                  </span>
                )}
              </div>

              {data?.body?.message && data?.body?.message === 'Inbound text message' ? (
                <div className="">
                  <span>
                    Text Message Received from <span className="text-bold">{data?.body?.client_name}</span>
                  </span>
                  {data?.time && <div className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</div>}
                </div>
              ) : (
                data?.body?.message
              )}

              <div className="d-flex justify-content-between">
                {data?.body?.case_name && (
                  <div>
                    <div>
                      Case: <span className="text-bold">{data?.body?.case_name}</span>
                    </div>

                    {data?.time && (
                      <div className="text-mutted small">
                        <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.time), new Date())} ago</span>
                      </div>
                    )}
                  </div>
                )}

                {data?.body?.updated_at && (
                  <div className="text-mutted small justify-content-end">
                    <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.body?.updated_at), new Date())} ago</span>
                  </div>
                )}
                {data?.body?.created_at && (
                  <div className="text-mutted small justify-content-end">
                    <span className="text-hint black-500 ms-0">{timeDifference(new Date(data?.body?.created_at), new Date())} ago</span>
                  </div>
                )}
              </div>
            </div>

            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    } else if (notification_type === 'message_viewed_by') {
      return (
        <div className={data?.is_read ? 'p-0 notification-msg' : 'p-0 unread-notification rounded-0 border-0'}>
          <div className="d-flex justify-content-between ms-2">
            <div className="align-self-start">
              <i className="fa fa-regular fa-bell text-muted px-3 mt-1"></i>
            </div>
            <div>{data?.body?.viewed_by_details?.viewed_by ? `Message viewed by ${data?.body?.viewed_by_details?.viewed_by}` : 'N/A'}</div>
            {!data.is_read && (
              <div className=" text-end align-items-center d-flex mark-read text-nowrap">
                <a
                  href="/"
                  onClick={(e) => markAsRead(e, data)}
                  className="flex-grow-1 text-end F-size12 text-mutted text-decoration-none ms-1"
                >
                  Mark as read
                </a>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return <>{isClient ? notificationClientContent() : notificationContent()}</>;
};

export default NotificationContent;
