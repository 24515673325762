/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import useHandleFilterChange from '../hooks/useHandleFilterChange';
import useGetFiles from '../hooks/useGetFiles';
import useBreadCrumbs from '../hooks/useBreadCrumbs';
import useHandleSelectedRows from '../hooks/useHandleSelectedRows';

import constants, { defaultCaseFilter } from 'constants/index';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useNavbarContext } from 'context/NavbarContext';
import { presignedURLGet } from '../services';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { useDebounce } from 'hooks/useDebounce';
import { convertCaseToFilterFormat } from 'utils/utils';
import { totalCountEvent } from 'services/generalServices';
import { listGlobalTags } from 'services/Settings';
import useGetTags from '../hooks/useGetTags';
import { courtDateEvent, summarizeDocument } from 'services/fileManager/fileManagerServices';

const FileMangerContext = createContext({});

export const useFileManagerContext = () => {
  const context = useContext(FileMangerContext);

  if (context === undefined) {
    throw new Error('useTestContext must be used within TestProvider');
  }

  return context;
};

export const FileManagerContextProvider = (props) => {
  const {
    isGlobalFileManager,
    isCaseFileManager,
    isLeadFileManager,
    isClientFileManager,
    caseDetails,
    isClosed,
    onMountUnMount,
    onOperationBreak,
  } = props;

  const userContext = useContext(UserDetailsContext);
  const { readClientNotifications, notificationCounts } = useNavbarContext();
  const location = useLocation();
  const { addToast } = useToast();

  const getDefaultFilter = () => {
    let filter = {
      ...defaultCaseFilter,
      case: convertCaseToFilterFormat(userContext?.clientSelectedCase) || '',
      selectedFolder: location?.state?.breadCrumb ? location?.state?.breadCrumb?.value : '',
    };
    let file = location?.state?.file;
    if (location?.state?.isFromClientDashboard && file) {
      const caseVal = { label: file?.case_name, id: file?.case_id };
      filter.case = caseVal;
    }
    return filter;
  };
  // hooks
  const { fileManagerFilters, onFilterChange, onTagChange, setFileManagerFilters } = useHandleFilterChange(getDefaultFilter);
  const { files, getFiles, pageLimit, setPageLimit, paginationDirection, onNextPageClick, onPrevPageClick, nextToken, transitionToken } =
    useGetFiles(isGlobalFileManager, isCaseFileManager, isLeadFileManager, isClientFileManager, userContext);
  const { getFileTags, fullTags } = useGetTags();

  const { breadCrumbs, setBreadCrumbs, handleBreadCrumbs, filterBreadCrumbs } = useBreadCrumbs();
  const { selectedRows, selectAll, handleSingleCheckboxClick, handleSelectAllCheckboxClick, setSelectedRows } = useHandleSelectedRows();

  //states
  const [currentView, setCurrentView] = useState('list');
  const [selectedItem, setSelectedItem] = useState();
  const [fileName, setFileName] = useState('');
  const [deletePermission, setDeletePermission] = useState(true);
  const [versionFileUpload, setVersionFileUpload] = useState(false);
  const [previewList, setPreviewList] = useState([]);
  const [selectedPreviewItem, setSelectedPreviewItem] = useState();
  const [query, setQuery] = useState('');
  const [valueSelected, setValueSelected] = useState(false);

  // modal states
  const [showEsignStatusModal, setEsignStatusModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showAddTag, setShowAddTag] = useState(false);
  const [tagModalType, setTagModalType] = useState('');
  const [showShareFileModal, setShowShareFileModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [moveItemModal, setMoveItemModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const [showBulkMoveModal, setShowBulkMoveModal] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showUploadedVersionsModal, setShowUploadedVersionsModal] = useState(false);
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false);
  const [showCreateDocumentModal, setShowCreateDocumentModal] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [globalTags, setGlobalTags] = useState(null);
  const [showSummarizeModal, setShowSummarizeModal] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [showDiscrepancyModal, setShowDiscrepancyModal] = useState(false);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [courtDateMsg, setCourtDateMsg] = useState(null);

  const filterDebounced = useDebounce(query, 400);

  //For global search redirection and file preview
  useEffect(() => {
    if (location?.state?.file?.file_path) {
      let file = location?.state?.file;
      // need to make additional changes in report generator page to get file url
      presignedURLGet(file?.file_path, file?.version_id, false)
        .then((res) => {
          file.url = res.data?.file_url;
          setShowPreview(true);
          setSelectedItem(file);
          setSelectedPreviewItem(file);
          setPreviewList([file]);
          // setQuery(file?.display_name || '');
        })
        .catch((err) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, 'File Not Found');
        });
    }
    if (location?.state?.breadCrumbs) {
      setBreadCrumbs([{ label: location?.state?.breadCrumb?.label, value: location?.state?.breadCrumb?.value }]);
    }
  }, [location?.state]);

  useEffect(() => {
    if (userContext.permissions) {
      if (userContext.getPermission('cases') !== constants.permissions.WRITE && props.casePage) {
        setDeletePermission(false);
      } else if (userContext.getFirmPermission(constants.permissions.DELETE_ITEMS) === false) {
        setDeletePermission(false);
      } else {
        setDeletePermission(true);
      }
    }
  }, [userContext.permissions]);

  //Client portal notification mark as complete
  useEffect(() => {
    if (userContext?.userDetails?.contact_id && isClientFileManager && notificationCounts) {
      Boolean(notificationCounts?.file_count) && readClientNotifications('file', userContext.userDetails.contact_id);
    }
  }, [userContext?.userDetails, notificationCounts, isClientFileManager]);

  useEffect(() => {
    handleGetFiles(pageLimit);
  }, [fileManagerFilters, isClientFileManager, filterDebounced]);

  const getGlobalTags = async () => {
    totalCountEvent('global_tag').then((response) => {
      listGlobalTags(response.data)
        .then((response) => {
          let tagsArray = [];
          response?.data?.global_tag.forEach((item) => {
            tagsArray.push({ name: item.tag, value: item.tag });
          });
          setGlobalTags(tagsArray);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleGetFiles = async (pageLimit, folder_id) => {
    let case_id;

    if (isClientFileManager && userContext?.clientCaseList.length === 1) {
      case_id = userContext?.clientCaseList[0]?.case_id;
    } else if (isGlobalFileManager || isClientFileManager) {
      case_id = fileManagerFilters?.case?.id;
    } else {
      case_id = caseDetails?.case_id;
    }
    let obj = {
      // case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters?.case?.id : caseDetails?.case_id,
      case_id: case_id,
      // selectedFolder: fileManagerFilters.selectedFolder,
      selectedFolder: folder_id ? folder_id : fileManagerFilters.selectedFolder,
      selectedType: fileManagerFilters.fileType,
      tag: fileManagerFilters.tag,
      query: query,
      pagination_direction: paginationDirection,
      is_read: fileManagerFilters?.fileStatus,
    };
    getFileTags(case_id);
    getFiles(obj, currentView, pageLimit);
  };

  function onResetFilter() {
    setFileManagerFilters((preVal) => ({
      fileType: '',
      tag: '',
      // case: isClientFileManager ? preVal?.case : '',
      case: '',
      selectedFolder: '',
      fileStatus: '',
    }));
    setQuery('');
  }

  const handleSummarizeFile = (rowData, data) => {
    setSelectedItem(rowData);
    if (Object.keys(data)?.length > 0) {
      summarizeDocument(data)
        .then((response) => {
          const res = response?.data;
          setSummaryData(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleShowCreateEvent = (rowData, data) => {
    setShowCreateEventModal(true);
    setSelectedItem(rowData);
    if (Object.keys(data)?.length > 0) {
      courtDateEvent(data)
        .then((response) => {
          const res = response?.data;
          setCourtDateMsg(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  function hideCreateEventModal() {
    setShowCreateEventModal(false);
    setCourtDateMsg();
  }

  const modals = {
    showPreview,
    setShowPreview,
    showRenameModal,
    setShowRenameModal,
    moveItemModal,
    setMoveItemModal,
    showDeleteModal,
    showBulkDeleteModal,
    showBulkMoveModal,
    setShowDeleteModal,
    setShowBulkDeleteModal,
    setShowBulkMoveModal,
    showShareFileModal,
    setShowShareFileModal,
    tagModalType,
    setTagModalType,
    showAddTag,
    setShowAddTag,
    showFileUploadModal,
    setShowFileUploadModal,
    showUploadedVersionsModal,
    setShowUploadedVersionsModal,
    showCreateFolderModal,
    setShowCreateFolderModal,
    showCreateDocumentModal,
    setShowCreateDocumentModal,
    showSignatureModal,
    setShowSignatureModal,
    isCopy,
    setIsCopy,
    setEsignStatusModal,
    showEsignStatusModal,
    showSummarizeModal,
    setShowSummarizeModal,
    showDiscrepancyModal,
    setShowDiscrepancyModal,
    showCreateEventModal,
    setShowCreateEventModal,
  };

  const contextPayload = useMemo(
    () => ({
      // props
      isGlobalFileManager,
      isCaseFileManager,
      isLeadFileManager,
      isClientFileManager,
      // filter
      fileManagerFilters,
      onFilterChange,
      onResetFilter,
      // view
      currentView,
      setCurrentView,
      // files
      files,
      getFiles,
      handleGetFiles,
      pageLimit,
      setPageLimit,
      // selected rows
      selectedRows,
      selectAll,
      handleSingleCheckboxClick,
      handleSelectAllCheckboxClick,
      //breadcrumbs
      breadCrumbs,
      setBreadCrumbs,
      handleBreadCrumbs,
      filterBreadCrumbs,
      // selected item
      selectedItem,
      setSelectedItem,
      // filename
      fileName,
      setFileName,
      // case details
      caseDetails,
      isClosed,
      // delete permissions
      deletePermission,
      modals,
      versionFileUpload,
      setVersionFileUpload,
      previewList,
      setPreviewList,
      selectedPreviewItem,
      setSelectedPreviewItem,
      onNextPageClick,
      onPrevPageClick,
      nextToken,
      transitionToken,
      paginationDirection,
      setSelectedRows,
      onTagChange,
      fullTags,
      query,
      setQuery,
      onMountUnMount,
      onOperationBreak,
      setFileManagerFilters,
      globalTags,
      getGlobalTags,
      getFileTags,
      handleSummarizeFile,
      summaryData,
      setSummaryData,
      handleShowCreateEvent,
      hideCreateEventModal,
      courtDateMsg,
    }),
    [
      fileManagerFilters,
      setFileManagerFilters,
      files,
      pageLimit,
      currentView,
      selectedRows,
      selectAll,
      breadCrumbs,
      showPreview,
      showShareFileModal,
      selectedItem,
      fileName,
      showAddTag,
      caseDetails,
      tagModalType,
      isClosed,
      deletePermission,
      showRenameModal,
      moveItemModal,
      showDeleteModal,
      showBulkDeleteModal,
      showBulkMoveModal,
      showFileUploadModal,
      showUploadedVersionsModal,
      versionFileUpload,
      previewList,
      selectedPreviewItem,
      showCreateFolderModal,
      showCreateDocumentModal,
      nextToken,
      transitionToken,
      paginationDirection,
      showSignatureModal,
      setSelectedRows,
      fullTags,
      query,
      onMountUnMount,
      onOperationBreak,
      valueSelected,
      setValueSelected,
      globalTags,
      getFileTags,
      showSummarizeModal,
      handleSummarizeFile,
      summaryData,
      setSummaryData,
      showDiscrepancyModal,
      handleShowCreateEvent,
      hideCreateEventModal,
      courtDateMsg,
    ]
  );

  return <FileMangerContext.Provider value={contextPayload} {...props} />;
};
