import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button } from 'primereact/button';

import { useSidebar } from '../hooks/useSidebar';
import { InfiniteList } from './components/InfiniteList';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import useScrollToDivOnLoad from 'hooks/useScrollToDivOnLoad';
import ConversationBetween from '../MessageDetailsPage/components/ConversationBetween/ConversationBetween';
import { openLinkInBlank } from 'utils/utils';
import { useHistory } from 'react-router-dom';

export default function Sidebar({ messageDetails }) {
  const { handleSubmit, control, setValue } = useForm();
  const { messages, loading, listRef, handleSendMessage, fetchNextItems } = useSidebar(messageDetails);
  const targetRef = useScrollToDivOnLoad();
  const history = useHistory();

  const submit = (data) => {
    handleSendMessage(data.message);
    setValue('message', '');
  };

  const redirectToPage = (e, path) => {
    e.preventDefault();
    if (!openLinkInBlank(path)) {
      history?.push(path);
    }
  };

  return (
    <div className="d-flex flex-column sidebar">
      {/* <InfiniteList {...{ listRef, messages, loading, fetchNextItems }} /> */}
      {!window.location.href.includes('client') && (
        <ConversationBetween messageDetails={messageDetails} redirectToPage={redirectToPage} fromSidebar={true} />
      )}
      <div className="flex-grow-1 overflow-hidden">
        <InfiniteList {...{ listRef, messages, loading, fetchNextItems }} />
      </div>
      <form onSubmit={handleSubmit(submit)}>
        <div className="mt-2 p-0" style={{ position: 'relative' }}>
          <Controller
            name="message"
            rules={{ required: true }}
            render={({ field }) => (
              <TextSnippetWrapper
                rows={4}
                cols={30}
                autoResize
                className="border text-message-input-wrapper shadow-small w-100 text-message-input h-100"
                placeholder="Send a Message..."
                type="textarea"
                {...field}
              />
            )}
            control={control}
            defaultValue={''}
          />
          <Button ref={targetRef} className="p-button-secondary sent-btn" type="button" onClick={handleSubmit(submit)}>
            <i className="pi pi-send" style={{ padding: 10, rotate: '40deg', fontSize: 18 }}></i>
          </Button>
        </div>
      </form>
    </div>
  );
}
