import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';

import { uuidv4 } from 'common/uuidGenerator';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { assignToItemTemplate, checkOptionDisabled } from '../TaskBoard/Board/PriorityDropdownTemplate';
import { RequiredValidation } from 'components/UI/RequiredValidation/RequiredValidation';
import { getTenantDetails, getUserStatusLabel } from 'utils/utils';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import ImageComponent from 'shared/ImageComponent';

export default function NestedSubTask({ control, register, errors, contactswithoutGroups }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sub_task',
  });
  const { isClosed } = useTaskContext();

  const [editingIndexes, setEditingIndexes] = useState([]);
  const [tenantId] = getTenantDetails();

  const toggleEdit = (index) => {
    if (editingIndexes.includes(index)) {
      return;
    } else {
      setEditingIndexes([...editingIndexes, index]);
    }
  };

  const handleAppend = () => {
    append({ sub_task_description: '', sub_task_status: false, sk: uuidv4() });
    setEditingIndexes([...editingIndexes, fields.length]); // Make the newly appended field editable
  };

  const handleRemove = (index) => {
    remove(index);
    setEditingIndexes((prevIndexes) => prevIndexes.filter((item) => item !== index).map((item) => (item > index ? item - 1 : item)));
  };

  return (
    <>
      {fields.map((item, index) => {
        const isEditing = editingIndexes.includes(index);
        return (
          <div
            className="d-flex align-items-center flex-column bg-grey mb-3"
            key={item.id}
            style={{
              padding: 8,
              borderRadius: 12,
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}
          >
            {!isClosed && isEditing ? (
              <div className="w-100 d-flex align-items-center flex-column">
                <div className="w-100">
                  <TextSnippetWrapper
                    type="text"
                    className="mb-2 mb-2 w-100 input-shadow"
                    defaultValue={item.sub_task_description}
                    {...register(`sub_task[${index}].sub_task_description`, { required: 'Sub task title required' })}
                    disabled={isClosed}
                    placeholder="Sub task title"
                  />
                  <RequiredValidation field={`sub_task.${index}.sub_task_description`} errors={errors} />
                </div>
                <Controller
                  name={`sub_task[${index}].assign_to`}
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      options={contactswithoutGroups}
                      className="w-100 input-shadow F-size14 input-height"
                      filter
                      filterPlaceholder="Search"
                      placeholder="Select Assignee"
                      display="chip"
                      optionLabel="assignee_name"
                      optionValue="assignee_id"
                      optionDisabled={checkOptionDisabled}
                      itemTemplate={assignToItemTemplate}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      resetFilterOnHide
                      showSelectAll={false}
                    />
                  )}
                />
              </div>
            ) : (
              <div className="w-100 d-flex flex-column" onClick={() => toggleEdit(index)}>
                <div className="mt-2">
                  Title: <b>{item.sub_task_description}</b>
                </div>
                <div className="mt-2">Assignees: {item?.assignTo?.length === 0 ? 'None' : ''}</div>
                {item?.assignTo?.map((item, i) => {
                  return (
                    <div className="d-flex align-items-center py-1 p-avatar-clickable" key={i}>
                      <ImageComponent
                        filePath={
                          item?.contact_id || item?.firm_user_id
                            ? `${tenantId}/${item?.contact_id ? item?.contact_id : item?.firm_user_id}/profile_image`
                            : null
                        }
                        fileName={item.assignee_name}
                        className="me-2"
                        style={{
                          backgroundColor: '#2196F3',
                          color: '#ffffff',
                          border: `2px solid ${item?.calendar_color ?? '#ffff'}`,
                        }}
                      />
                      {item?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
                      {item?.assignee_name}
                      {getUserStatusLabel(item)}
                    </div>
                  );
                })}
              </div>
            )}
            <div className="w-100 d-flex align-items-center justify-content-between mt-2">
              <div className="d-flex align-items-center justify-content-center gap-10">
                Mark as complete:
                <Controller
                  name={`sub_task[${index}].sub_task_status`}
                  control={control}
                  defaultValue={false}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      inputId={field.name}
                      ref={field.ref}
                      onChange={(e) => field.onChange(e.checked)}
                      checked={field.value}
                      className={classNames({ 'p-invalid': fieldState.invalid, 'pe-none opacity-50': isClosed, 'mt-1': true })}
                      disabled={isClosed}
                    />
                  )}
                />
              </div>
              <div className={`mt-1 ${isClosed ? 'pe-none opacity-50' : ''}`}>
                <i className="icon-delete icon-red pointer" onClick={() => handleRemove(index)} />
              </div>
            </div>
          </div>
        );
      })}
      <div className={`mt-2 edit-field pointer addfield  ${isClosed ? 'disabled-icon' : ''}`} onClick={handleAppend}>
        <i className="icon-add F-size14 me-1"></i> Add Sub Task
      </div>
    </>
  );
}
