import React, { useContext, useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

import CustomHeader from '../CustomHeader';
import { useToast } from 'context/ToastContext';
import TextFileViewer from '../PreviewModal/TextFileViewer';
import PDFViewer from '../PreviewModal/PDFViewer';
import DocumentViewer from '../PreviewModal/DocxViewer';
import DocxViewer from '../PreviewModal/DocxViewer';

import { getFileExtension, getFileType, uploadFiles } from 'utils/filesUtils';
import { useForm } from 'react-hook-form';
import CourtEventForm from './CourtEventForm';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { UserDetailsContext } from 'context/userDetailsContext';
import { addEvent } from 'services/Calender/calendarServices';
import { toastConstant } from 'constants/toastmessage';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { getTenantDetails, parseRemiderPayload } from 'utils/utils';
import { useLoaderDispatch } from 'context/LoaderContext';
import { loadLocations } from 'modules/settings/workflow_automation/submodules/events/helpers/createEventHelpers';
import { useActivityLogPreview } from 'hooks/useActivityLogPreview';

function CreateCourtEventModal() {
  const {
    modals,
    selectedItem,
    isCaseFileManager,
    isLeadFileManager,
    isGlobalFileManager,
    caseDetails,
    hideCreateEventModal,
    courtDateMsg,
    onMountUnMount,
  } = useFileManagerContext();
  const websocketContext = useContext(WebSocketConnectionContext);
  const userContext = useContext(UserDetailsContext);
  const { showCreateEventModal, setShowCreateEventModal } = modals;
  const [uploadedAttachments, setUploadedAttachments] = useState([]);
  const [courtEventExtractedData, setCourtEventExtractedData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [locationsList, setLocationsList] = useState([]);
  const { addToast } = useToast();
  const { addActivity } = useActivityLogPreview();

  const renderingData = { ...selectedItem, type: findType(selectedItem) };

  const userId = userContext?.firmDetails?.current_user;
  const setLoader = useLoaderDispatch();
  const {
    handleSubmit,
    control,
    resetField,
    setValue,
    getValues,
    watch,
    formState: { errors },
    trigger,
  } = useForm();

  function findType(item) {
    return getFileType(getFileExtension(item?.display_name || item?.file_name));
  }

  const saveCreateEvent = async (data) => {
    if (data?.attachments?.length > 0) {
      setLoader(true);
      await uploadFiles(data?.attachments, 'calendar', selectedItem?.case_id, userId)
        .then((res) => {
          setLoader(false);
          data.attachments = res;
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          return;
        });
    }

    data.reminder = parseRemiderPayload(data.reminder);
    data.reminder = Array.from(new Set(data?.reminder));

    data.meeting_start_time = convertToTimezoneFormat(data.meeting_start_time, userContext?.userDetails?.timezone);
    data.meeting_end_time = convertToTimezoneFormat(data.meeting_end_time, userContext?.userDetails?.timezone);
    data.when = convertToTimezoneFormat(data.when, userContext?.userDetails?.timezone);

    data.case_name = isCaseFileManager || isLeadFileManager ? caseDetails?.case_description?.case_name : data.case?.case_name ?? '';
    data.case_id = isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : selectedItem?.case_id;
    data.is_lead = isLeadFileManager ? isLeadFileManager : data?.case?.is_lead;

    data.icon = data.event_type?.event_type_icon ?? '';

    if (isCaseFileManager || isLeadFileManager) {
      if (caseDetails?.case?.practice_area) {
        data.practice_area = Array.isArray(caseDetails.case.practice_area)
          ? caseDetails.case.practice_area
          : [caseDetails.case.practice_area];
      } else if (caseDetails?.case_description?.case_practice_area) {
        data.practice_area = Array.isArray(caseDetails.case_description.case_practice_area)
          ? caseDetails.case_description.case_practice_area
          : [caseDetails.case_description.case_practice_area];
      } else if (data?.case?.practice_area) {
        data.practice_area = Array.isArray(data.case.practice_area) ? data.case.practice_area : [data.case.practice_area];
      } else if (data?.practice_area) {
        data.practice_area = Array.isArray(data.practice_area) ? data.practice_area : [data.practice_area];
      }
    } else if (!isCaseFileManager && !isLeadFileManager) {
      if (data?.case?.practice_area) {
        data.practice_area = Array.isArray(data.case.practice_area) ? data.case.practice_area : [data.case.practice_area];
      } else if (data?.practice_area) {
        data.practice_area = Array.isArray(data.practice_area) ? data.practice_area : [data.practice_area];
      }
    } else {
      data.practice_area = [];
    }

    data.guests = [...data.contacts, ...data.firm_users];

    if (isCaseFileManager || isLeadFileManager) {
      data.case = {
        id: caseDetails?.case_id,
        value: caseDetails?.case_description?.case_name,
        label:
          caseDetails?.case_description && caseDetails?.case_description.case_name
            ? `${caseDetails.unique_number ? `${caseDetails.unique_number} ` : ''}${caseDetails.case_description.case_name}`
            : 'TBD',
      };
    }

    data.recurrence_keys = data.recurrence_keys ?? {};
    if (data.is_recurring) {
      data.recurrence_keys.recurrence_limit = convertToTimezoneFormat(
        data.recurrence_keys.recurrence_limit,
        userContext?.userDetails?.timezone
      );
    }

    addEvent({
      data,
      case_id: isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : selectedItem?.case_id,
      is_lead: isLeadFileManager ? isLeadFileManager : selectedItem?.is_lead,
    })
      .then((res) => {
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.EVENT_CREATED_SUCCESS);
        setUploadedAttachments();
        setCourtEventExtractedData();
        hideCreateEventModal();
      })
      .catch((err) => {
        console.log(err);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.EVENT_CREATE_FAILURE);
      });
  };

  const hadleAddNewLocation = (data) => {
    setLocationsList((prev) => [...prev, { label: data.title, value: data.title }]);
  };

  useEffect(() => {
    if (websocketContext?.aiCourtDate?.body?.court_date_message?.details) {
      setIsLoading(false);
      if (
        websocketContext?.aiCourtDate?.body?.court_date_message?.isError &&
        websocketContext?.aiCourtDate?.body?.court_date_message?.message
      ) {
        addToast(
          false,
          toastConstant.toasterType.ERROR,
          toastConstant.api.FAILED,
          websocketContext?.aiCourtDate?.body?.court_date_message?.message
        );
      }
      setCourtEventExtractedData(websocketContext?.aiCourtDate?.body?.court_date_message);
    }
  }, [websocketContext?.aiCourtDate]);

  useEffect(() => {
    setIsLoading(true);
  }, [courtDateMsg]);

  useEffect(() => {
    loadLocations(setLocationsList);
  }, []);

  const handleUploadAttachements = (items) => {
    setUploadedAttachments(items);
  };

  const createActivityLog = (item) => {
    setTimeout(() => {
      const [tenantId] = getTenantDetails();
      const data = {
        event_key: 'Read',
        event_type: 'document',
        event_subtype: 'read_document',
        message: `Viewed Document: ${item?.display_name}`,
        event_status: 'success',
        pk: item?.pk,
        sk: item?.sk,
        event_items: {
          case_id: item?.case_id || '',
          tenant_id: tenantId,
          case_name: item?.case_name || '',
          is_lead: item?.is_lead,
        },
      };
      addActivity(data);
    }, 5000);
    //Automated time entry logic
    isCaseFileManager && typeof onMountUnMount === 'function' && onMountUnMount('file-preview', 'mounted');
  };

  const renderViewer = React.useCallback(
    (item) => {
      const viewerProps = {
        onLoad: () => createActivityLog(item),
      };
      switch (item.type) {
        case 'text':
          return <TextFileViewer fileUrl={item.url} {...viewerProps} />;
        case 'doc':
          return (
            <div style={{ height: '100%', width: '100%' }}>
              <DocxViewer document={item.url} {...viewerProps} />
            </div>
          );
        case 'pdf':
          return (
            <div style={{ height: '100%', width: '100%' }}>
              <PDFViewer document={item.url} {...viewerProps} />
            </div>
          );
        default:
          return (
            <div style={{ height: '100%', width: '100%' }}>
              <DocumentViewer document={item.url} {...viewerProps} />
            </div>
          );
      }
    },
    [createActivityLog]
  );

  const renderLoader = (data) => {
    return (
      <div className="overlay d-flex flex-column p-1 align-items-center">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '3em' }}></i>
        <div className="mt-3">{data?.message}</div>
      </div>
    );
  };

  return (
    <Dialog
      className="my-dialog custom-dialog preview-dialog court-setting-modal"
      header={
        <CustomHeader
          onHide={() => {
            setShowCreateEventModal(false);
            setIsLoading(false);
          }}
        />
      }
      visible={showCreateEventModal}
      modal
      onHide={() => {
        setShowCreateEventModal(false);
        setIsLoading(false);
      }}
      closable={false}
    >
      <div className="row">
        <div className="col-lg-8 col-md-6 col-12  court-settings-preview" style={{ maxHeight: '100%' }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="modal-heading F-size16 text-bold">{`Preview of ${renderingData?.file_name}`}</div>
          </div>
          {renderViewer(renderingData)}
        </div>
        {isLoading ? (
          <div className="col-lg-4 col-md-6 col-12 mb-1 d-flex align-items-center justify-content-center" style={{ overflow: 'auto' }}>
            {renderLoader(courtDateMsg)}
          </div>
        ) : (
          <div className="col-lg-4 col-md-6 col-12 mb-1 court-settings-form">
            <CourtEventForm
              saveCreateEvent={saveCreateEvent}
              hideCreateEventModal={hideCreateEventModal}
              selectedItem={selectedItem}
              control={control}
              resetField={resetField}
              setValue={setValue}
              getValues={getValues}
              watch={watch}
              errors={errors}
              trigger={trigger}
              handleUploadAttachements={handleUploadAttachements}
              uploadedAttachments={uploadedAttachments}
              courtEventExtractedData={courtEventExtractedData}
              locationsList={locationsList}
              hadleAddNewLocation={hadleAddNewLocation}
            />
            <div className="footer-btn">
              <Button
                className="p-button-secondary outline mx-2"
                label="Cancel"
                onClick={() => {
                  setShowCreateEventModal(false);
                  setIsLoading(false);
                }}
              />
              <Button className="p-button p-button-secondary" label="Save" onClick={handleSubmit(saveCreateEvent)} />
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}

export default CreateCourtEventModal;
