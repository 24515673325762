import React, { useContext, useEffect, useState } from 'react';

import { UserDetailsContext } from 'context/userDetailsContext';

import EventTitle from 'modules/calendar/Components/CreateEventModal/Components/EventTitle';
import EventVideoConferenceUrl from 'modules/calendar/Components/CreateEventModal/Components/EventVideoConferenceUrl';
import EventDescription from 'modules/calendar/Components/CreateEventModal/Components/EventDescription';
import EventReminders from 'modules/calendar/Components/CreateEventModal/Components/EventReminders';
import EventRecurring from 'modules/calendar/Components/CreateEventModal/Components/EventRecurring';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import EventPracticeArea from 'modules/calendar/Components/CreateEventModal/Components/EventPracticeArea';
import CourtEventType from './Components/CourtEventType';
import CourtEventDate from './Components/CourtEventDate';
import CourtEventTime from './Components/CourtEventTime';
import CourtEventLocation from './Components/CourtEventLocations';
import CourtEventAttendees from './Components/CourtEventAttendees';
import CourtEventAttachments from './Components/CourtEventAttachments';
import { roundToNextHalfHour } from 'utils/utility_functions/timezone';

function CreateCourtEventForm({
  selectedItem,
  control,
  resetField,
  setValue,
  getValues,
  watch,
  errors,
  trigger,
  handleUploadAttachements,
  uploadedAttachments,
  courtEventExtractedData,
  locationsList,
  hadleAddNewLocation,
}) {
  const { isGlobalFileManager } = useFileManagerContext();
  const userContext = useContext(UserDetailsContext);

  const [showReminder, setShowReminder] = useState(false);

  const whenWatcher = watch('when');
  const startTimeWatcher = watch('meeting_start_time');
  const endTimeWatcher = watch('meeting_end_time');

  useEffect(() => {
    if (courtEventExtractedData) {
      if (courtEventExtractedData?.details?.title !== -1) {
        setValue('title', courtEventExtractedData?.details?.title);
      }
      if (courtEventExtractedData?.details?.court_date !== -1) {
        setValue('when', new Date(courtEventExtractedData?.details?.court_date));
      } else {
        setValue('when', new Date(timezoneBasedTime));
      }
      if (courtEventExtractedData?.details?.court_time !== -1) {
        setValue('meeting_start_time', formatTime(courtEventExtractedData?.details?.court_time));
        addTimeHandler(roundToNextHalfHour(formatTime(courtEventExtractedData?.details?.court_time)), 30, 'meeting_end_time');
      } else {
        setValue('meeting_start_time', roundToNextHalfHour(new Date(timezoneBasedTime)));
        addTimeHandler(roundToNextHalfHour(new Date(timezoneBasedTime)), 30, 'meeting_end_time');
      }
    }
  }, [courtEventExtractedData]);

  function addTimeHandler(time, extraTime, field) {
    if (time) {
      let new_time = new Date(time);
      let addedTime = new_time.setMinutes(new_time.getMinutes() + extraTime);
      let endTime = new Date(addedTime);
      setValue(field, endTime);
      trigger('meeting_end_time');
    }
  }
  let timezoneBasedTime = new Date().toLocaleString('en-US', {
    timeZone: userContext?.userDetails?.timezone,
  });

  function formatTime(isoString) {
    const date = new Date(isoString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  }

  const handleShowReminder = (show) => {
    if (!show) {
      setShowReminder(false);
    } else {
      setShowReminder(true);
    }
  };

  return (
    <form>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{'Court Event Details'}</div>
      </div>
      <div className="court-form-contents pe-2">
        <EventTitle control={control} errors={errors} />
        <CourtEventType control={control} setValue={setValue} resetField={resetField} />
        <CourtEventDate control={control} trigger={trigger} errors={errors} setValue={setValue} timezoneBasedTime={timezoneBasedTime} />
        <CourtEventTime control={control} trigger={trigger} errors={errors} setValue={setValue} startTimeWatcher={startTimeWatcher} />
        <EventRecurring
          control={control}
          resetField={resetField}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
          isGlobal={isGlobalFileManager}
          errors={errors}
          timezoneBasedTime={timezoneBasedTime}
        />
        <EventVideoConferenceUrl control={control} />
        <CourtEventLocation
          control={control}
          setValue={setValue}
          locationsList={locationsList}
          hadleAddNewLocation={hadleAddNewLocation}
          courtEventExtractedData={courtEventExtractedData}
        />
        {(!selectedItem?.case_id || selectedItem?.case_id.trim() === 'undefined') && (
          <EventPracticeArea key={selectedItem?.case_id} control={control} setValue={setValue} />
        )}
        <CourtEventAttendees
          control={control}
          getValues={getValues}
          setValue={setValue}
          trigger={trigger}
          watch={watch}
          selectedItem={selectedItem}
          handleShowReminder={handleShowReminder}
          showReminder={showReminder}
        />
        <EventDescription control={control} />
        <CourtEventAttachments
          control={control}
          setValue={setValue}
          getValues={getValues}
          handleUploadAttachements={handleUploadAttachements}
          uploadedAttachments={uploadedAttachments}
        />
        <EventReminders
          control={control}
          setValue={setValue}
          watch={watch}
          isGlobal={isGlobalFileManager}
          errors={errors}
          isRequired={showReminder}
          optionText="before event start time"
        />
      </div>
    </form>
  );
}

export default CreateCourtEventForm;
