import React, { useEffect, useState } from 'react';

import { confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

import {
  deleteCaseFormService,
  getCaseFormsService,
  resendCaseFormService,
  viewCaseFormsService,
} from 'services/intakeforms/clientIntakeService';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { convertObjectToQuery, generateFormPdf } from 'utils/utils';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { handlePageNumberInPagination } from 'common/pagination';
import { paginationDropdownOptions } from 'constants/dropdownOptions';

export function useCaseIntake(case_id, _pageSize = 10) {
  const userContext = useUserDetailsContext();
  const { addToast } = useToast();

  const [data, setData] = useState([]);
  const [isForward, setIsForward] = useState(true);
  const [pageSize, setpageSize] = useState(
    userContext?.firmDetails?.pagination_preference?.settings?.client_intake_list?.self || _pageSize
  );

  const next_Token = data?.next_token;
  const transition_Token = data?.transition_token;
  const caseForms = data?.link_data || [];

  useEffect(() => {
    setpageSize(userContext?.firmDetails?.pagination_preference?.settings?.client_intake_list?.self || _pageSize);
  }, [userContext]); // eslint-disable-line

  const hasNext = next_Token === null && isForward;
  const hasPrevious = transition_Token === null || (transition_Token && next_Token === null && !isForward);

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: () => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={pageSize} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchNext} disabled={hasNext} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button onClick={fetchprev} disabled={hasPrevious} className={'p-paginator-next p-paginator-element p-link'} icon={iconClassName} />
      );
    },
  };

  const fetchNext = () => {
    setIsForward(true);
    loadList({
      limit: pageSize,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'forward',
    });
  };

  const fetchprev = () => {
    setIsForward(false);
    loadList({
      limit: pageSize,
      pagination_token: isForward ? transition_Token : next_Token,
      pagination_direction: 'reverse',
    });
  };

  const onPageChange = (e) => {
    setpageSize(e.value);
    handlePageNumberInPagination(userContext, 'settings', 'client_intake_list', 'self', e.value);
    loadList({
      limit: e.value,
      pagination_token: '',
      pagination_direction: 'forward',
    });
    setIsForward(true);
  };

  const loadList = ({ limit = pageSize || 10, pagination_token = '', pagination_direction = 'forward' }) => {
    const qs = {
      pagination_token,
      pagination_direction,
      limit,
      case_id,
    };
    getCaseFormsService(convertObjectToQuery(qs))
      .then((response) => setData({ ...response?.data }))
      .catch(() =>
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.INTAKE_FORM_LIST_FAILED)
      );
  };

  useEffect(() => {
    loadList({});
  }, []);

  // ----------------------------------------------------------------------

  const viewCaseForm = (pk, sk) => {
    viewCaseFormsService(convertObjectToQuery({ pk, sk })).then((response) => {
      generateFormPdf(response?.data);
    });
  };

  const deleteIntake = ({ pk, sk }) => {
    confirmDialog({
      message: 'Are you sure want to delete this form?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () =>
        deleteCaseFormService({ pk, sk }).then(() => {
          loadList({});
          addToast(
            false,
            toastConstant.toasterType.SUCCESS,
            toastConstant.api.SUCCESS,
            toastConstant.message.INTAKE_FORM_RESPONSE_DELETE_SUCCESS
          );
        }),
    });
  };

  const resendCaseForm = (form_sk, send_email) => {
    confirmDialog({
      message: 'Are you sure want to resend this form?',
      header: 'Resend Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => {
        resendCaseFormService(convertObjectToQuery({ form_sk, send_email }))
          .then((res) => {
            if (res?.data?.recipient === 'no recipient/invalid email') {
              addToast(
                false,
                toastConstant.toasterType.WARN,
                toastConstant.api.FAILED,
                toastConstant.message.INTAKE_FORM_EMAIL_NOT_AVAILABLE
              );
            } else {
              addToast(
                false,
                toastConstant.toasterType.SUCCESS,
                toastConstant.api.SUCCESS,
                toastConstant.message.INTAKE_FORM_RESEND_SUCCESS
              );
            }
          })
          .catch((err) => {
            console.log(err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.INTAKE_FORM_RESEND_FAILED);
          });
      },
    });
  };

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
    addToast(false, toastConstant.toasterType.WARNING, '', toastConstant.message.CLIPBOARD_COPY_SUCCCESS);
  };
  // ----------------------------------------------------------------------------------

  return {
    paginatorTemplate,
    caseForms,
    loadList,
    copyToClipboard,
    resendCaseForm,
    deleteIntake,
    viewCaseForm,
  };
}
