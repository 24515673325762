import { useState, useEffect, memo, useCallback, useMemo } from 'react';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';
import GetSignature from '../../ListView/Templates/ActionsTemplate/GetSignature';
import useSpinner from 'hooks/useSpinner';

const DocumentIframe = memo(({ url, onLoad, onError }) => {
  const handleError = (err) => {
    console.error('Iframe loading error:', err);
    onError?.(err);
  };
  return (
    <>
      <iframe
        key={url}
        src={url}
        className="file-viewer"
        width="1366px"
        height="623px"
        frameBorder="0"
        onLoad={onLoad}
        onError={handleError}
      >
        This is an embedded{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://office.com">
          Microsoft Office
        </a>{' '}
        document, powered by{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://office.com/webapps">
          Office Online
        </a>
        .
      </iframe>
    </>
  );
});

const DocumentViewer = memo(({ document, item, isClientFileManager, onLoad }) => {
  const [iFrameUrl, setIframeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);

  const docTypeVal = useMemo(() => 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', []);

  const sigCondition = useMemo(() => {
    const fileType = item?.file_type;
    return fileType && (fileType === 'application/pdf' || fileType === docTypeVal);
  }, [item?.file_type, docTypeVal]);

  // Validate document URL
  useEffect(() => {
    if (document) {
      try {
        new URL(document);
      } catch (e) {
        console.error('Invalid document URL:', e);
        setLoadError('Invalid document URL');
        setIsLoading(false);
      }
    }
  }, [document]);

  // Add timeout for loading
  useEffect(() => {
    let timeoutId;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        handleRetry();
        setLoadError('Loading timeout - document might be too large or unavailable');
        setIsLoading(false);
      }, 10000); // 10 second timeout
    }
    return () => clearTimeout(timeoutId);
  }, [isLoading]);

  // Update URL construction
  useEffect(() => {
    if (document) {
      try {
        setIsLoading(true);
        setLoadError(null);
        const encodedUrl = encodeURIComponent(document);
        const newUrl = `https://docs.google.com/viewer?url=${encodedUrl}&embedded=true`;
        setIframeUrl(newUrl);
      } catch (error) {
        console.error('Error constructing URL:', error);
        setLoadError('Failed to load document');
        setIsLoading(false);
      }
    }
  }, [document]);

  // Handle loading state
  useEffect(() => {
    if (isLoading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [isLoading, showSpinner, hideSpinner]);

  const handleRetry = useCallback(() => {
    if (retryCount < 3) {
      setIsLoading(true);
      setLoadError(null);
      setRetryCount((prev) => prev + 1);
      const timestamp = new Date().getTime();
      setIframeUrl((current) => `${current}${current.includes('?') ? '&' : '?'}t=${timestamp}`);
    }
  }, [retryCount]);

  const handleIframeLoad = useMemo(() => {
    return () => {
      setIsLoading(false);
      setLoadError(null);
      setRetryCount(0);
      onLoad?.();
      console.log('Iframe loaded');
    };
  }, [onLoad]);

  const handleIframeError = useMemo(() => {
    return (error) => {
      console.error('Iframe loading error:', error);
      setIsLoading(false);
      setLoadError('Failed to load document');
      handleRetry();
    };
  }, [handleRetry]);

  const handleManualRetry = useCallback(() => {
    setRetryCount(0);
    handleRetry();
  }, [handleRetry]);

  if (!document) return null;

  return (
    <div className="h-100">
      {!isClientFileManager && sigCondition && <GetSignature rowData={item} />}
      {item && (
        <FileDownload
          rowData={item}
          isFromFilePreviewModal={true}
          filePreviewModalIconClassName="icon-save p-2 pointer float-end h2 mb-0"
        />
      )}
      {/* {spinner} */}
      {loadError && retryCount >= 3 && (
        <div className="alert alert-danger">
          {loadError}
          <button className="btn btn-link" onClick={handleManualRetry}>
            Try again
          </button>
        </div>
      )}
      {iFrameUrl && !loadError && <DocumentIframe url={iFrameUrl} onLoad={handleIframeLoad} onError={handleIframeError} />}
    </div>
  );
});

export default DocumentViewer;
