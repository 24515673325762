import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MultiSelect } from 'primereact/multiselect';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { Dropdown } from 'primereact/dropdown';
import { fetchFiles } from 'services/fileManager/fileManagerServices';
import { getSignedUrl } from 'modules/file_manager/services';
import { useDebounce } from 'hooks/useDebounce';
import { RadioButton } from 'primereact/radiobutton';

const IdentifyDiscrepancy = ({
  isGlobalFileManager,
  isCaseFileManager,
  isLeadFileManager,
  caseDetails,
  fileManagerFilters,
  setBreadCrumbs,
  control,
  errors,
  watch,
  setValue,
  reset,
}) => {
  const caseSelection = watch('case'); // Watching the case dropdown selection
  const [fileList, setFileList] = useState([]);
  const [query, setQuery] = useState('');
  const [selectedMultiSelectFiles, setSelectedMultiSelectFiles] = useState([]);
  const [prevCaseId, setPrevCaseId] = useState(null);

  const outputFileOption = [
    {
      key: 'PDF',
      value: 'pdf',
    },
    {
      key: 'Docx',
      value: 'docx',
    },
  ];

  const getFiles = (obj) => {
    fetchFiles(obj)
      .then(async (response) => {
        let arr = response.data.data;

        arr = arr.filter((item) => {
          if (!item.file_type) return false;
          const fileType = item?.file_type?.toLowerCase();
          return fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        });
        const _dataArray = await Promise.all(
          arr.map(async (item) => {
            if (item.file_path) {
              const url = await getSignedUrl(item);
              return { ...item, url };
            }
            return item;
          })
        );

        setFileList((prev) => {
          if (prev.length > 0 && prev[0]?.case_id !== obj.case_id) {
            return _dataArray; // Reset fileList if case_id is different
          }

          const nonListedItems = [];
          for (const data of _dataArray) {
            const ifNotAdded = prev.find((prv) => prv?.sk === data?.sk);
            if (!ifNotAdded) {
              nonListedItems.push(data);
            }
          }
          return [...prev, ...nonListedItems];
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    if (!caseSelection?.id && !fileManagerFilters?.case?.id && !caseDetails?.case_id) return;

    const caseId = caseSelection?.id || fileManagerFilters?.case?.id || caseDetails?.case_id;

    if (prevCaseId !== caseId) {
      reset({
        case: caseSelection,
        selectedFiles: [],
        taskName: '',
        outputFormat: '',
      });
      setPrevCaseId(caseId);
    }

    const obj = {
      case_id: caseId,
      selectedFolder: '',
      selectedType: '',
      tag: '',
      query: debouncedQuery,
      pagination_direction: '',
      is_read: '',
    };

    getFiles(obj);
  }, [caseSelection, debouncedQuery, isCaseFileManager, isLeadFileManager, fileManagerFilters?.case?.id, caseDetails?.case_id]);

  useEffect(() => {
    const dropdownClasses = document?.querySelectorAll('.p-multiselect-token');
    for (const dropdownElement of dropdownClasses) {
      const dropLabelElement = dropdownElement?.querySelector('.p-multiselect-token-label')?.innerHTML;

      if (dropLabelElement?.trim()?.length === 0) {
        dropdownElement.style.display = 'none';
      }
    }
  }, [fileList]);

  useEffect(() => {
    if (!caseSelection && fileManagerFilters?.case?.id) {
      setValue('case', fileManagerFilters?.case, { shouldValidate: true });
    }
  }, [fileManagerFilters, caseSelection, setValue]);

  return (
    <>
      <div className="mb-2">
        <span className="F-size14 text-bold">Choose files to identify discrepancies</span>
      </div>

      {/* Case Selection (if Global File Manager is enabled) */}
      {isGlobalFileManager && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-3 col-12 p-medium line_height3">
            <label className="p-medium me-3">
              Case<span className="text-danger">*</span>
            </label>
          </div>
          <div className="col-md-9 col-12">
            <Controller
              name="case"
              control={control}
              defaultValue={null}
              rules={{ required: 'Case is required' }} // Adding validation rule
              render={({ field }) => <CaseListDropDown {...field} placeholder="Case/Lead Name" noOptionsMessage="No Cases/Leads Found" />}
            />
            {/* Display error for Case field */}
            {errors.case && <InputErrorMessage>{errors?.case?.message}</InputErrorMessage>}
          </div>
        </div>
      )}

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-3 col-12 p-medium line_height3">
          <label className="p-medium me-3">
            Task Identifier<span className="text-danger">*</span>
          </label>
        </div>
        <div className="flex-grow-1">
          <Controller
            name="taskName"
            control={control}
            // defaultValue={''}
            rules={{ required: 'Task identifier is required' }} // Add validation rule with message
            render={({ field }) => (
              <TextSnippetWrapper
                type="text"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                }}
                placeholder="Task identifier"
                className="w-100 input-shadow"
              />
            )}
          />
          {/* Display error for Name field */}
          {errors.taskName && <InputErrorMessage>{errors?.taskName?.message}</InputErrorMessage>}
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-3 col-12 p-medium line_height3">
          <label className="p-medium me-3">
            Choose Files<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-md-9 col-12">
          <Controller
            name="selectedFiles"
            control={control}
            defaultValue={[]} // Default value as an empty array
            rules={{
              required: 'At least two files must be selected',
              validate: (value) => (value?.length >= 2 ? true : 'At least two files must be selected'),
            }} // Add validation rule with message
            render={({ field }) => (
              <MultiSelect
                value={field.value} // No need to map, just use the selected files directly
                inputRef={field.ref}
                options={fileList || []} // Updated file options
                placeholder="Select Files"
                maxSelectedLabels={1}
                display="chip"
                filter
                optionLabel="display_name"
                onChange={(e) => {
                  field.onChange(e.value); // Update selected files in the form state
                }}
                className="input-shadow F-size14 w-100"
                disabled={isGlobalFileManager && !caseSelection} // Disable if no case is selected
                onFilter={(event) => setQuery(event?.filter)} // Filter files based on the search query
                emptyFilterMessage={'No files found'}
              />
            )}
          />
          {/* Display error for Select File field */}
          {errors.selectedFiles && <InputErrorMessage>{errors?.selectedFiles?.message}</InputErrorMessage>}
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-md-3 col-12 p-medium line_height3">
          <label className="p-medium me-3">
            Extension Type<span className="text-danger">*</span>
          </label>
        </div>
        <div className="col-md-9 col-12">
          <Controller
            name="reportExtension"
            control={control}
            rules={{ required: 'Report extension is required' }}
            render={({ field }) => (
              <div className="d-flex gap-3">
                {outputFileOption.map((option) => (
                  <div key={option.value} className="d-flex align-items-center gap-2">
                    <RadioButton
                      inputId={option.value}
                      name="reportExtension"
                      value={option.value}
                      onChange={(e) => field.onChange(e.value)}
                      checked={field.value === option.value}
                    />
                    <label htmlFor={option.value}>{option.key}</label>
                  </div>
                ))}
              </div>
            )}
          />
          {errors.reportExtension && <InputErrorMessage>{errors?.reportExtension?.message}</InputErrorMessage>}
        </div>
      </div>
    </>
  );
};

export default IdentifyDiscrepancy;
