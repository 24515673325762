import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Controller } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { Card } from 'primereact/card';
import { InputSwitch } from 'primereact/inputswitch';
import { totalCountEvent } from 'services/generalServices';
import AppConstants from 'constants/index';
import { getConflictCheck, listContacts } from 'services/lead/leadServices';
import { generateFullName } from 'utils/generateFullNameUtils';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const ConflictCheck = ({ control, userDetails, isCaseClosed, watch, setValue, isLeadSpecific, caseDetails }) => {
  const [filteredClients, setFilteredClients] = useState(null);
  const [conflictedCaseList, setConflictedCaseList] = useState([]);

  useEffect(() => {
    const clientId =
      caseDetails?.lead_details?.conflict_contacts?.client?.contact_id || caseDetails?.case_details?.conflict_contacts?.client?.contact_id;
    if (clientId) {
      getConflictCheck(clientId, isLeadSpecific)
        .then((response) => setConflictedCaseList(response.data?.case_data))
        .catch((error) => {});
    }
  }, [caseDetails, setValue, isLeadSpecific]);

  //Conflict Client
  const searchClient = async (event) => {
    let totalCount = await totalCountEvent('contact');
    let obj = { pageLimit: totalCount.data, keyWord: event.query, in_lead: true, contactType: '' };
    let response = await listContacts(obj);
    if (response?.data?.contacts?.length > 0) {
      let data = [];
      response.data.contacts.forEach((element) => {
        data.push({
          label: element.contact_type === 'person' ? generateFullName(element) : element.title,
          value: element.contact_id,
          client: element,
        });
      });
      setFilteredClients(data);
    } else {
      setFilteredClients([]);
    }
  };

  const onSelectContact = (e) => {
    if (e.value && e.value.value) {
      getConflictCheck(e.value.value, isLeadSpecific)
        .then((response) => {
          setConflictedCaseList(response.data?.case_data);
          if (response.data?.message === 'This contact has conflict') {
            setValue('lead_details.conflict_check', true);
          } else if (response.data === 'No conflicts') {
            setValue('lead_details.conflict_check', false);
          }
        })
        .catch((error) => {});
    }
  };

  const onClearContact = () => {
    setConflictedCaseList('');
    setValue('lead_details.conflict_check', false);
  };

  return (
    <>
      <div className="caption-bold mb-3">Conflict Check</div>
      <Card className="mb-4 shadow-middle F-size14">
        <div className="row mb-4 conflict-card">
          <div className="d-flex align-items-top py-2 F-size14 flex-wrap">
            <div className="col-sm-6 text-muted p-medium mt-2">Conflict Check</div>
            <div className="col-md-6 col-12 align-items-center edit-field">
              {isCaseClosed ? (
                <div className="w-100 h-100 mt-2">{watch('conflict_check') ? 'Conflict' : 'No Conflict'}</div>
              ) : (
                <>
                  <div className="mb-3 d-flex align-items-center">
                    <Controller
                      name="lead_details.conflict_contacts"
                      control={control}
                      render={({ field }) => (
                        <AutoComplete
                          placeholder="Enter Name"
                          style={{ width: '100%' }}
                          inputStyle={{ width: '100%' }}
                          value={field.value}
                          suggestions={filteredClients}
                          onSelect={(e) => onSelectContact(e)}
                          onClear={onClearContact} // Add onClear handler
                          completeMethod={searchClient}
                          field="label"
                          onChange={(e) => field.onChange(e.value)}
                          className="flex-grow-1 input-shadow"
                        />
                      )}
                    />
                  </div>
                  <div className="mb-3 d-flex align-items-center">
                    <Controller
                      name="lead_details.conflict_check"
                      control={control}
                      render={({ field }) => (
                        <InputSwitch
                          name={field?.name}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          className="input-swich"
                        />
                      )}
                    />
                    {watch('lead_details.conflict_check') === true ? (
                      <label className="p-medium ms-2">Conflict</label>
                    ) : (
                      <label className="p-medium ms-2">No Conflict</label>
                    )}
                  </div>
                  <div className="mb-2">
                    {!!conflictedCaseList?.length &&
                      conflictedCaseList.map((data) => {
                        return (
                          <div className="p-2">
                            <Link
                              to={data?.is_lead ? `/leads/${data?.case_id}` : `/cases/${data?.case_id}`}
                              target="_blank"
                              className="a text-break pointer F-size14 call-back text-decoration-none"
                            >
                              {data?.case_description?.case_name}
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="d-flex  py-2 F-size14 flex-wrap conflict-note">
            <div className="col-sm-6 text-muted p-medium">Conflict Notes</div>
            <div className="col-md-6 col-12 d-flex align-items-center">
              <Controller
                name="lead_details.conflict_note"
                control={control}
                render={({ field }) => (
                  <TextSnippetWrapper
                    type="textarea"
                    rows={8}
                    cols={30}
                    style={{ width: '90%' }}
                    placeholder="Enter Conflict Notes"
                    value={field.value}
                    onChange={(e) => {
                      userDetails.getPermission('cases') === AppConstants.permissions.WRITE && field.onChange(e.target.value);
                    }}
                    className="input-shadow"
                    disabled={isCaseClosed}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ConflictCheck;
