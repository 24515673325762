import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { classNames } from 'primereact/utils';

import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { generateFullName } from 'utils/generateFullNameUtils';
import { getRelativeTime, replaceLawftDate } from 'utils/utils';
import constants from 'constants/index';
import ImageComponent from 'shared/ImageComponent';
import Cookies from 'universal-cookie';
import { SentimentDot } from 'components/SentimentDot/SentimentDot';
import { TabPanel, TabView } from 'primereact/tabview';
const cookie = new Cookies();

const contactOptionTemplate = (item) => {
  let name = generateFullName(item);
  if (!Boolean(item?.phone)) {
    name += ' (Please add a phone number)';
  }
  return <div className={classNames({ 'disable-option': !Boolean(item?.phone) })}>{name}</div>;
};

const tenantId = cookie.get('tenantId');

export default function ThreadList({
  isFromCase,
  caseDetails,
  isFromLead,
  leadDetails,
  selectedContact,
  setSelectedContact,
  contactsOption,
  messageThreads,
  selectedThread,
  handleSelectThread,
  fetchTextMessages,
  activeIndex,
  setActiveIndex,
  websocket,
}) {
  const userContext = useUserDetailsContext();
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [caseRelatedContactList, setCaseRelatedContactList] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null); // State for tracking selected message
  const prevMessageThreadsRef = useRef(); // Ref to store previous messageThreads
  const prevNotificationRef = useRef(); // Ref to store previous notification

  useEffect(() => {
    if (selectedContact?.contact_id || selectedContact === undefined || selectedContact === '') {
      fetchTextMessages(leadDetails?.case_id || caseDetails?.case_id, selectedContact?.contact_id, activeIndex === 0 ? '' : false);
    }
  }, [selectedContact, activeIndex]);

  useEffect(() => {
    if (isFromCase && caseDetails) {
      let contactList = [];
      if (caseDetails?.client_details?.length > 0) {
        contactList.push(caseDetails?.client_details[0]);
      }
      if (caseDetails?.relations?.length > 0) {
        contactList = [...contactList, ...caseDetails?.relations];
      }
      setCaseRelatedContactList(contactList.map((v) => ({ ...v, fullName: generateFullName(v) })));
    }

    if (isFromLead && leadDetails) {
      let allLeadContacts = [];
      const { main_client, contact, relations } = leadDetails || {};

      if (main_client?.length) {
        allLeadContacts = [...allLeadContacts, main_client[0]];
      }
      if (contact?.length) {
        allLeadContacts = [...allLeadContacts, contact[0]];
      }
      if (relations?.length) {
        allLeadContacts = [...allLeadContacts, ...relations];
      }
      setCaseRelatedContactList(
        allLeadContacts?.filter((contact) => contact.contact_type === 'person').map((v) => ({ ...v, fullName: generateFullName(v) }))
      );
    }
  }, [caseDetails, leadDetails]);

  useEffect(() => {
    if (JSON.stringify(messageThreads) !== JSON.stringify(prevMessageThreadsRef.current)) {
      // if (activeIndex === 0 && (selectedContact?.contact_id || selectedContact === undefined || selectedContact === '')) {
      //   fetchTextMessages(leadDetails?.case_id || caseDetails?.case_id, selectedContact?.contact_id, '');
      // }
      // Ensure the notification data body is different before fetching new messages
      if (websocket?.notificationData?.body && websocket?.notificationData?.body !== prevNotificationRef.current) {
        fetchTextMessages(leadDetails?.case_id || caseDetails?.case_id, selectedContact?.contact_id, activeIndex === 0 ? '' : false);
        prevNotificationRef.current = websocket.notificationData.body; // Update the ref here
      }
    }

    prevMessageThreadsRef.current = messageThreads;
  }, [messageThreads, websocket]);

  const searchContacts = (event) => {
    setTimeout(() => {
      let _filteredUsers;
      if (!event.query.trim().length) {
        _filteredUsers = [...contactsOption];
      } else {
        _filteredUsers = contactsOption.filter((user) => {
          return (
            generateFullName(user)?.toLowerCase()?.includes(event.query.toLowerCase()) ||
            user?.phone?.toLowerCase().includes(event.query.toLowerCase())
          );
        });
      }
      setFilteredContacts(_filteredUsers);
    }, 250);
  };

  const handleSelectContact = (e) => {
    if (e?.value?.phone !== '') {
      setSelectedContact(e.value);
    } else setSelectedContact('');
  };

  const handleThreadClick = (message) => {
    handleSelectThread(message);
    setSelectedMessage(message);
    message.is_read = true;
  };

  const handleLastMessage = (msg = false) => {
    try {
      if (msg && msg.last_message) {
        let unicode_1 = '#128206; ';
        let unicode_2 = '&#128206; ';
        let unicode_3 = '128206';
        let data1 = msg.last_message.replace(unicode_1, '');
        let data2 = replaceLawftDate(data1.replace(unicode_2, ''), userContext?.userDetails?.timezone);
        if (msg.last_message.includes(unicode_1) || msg.last_message.includes(unicode_2) || msg.last_message.includes(unicode_3)) {
          return (
            <span>
              <i className="fas fa-paperclip"></i>
              {` ${data2}`}
            </span>
          );
        } else {
          return data2;
        }
      }
    } catch (error) {}
  };

  return (
    <div className="shadow-middle my-3 text-message-height">
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="All"></TabPanel>
        <TabPanel header="Unread"></TabPanel>
      </TabView>
      <div className="p-3">
        <span className="p-input-icon-left input-shadow w-100">
          {Boolean(isFromCase || isFromLead) ? (
            <Dropdown
              name="dropdown"
              value={selectedContact}
              optionLabel="fullName"
              options={caseRelatedContactList}
              onChange={handleSelectContact}
              placeholder="Select a contact"
              className="input-shadow w-100"
              optionValue={(v) => v}
              filter
              showClear
              itemTemplate={contactOptionTemplate}
            />
          ) : (
            <AutoComplete
              value={selectedContact}
              selectedItemTemplate={(v) => v.first_name + ' ' + v.last_name}
              name="autoComplete"
              suggestions={filteredContacts}
              completeMethod={searchContacts}
              onChange={handleSelectContact}
              placeholder="Search or Start New Chat"
              className="input-shadow w-100"
              inputClassName="w-100"
              itemTemplate={contactOptionTemplate}
              resetFilterOnHide
            />
          )}
        </span>
      </div>
      <div className="msg-holder p-3 pt-0" style={{ height: '80vh', overflow: 'hidden auto' }}>
        {messageThreads?.map((message, index) => {
          const sentimentParams = {
            type: message?.aggregate_sentiment,
            positive: message?.positive_sentiment_count,
            neutral: message?.neutral_sentiment_count,
            negative: message?.negative_sentiment_count,
            mixed: message?.mixed_sentiment_count,
          };
          return (
            <div
              className="w-100 border-top p-2 pointer"
              key={index}
              // onClick={() => handleSelectThread(message)}
              onClick={() => {
                handleThreadClick(message);
              }}
              style={{ background: selectedThread?.sk === message.sk ? '#fafbfe' : '' }}
            >
              <div className="d-flex align-items-center py-2">
                <div className="col-2">
                  <ImageComponent
                    filePath={`${tenantId}/${message?.contact_id}/profile_image`}
                    fileName={message?.client_name?.first_name?.charAt(0) ?? 'C'}
                    style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
                    fileSize="medium.jpg"
                  />
                </div>
                <div className="col-7 d-flex align-items-center">
                  <h5 className="caption-bold mb-0 pe-2">
                    {message?.client_name?.first_name
                      ? `${generateFullName(message?.client_name)} ( ${
                          userContext?.firmPhoneNumber === message.from_ ? message?.to : message?.from_
                        } )`
                      : 'User'}
                    <span className="d-flex align-items-center red">
                      {message?.is_active_user === false ? (
                        <>
                          <i className="fas fa-exclamation-circle me-1"></i>
                          Inactive Contact
                        </>
                      ) : message?.is_active === false ? (
                        <>
                          <i className="fas fa-exclamation-circle me-1"></i>
                          Inactive Thread
                        </>
                      ) : null}
                    </span>
                  </h5>
                </div>
                <div className="col-3 text-end">
                  {getRelativeTime(
                    handleDateTimeOffset(userContext?.userDetails?.timezone, message.last_updated, constants.date_time_format)
                  )}
                </div>
              </div>
              <div className="d-flex">
                <div className="col-2"></div>
                <div className="col-10 text-muted d-flex justify-content-between">
                  {message?.last_author !== 'client' ? (
                    <>
                      <div className={message?.is_read ? 'pointer text-break text-primary-dark' : 'pointer text-bold text-primary-dark'}>
                        <b>{message.last_author}:</b>
                        <span style={{ marginLeft: '0.5rem' }}>{handleLastMessage(message)}</span>
                      </div>
                      <div>
                        {message.last_message_status === 'delivered' ? (
                          <i className="fas fa-check-double" style={{ color: 'lightgreen' }} />
                        ) : (
                          <i className="fas fa-check" style={{ color: 'lightgreen' }} />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <span className={`pointer text-break text-primary-dark ${message?.is_read ? '' : 'text-bold'}`}>
                        {handleLastMessage(message)}
                      </span>
                      <span className="d-flex flex-column align-items-center">
                        {message?.aggregate_sentiment && <SentimentDot tooltipPosition="top" {...sentimentParams} />}
                        {/* {Number(message?.unread_reply_count) > 0 && <span className="badge-text mt-1">New</span>} */}
                        {message?.is_read === false && <span className="badge-text mt-1">New</span>}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
