import { Tooltip } from 'primereact/tooltip';
import React from 'react';

function FilesList({ filesWatcher, uploadingIndex, setValue, isClientFileManager }) {
  const removeFirstSlash = (itemPath = '') => {
    if (itemPath.charAt(0) === '/') {
      itemPath = itemPath.slice(1);
    }
    return itemPath;
  };

  const deleteFile = (index) => {
    let _files = [...filesWatcher];
    _files.splice(index, 1);
    setValue('files', _files);
  };

  const handleCourtSetting = (index) => {
    let _files = [...filesWatcher];
    _files[index].is_court_setting_notice = !_files[index].is_court_setting_notice;
    setValue('files', _files);
  };

  return (
    <div className="file-list-container d-flex align-items-center py-2 flex-wrap">
      <div className="col-md-3 col-12"></div>
      <div className="col-md-9 col-12">
        {!isClientFileManager && filesWatcher?.some((item) => item?.type === 'application/pdf') && (
          <div className="d-flex flex-wrap mt-2 align-items-center files-note mb-2">
            <i className="pi pi-info-circle me-1"></i>
            Select to mark as Court Setting Notice
          </div>
        )}
        {filesWatcher?.map((item, index) => {
          return (
            <div className="d-flex mb-3 pe-2 align-items-center justify-content-between" key={index}>
              <div className="d-flex align-items-center text-truncate" key={index}>
                {!isClientFileManager && filesWatcher?.some((item) => item?.type === 'application/pdf') && (
                  <div className="d-flex align-items-center">
                    <Tooltip target=".tooltip-container" position="top" className="mb-3">
                      Mark as Court Setting Notice
                    </Tooltip>
                    <input
                      type="checkbox"
                      className="me-2 cursor-pointer tooltip-container slider round"
                      checked={item.is_court_setting_notice || false}
                      onChange={() => handleCourtSetting(index)}
                      disabled={item?.type !== 'application/pdf'}
                    />
                  </div>
                )}
                <i className="icon-pdf fa-lg text-primary-dark"></i>
                <p className="mb-0 ms-2 text-primary-dark text-medium text-truncate">{removeFirstSlash(item?.path)}</p>
              </div>
              {uploadingIndex === index ? (
                <div>
                  <i className="fas fa-spinner fa-spin ms-2"></i>
                </div>
              ) : (
                <div className="d-flex align-items-center ms-2">
                  {item.completed ? (
                    <div>
                      <i onClick={() => deleteFile(index)} className="far fa-check-circle  ms-3  mt-1"></i>
                    </div>
                  ) : (
                    <i onClick={() => deleteFile(index)} className="icon-delete icon-red pointer"></i>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FilesList;
