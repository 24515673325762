import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDiscrepancies } from 'services/fileManager/fileManagerServices';
import { useUserDetailsContext } from 'context/userDetailsContext';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { paginationDropdownOptions } from 'constants/dropdownOptions';
import { useHistory } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import useSpinner from 'hooks/useSpinner';
import DiscrepancyFilter from './DiscrepancyFilter';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { handlePageNumberInPagination } from 'common/pagination';

export const DiscrepancyHistory = ({ isCaseFileManager, isLeadFileManager, caseDetails, setShowDiscrepancyModal }) => {
  const userContext = useUserDetailsContext();
  const { isGlobalFileManager } = useFileManagerContext();
  let history = useHistory();

  const getPageLimit = () => {
    if (isCaseFileManager && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.discrepancies;
    } else if (isLeadFileManager && userContext) {
      return userContext?.firmDetails?.pagination_preference?.lead?.discrepancies;
    } else if (userContext) {
      return userContext?.firmDetails?.pagination_preference?.discrepancies?.self;
    }
  };

  const [listDiscrepanices, setListDiscrepancies] = useState([]);
  const [isForward, setIsForward] = useState(true);
  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);
  const [searchKey, setSearchKey] = useState('');
  const [filterCase, setFilterCase] = useState(isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : '');
  const [nextToken, setNextToken] = useState();
  const [transitionToken, setTransitionToken] = useState();

  const loadDiscrepancies = async (limit = 5, token = '', direction = 'forward', sk = '', searchKey = '', case_id = '') => {
    getDiscrepancies(limit, token, direction, sk, searchKey, case_id)
      .then((response) => {
        if (response && response?.data) {
          setListDiscrepancies(response.data?.tasks?.discrepancy_tasks);
          setNextToken(response?.data?.tasks?.next_token);
          setTransitionToken(response?.data?.tasks?.transition_token);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReload = async (rowData) => {
    try {
      const updatedRowData = await getDiscrepancies(
        pageLimit,
        '',
        'forward',
        rowData?.sk,
        searchKey,
        isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : filterCase?.id
      );

      if (updatedRowData?.data?.tasks?.discrepancy_tasks) {
        const updatedTask = updatedRowData.data.tasks.discrepancy_tasks;

        const updatedData = listDiscrepanices.map((item) =>
          item.sk === rowData.sk && JSON.stringify(item) !== JSON.stringify({ ...item, ...updatedTask })
            ? { ...item, ...updatedTask }
            : item
        );

        if (JSON.stringify(updatedData) !== JSON.stringify(listDiscrepanices)) {
          setListDiscrepancies(updatedData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFilePathForPagination = () => {
    if (isCaseFileManager && userContext) {
      return { module: 'case', subModule: '', listName: 'discrepancies' };
    } else if (isLeadFileManager && userContext) {
      return { module: 'leads', subModule: '', listName: 'discrepancies' };
    } else {
      return { module: 'discrepancies', subModule: '', listName: 'self' };
    }
  };

  const onNextPageClick = () => {
    loadDiscrepancies(
      pageLimit,
      !isForward ? transitionToken : nextToken,
      'forward',
      '',
      searchKey,
      isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : filterCase?.id
    );
    setIsForward(true);
  };
  const onPrevPageClick = () => {
    loadDiscrepancies(
      pageLimit,
      isForward ? transitionToken : nextToken,
      'reverse',
      '',
      searchKey,
      isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : filterCase?.id
    );
    setIsForward(false);
  };

  const onPageChange = (event) => {
    setPageLimit(event.value);
    let path = getFilePathForPagination();
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event?.value);
    loadDiscrepancies(
      event?.value,
      '',
      'forward',
      '',
      searchKey,
      isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : filterCase?.id
    );
    setIsForward(true);
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onNextPageClick}
          disabled={nextToken === null && isForward}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onPrevPageClick}
          disabled={transitionToken === null || (transitionToken && nextToken === null && !isForward)}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };

  const taskNameTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Task Identifier</span>
        <span className="text-break" title={rowData?.task_name ?? null}>
          {rowData?.task_name ?? '-'}
        </span>
      </React.Fragment>
    );
  };

  const filesTemplate = (rowData) => {
    const files = rowData?.file_details || {};
    const fileNames = Object.keys(files);
    const sanitizedSk = rowData?.sk.replace(/[^a-zA-Z0-9_-]/g, '_');
    const displayedFiles = fileNames.slice(0, 1);
    const remainingFiles = fileNames.slice(1);

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Files</span>
        <React.Fragment>
          <span className="text-break d-block">
            <span className="file-manager-ellipsis-text" title={displayedFiles}>
              {displayedFiles}
            </span>

            {remainingFiles.length > 0 && (
              <div>
                <Tag className={`p-mr-2 pa-tags more-tag more-tag-${sanitizedSk}`} rounded value={`+${remainingFiles.length} more`} />
                <Tooltip target={`.more-tag-${sanitizedSk}`} position="top" className="pa-tooltip discrepancy-files-tooltip">
                  {remainingFiles.map((files, index) => (
                    <span key={index}>
                      {files}
                      {index < remainingFiles?.length - 1 && <>{','}&nbsp;</>}
                    </span>
                  ))}
                </Tooltip>
              </div>
            )}
          </span>
        </React.Fragment>
      </React.Fragment>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Status</span>
        {rowData?.task_status === 'Success' ? (
          <>
            <div>
              <Tag className="tag-case s-tag bg-light" value={rowData?.task_status}></Tag>
            </div>
            <i
              id={`reload-${rowData?.rowData?.sk.replace(/[^a-zA-Z0-9_-]/g, '_')}`}
              className="fas fa-sync-alt pointer mx-2 mt-1 reload"
              onClick={() => handleReload(rowData)}
            ></i>
            <Tooltip target={`#reload-${rowData?.rowData?.sk.replace(/[^a-zA-Z0-9_-]/g, '_')}`} position="top">
              Refresh Status
            </Tooltip>
          </>
        ) : rowData?.task_status === 'Failure' ? (
          <>
            <div>
              <Tag className="tag-case da-tag bg-light" value={rowData?.task_status}></Tag>
            </div>
            <i className="fas fa-sync-alt pointer mx-2 mt-1" onClick={() => handleReload(rowData)}></i>
          </>
        ) : rowData?.task_status === 'In Progress' ? (
          <>
            <div>
              <Tag className="tag-case y-tag bg-light" value={rowData?.task_status}></Tag>
            </div>
            <i className="fas fa-sync-alt pointer mx-2 mt-1" onClick={() => handleReload(rowData)}></i>
          </>
        ) : (
          <Tag className="tag-case bg-light" value="-"></Tag>
        )}
      </React.Fragment>
    );
  };

  const actionBody = (rowData) => {
    const isDisabled = rowData?.task_status !== 'Success';

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Action</span>
        <i
          id={`redirection-${rowData?.sk.replace(/[^a-zA-Z0-9_-]/g, '_')}`}
          className={`bi bi-box-arrow-up-right mx-2 mt-1 pointer ${isDisabled ? 'text-muted' : 'text-primary-main'}`}
          style={isDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {}}
          onClick={() => {
            if (!isDisabled) {
              history.replace({
                pathname: `/cases/${rowData?.case_id}/files`,
                state: {
                  file: {
                    file_path: rowData?.file_path,
                  },
                  isFromClientDashboard: true,
                  case_details: {
                    case_id: rowData?.case_id,
                    case_name: rowData?.case_name,
                    display_name: rowData?.display_name,
                  },
                  breadCrumb: {
                    label: rowData?.folder_name,
                    value: rowData?.folder_object_id,
                  },
                },
              });
              setShowDiscrepancyModal(false);
            }
          }}
        ></i>
        <Tooltip target={`#redirection-${rowData?.sk.replace(/[^a-zA-Z0-9_-]/g, '_')}`} position="top">
          {isDisabled ? '' : 'View Report'}
        </Tooltip>
      </React.Fragment>
    );
  };

  useEffect(() => {
    loadDiscrepancies(
      pageLimit,
      '',
      'forward',
      '',
      searchKey,
      isCaseFileManager || isLeadFileManager ? caseDetails?.case_id : filterCase?.id
    );
  }, [searchKey, filterCase]);

  return (
    <>
      <DiscrepancyFilter
        isGlobalFileManager={isGlobalFileManager}
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        filterCase={filterCase}
        setFilterCase={setFilterCase}
      />
      <div className="col-12 p-0 datatable-responsive">
        <DataTable
          value={listDiscrepanices}
          className="p-datatable-responsive"
          paginator
          paginatorTemplate={paginatorTemplate}
          rows={pageLimit}
        >
          <Column header="Task Identifier" field="task_name" body={taskNameTemplate} bodyClassName="ellipsis-text" />
          <Column header="Files" field="files" body={filesTemplate} />
          <Column header="Status" field="task_status" body={statusTemplate} bodyClassName="ellipsis-text" />
          <Column header="Actions" body={actionBody} />
        </DataTable>
      </div>
    </>
  );
};
