import { classNames } from 'primereact/utils';
import React from 'react';
import { generateFullName } from 'utils/generateFullNameUtils';

export const GroupUserTooltip = (group, users) => {
  group.group_status = group?.group_status || group?.status;
  // **Added Function to Handle User Name Formatting**
  const userFormatting = (option) => {
    if (option?.value?.is_confirmed === false && option?.value?.status === 'ACTIVE') {
      return `${option?.label} (Unconfirmed)`; // **Handles Unconfirmed Users**
    } else if (option?.value?.is_confirmed && option?.value?.status === 'ARCHIVED') {
      return `${option?.label} (Inactive)`; // **Handles Archived Users**
    } else if (option?.value?.is_confirmed === false && option?.value?.status === 'ARCHIVED') {
      return `${option?.label} (Inactive)`;
    }
    return option?.label; // **Default Case**
  };

  return (
    <div>
      <p
        className={classNames({
          'p-medium': group?.group_status !== 'Deleted',
          'mb-1': true,
        })}
      >
        {/* {group?.group_name} {group?.group_status === 'Deleted' && '(Deleted)'} */}
        {group?.group_name} {group?.group_status === 'ARCHIVED' && '(Archived)'}
      </p>
      {group?.group_status !== 'Deleted' &&
        users
          ?.filter((v) => v?.group_id?.includes(group?.group_id))
          .map((user, index) => {
            const userName = user?.assignee_name || user?.recipient_name || user?.name || user?.team_member_name || generateFullName(user);
            return (
              <>
                {/* {user?.status === 'ARCHIVED' && ( */}
                <div key={index}>
                  <i className="pi pi-user me-2 ms-1"></i>{' '}
                  {/* {user?.assignee_name || user?.recipient_name || user?.name || user?.team_member_name}{' '} */}
                  {userFormatting({
                    label: userName,
                    value: user,
                  })}
                </div>
                {/* )} */}
              </>
            );
          })}
    </div>
  );
};
