import { useEffect, useState } from 'react';
import { totalCountEvent } from 'services/generalServices';
import { listUsers } from 'services/users/userServices';
import { sortByKey } from 'utils/utils';

export function useFirmUserList(is_include_groups = false, active_groups = false) {
  const [List, setList] = useState([]);

  const loadUserList = () => {
    totalCountEvent('user')
      .then((response) => {
        listUsers({ status: 'ACTIVE', limit: response.data, is_confirmed_user: true, is_include_groups, active_groups })
          .then((response) => {
            const firmUsers = response.data?.firm_users?.filter((user) => user.res_type === 'user') || [];
            const groups = response.data?.firm_users?.filter((user) => user.res_type === 'user-group') || [];
            const sortedFirmUsers = sortByKey(firmUsers, 'first_name');
            setList([...sortedFirmUsers, ...groups]);
          })
          .catch((err) => console.error(err));
      })
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    loadUserList();
  }, []);

  return { userList: List };
}
