import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { calendarCategories } from 'constants/dropdownOptions';
import { userOptionTemplate } from 'modules/Tasks/components/Header/MultiSelectTemplate';
import { getUserId, bringElementToTop, bringMultiSelectElementToTop, sortByKey } from 'utils/utils';
import { multiSelectTemplate } from 'modules/Tasks/components/TaskBoard/Board/PriorityDropdownTemplate';
import { handleFilterUpdateInUserContext } from 'common/pagination';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';

function CalendarFilter() {
  const {
    isGlobal,
    firmUserList,
    isClientCalendar,
    locationsList,
    typeList,
    calendarFilters,
    setCalendarFilters,
    generateFirmUserIdList,
    isFromCaseSpecificModule,
    isFromLeadSpecificModule,
    showUserFilter,
    setShowUserFilter,
    userContext,
  } = useCalendarContext();

  const user_id = getUserId();
  const { practiceAreaList } = usePracticeAreaList();

  const onChangeLocation = (e) => {
    let locationVal = e.value === 'All' ? '' : e.value;
    if (isGlobal) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'by_location', locationVal, true);
    }
    setCalendarFilters((prevValues) => ({
      ...prevValues,
      locationFilter: locationVal,
    }));
  };

  const onChangeEventType = (e) => {
    let eventTypeVal = e.value === 'All' ? '' : e?.value;
    if (isGlobal) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'event_type', eventTypeVal, true);
    }
    setCalendarFilters((prevValues) => ({
      ...prevValues,
      eventTypeFilter: eventTypeVal,
    }));
  };

  const onChangeCategory = (e) => {
    let categoryVal = e.value === 'All' ? '' : e?.value;
    if (isGlobal) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'by_category', categoryVal, true);
    }
    setCalendarFilters((prevValues) => ({
      ...prevValues,
      categoryFilter: categoryVal,
    }));
  };

  const onChangeCase = (e) => {
    if (isGlobal && !isClientCalendar) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'case', e, true);
    }
    setCalendarFilters((prevValues) => ({
      ...prevValues,
      caseFilter: e,
    }));
  };

  const onChangeFirmUser = (e) => {
    let userList = [...calendarFilters?.attendeesFilter];
    if (e?.target?.name === 'multiSelect') {
      userList = [...e.value];
    }
    if (isGlobal) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'users', userList, true);
    }
    setCalendarFilters((prevValues) => ({
      ...prevValues,
      attendeesFilter: userList,
    }));
  };

  function resetFilter() {
    handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'by_location', '', true);
    handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'event_type', '', true);
    handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'by_category', '', true);
    handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'by_practice_area', '', true);
    setCalendarFilters((prevValues) => {
      let obj = {
        ...prevValues,
        locationFilter: '',
        eventTypeFilter: '',
        categoryFilter: '',
        caseFilter: '',
        practiceAreaFilter: '',
      };

      if (isFromCaseSpecificModule || isFromLeadSpecificModule) {
        obj['attendeesFilter'] = [...generateFirmUserIdList()];
      }
      return obj;
    });
  }

  const generateSelectedItemsLabel = () => {
    const commonSubString = 'users selected';
    const attendeesFilterLength = calendarFilters?.attendeesFilter?.length;
    const label = attendeesFilterLength === firmUserList?.length ? `All ${commonSubString}` : `${attendeesFilterLength} ${commonSubString}`;

    return label;
  };

  const generateMultiSelectedItemsLabel = () => {
    const commonSubString = 'users selected';
    const attendeesFilterLength = calendarFilters?.attendeesFilter?.length;
    const firmUserListLength = firmUserList
      .map((entry) => entry.items.length) // Get the length of items array from each entry
      .reduce((total, length) => total + length, 0); // Sum all lengths
    const label = attendeesFilterLength === firmUserListLength ? `All ${commonSubString}` : `${attendeesFilterLength} ${commonSubString}`;

    return label;
  };

  const onPracticeAreaChange = (e) => {
    let praciceAreaValue = e.value === 'All' ? '' : e.value;
    if (isGlobal) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'calender', null, 'by_practice_area', praciceAreaValue, true);
    }
    setCalendarFilters((prevValues) => ({
      ...prevValues,
      practiceAreaFilter: praciceAreaValue,
    }));
  };

  return (
    <div className="d-flex align-items-center flex-wrap filter-wrap px-2 py-2 py-lg-0">
      <span className="text-bold black-600 me-2 ms-1">Filters: </span>
      {!Boolean(isClientCalendar) && (
        <>
          <div className="d-flex flex-column me-1 my-2 r-mb-0 filter-wrap">
            <Dropdown
              placeholder="By Category"
              className="mx-1"
              options={calendarCategories}
              onChange={onChangeCategory}
              value={
                calendarFilters.categoryFilter ||
                (isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.by_category : '')
              }
            />
          </div>
          <div className="d-flex flex-column me-1 my-2 r-mb-0 filter-wrap">
            <Dropdown
              placeholder="By Location"
              onChange={(e) => {
                onChangeLocation(e);
              }}
              options={[{ label: 'Show All', value: 'All' }, ...locationsList]}
              value={
                calendarFilters.locationFilter ||
                (isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.by_location : '')
              }
              className="mx-1"
              disabled={
                calendarFilters.categoryFilter === 'dob' ||
                calendarFilters.categoryFilter === 'case' ||
                calendarFilters.categoryFilter === 'task'
              }
            />
          </div>

          <div className="d-flex flex-column me-1 my-2 r-mb-0 filter-wrap">
            <Dropdown
              placeholder="By Type"
              options={[{ label: 'Show All', event_type: 'All' }, ...typeList]}
              optionLabel="event_type"
              optionValue="event_type"
              onChange={onChangeEventType}
              value={
                calendarFilters.eventTypeFilter ||
                (isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.event_type : '')
              }
              className="mx-1"
              disabled={
                calendarFilters.categoryFilter === 'dob' ||
                calendarFilters.categoryFilter === 'case' ||
                calendarFilters.categoryFilter === 'task'
              }
            />
          </div>
        </>
      )}

      {/* {isGlobal && (
        <div className="d-flex flex-column me-1 my-2 r-mb-0 filter-wrap mx-1">
          <CaseListDropDown
            isClient={isClientCalendar}
            value={calendarFilters?.caseFilter}
            onChange={(e) => {
              if (calendarFilters?.caseFilter !== e) {
                onChangeCase(e);
              }
            }}
            name="case"
            placeholder="Case/Lead Name"
            noOptionsMessage="No Cases/Leads Found"
            isClearable={!isClientCalendar}
          />
        </div>
      )} */}
      {isGlobal && (
        <div className="d-flex flex-column me-1 my-2 r-mb-0 filter-wrap">
          <Dropdown
            placeholder="By Practice Area"
            onChange={(e) => {
              onPracticeAreaChange(e);
            }}
            options={[{ practice_area_name: 'Show All', sk: 'All' }, ...practiceAreaList]}
            value={
              calendarFilters.practiceAreaFilter ||
              (isGlobal ? userContext?.firmDetails?.user_preferences?.filters?.calender?.by_practice_area : '')
            }
            className="mx-1"
            optionValue={'sk'}
            optionLabel="practice_area_name"
            // display="chip"
            // filter
            // name="practice_area"
            // showClear
            style={{ minWidth: 180 }}
          />
        </div>
      )}
      {!showUserFilter && !isClientCalendar && !isFromCaseSpecificModule && !isFromLeadSpecificModule && (
        <Button
          type="button"
          label={isGlobal ? 'Show Team Calendars' : 'Show User'}
          className="p-button me-2 button-text px-1 adv-filter text-bold text-primary-main"
          onClick={() => {
            setShowUserFilter(true);
          }}
        />
      )}

      {/* {!Boolean(isClientCalendar) && showUserFilter && ( */}
      {!Boolean(isClientCalendar) && (
        <div className="d-flex flex-column me-1 my-2 r-mb-0 filter-wrap">
          {(isFromCaseSpecificModule || isFromLeadSpecificModule) && (
            <MultiSelect
              value={firmUserList?.length > 0 ? calendarFilters?.attendeesFilter : []} // {firmUserIds}
              options={bringElementToTop(sortByKey(firmUserList, 'first_name'), user_id)}
              onChange={onChangeFirmUser}
              optionValue={'user_id'}
              placeholder="Select Users"
              filter
              className="searchable-dropdown input-shadow"
              itemTemplate={userOptionTemplate}
              optionLabel="full_name"
              maxSelectedLabels={1}
              selectAll={true}
              name="multiSelect"
              selectedItemsLabel={generateSelectedItemsLabel()}
            />
          )}
          {!isFromCaseSpecificModule && !isFromLeadSpecificModule && showUserFilter && (
            <MultiSelect
              value={
                firmUserList?.length > 0
                  ? userContext?.firmDetails?.user_preferences?.filters?.calender?.users || calendarFilters?.attendeesFilter
                  : []
              } // {firmUserIds}
              options={bringMultiSelectElementToTop(firmUserList, user_id)}
              onChange={onChangeFirmUser}
              optionValue={'assignee_id'}
              // optionValue={(v) => v}
              placeholder="Select Users"
              filter
              className="searchable-dropdown input-shadow"
              // itemTemplate={userOptionTemplate}
              itemTemplate={multiSelectTemplate}
              optionLabel="assignee_name"
              optionGroupLabel="assignee_name"
              optionGroupChildren="items"
              maxSelectedLabels={1}
              selectAll={true}
              name="multiSelect"
              selectedItemsLabel={generateMultiSelectedItemsLabel()}
            />
          )}
        </div>
      )}
      {!Boolean(isClientCalendar) ? (
        <div className="pointer ms-0 p-2" title="Clear Filters" onClick={resetFilter}>
          <i className="fas fa-sync-alt"></i>
          <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
        </div>
      ) : null}
    </div>
  );
}

export default CalendarFilter;
