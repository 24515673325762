import React, { useMemo, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { Card } from 'primereact/card';
import { AutoComplete } from 'primereact/autocomplete';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { InputSwitch } from 'primereact/inputswitch';

import ImageComponent from 'shared/ImageComponent';
import { handleRedirection } from 'utils/utils';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import CustomFields from 'modules/lead/AddLead/components/ContactForm/CustomFields';
import { generateFullName } from 'utils/generateFullNameUtils';
import { useToggle } from 'hooks/useToggle';
import { useContacts } from 'hooks/useContacts';
import { useCaseContact } from 'modules/cases/hooks/useCaseContact';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { useHistory } from 'react-router-dom';
import { countriesList, stateList } from 'constants/stateList';
import { validatePhoneNumber } from 'modules/lead/helpers/helpers';
import { emailDuplicateCheck } from 'services/Contact/contactServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const state_list = stateList?.map((index) => {
  return {
    label: index.abbreviation + ' - ' + index.name,
    value: index.abbreviation + ' - ' + index.name,
  };
});

const itemTemplate = (e) => generateFullName(e);

export default function Client({ caseDetails, isLeadSpecific, isCaseClosed, isLeadClosed, getDetails }) {
  const [isEdit, toggleIsEdit] = useToggle(false);
  const [client, setClient] = useState();
  const { contactList, searchAsync, loading } = useContacts();
  const history = useHistory();
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    reset,
  } = useForm();
  const {
    customFields: customFieldList,
    loadCustomFields,
    changeClient,
    handleUpdateContact,
  } = useCaseContact({ caseDetails, getDetails, isLeadSpecific });

  const isDisabled = isCaseClosed || isLeadClosed;

  const isArchivedDisabled = caseDetails?.client_details?.some((item) => item?.status === 'ARCHIVED');

  const clientDetails = useMemo(() => {
    if (caseDetails?.client_details?.length === 1) {
      return caseDetails?.client_details[0];
    } else {
      return caseDetails?.client_details?.find((data) => data?.is_primary_contact === true);
    }
  }, [caseDetails]);

  const customFields = useMemo(() => {
    return clientDetails?.custom_field
      ?.filter((v) => v?.value)
      ?.map((c) => {
        let item2 = customFieldList?.find((item) => item?.custom_field_id === c?.custom_field_id);
        if (item2) {
          c.position = item2.position;
        }
        return c;
      })
      ?.sort((a, b) => a?.position - b?.position);
  }, [clientDetails, customFieldList]);

  const gotoContacts = (e) => {
    const { contact_id, sk, pk } = clientDetails;
    const url = contact_id ? `/contacts/${contact_id}` : '/contacts';
    e.preventDefault();

    handleRedirection(history, url, clientDetails);
    if (sk && pk) handleSettingSkAndPkToLocalStorage(sk, pk);
  };
  useEffect(() => {
    loadCustomFields();
  }, []);

  useEffect(() => {
    if (clientDetails) {
      reset({ ...clientDetails, custom_field: customFields, country: clientDetails?.country ?? 'United States' });
    }
  }, [clientDetails, customFields]);

  const handleEditClient = () => {
    setClient(
      clientDetails?.first_name ? (clientDetails?.first_name ?? '') + ' ' + (clientDetails?.last_name ?? '') : clientDetails?.title
    );
    toggleIsEdit();
  };

  const updateCaseContact = () => {
    changeClient(client, clientDetails?.contact_id, isLeadSpecific).then(() => {
      setClient('');
      toggleIsEdit();
    });
  };

  const validatePhone = async (value) => {
    if (clientDetails?.phone === value) return true;
    if (!Boolean(value)) return true;
    return validatePhoneNumber(value, clientDetails?.contact_id);
  };

  const emailValidation = async (value) => {
    if (!Boolean(value)) return true;
    if (clientDetails?.email === value) return true;
    try {
      await emailDuplicateCheck(value);
      return true;
    } catch (error) {
      return 'Email already exists';
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] && <InputErrorMessage>{errors[name].message}</InputErrorMessage>;
  };

  const onSubmit = (formData) => {
    handleUpdateContact(formData);
  };

  return (
    <Card title="CLIENT">
      <div className="d-flex pb-3 align-items-center justify-content-between border-bottom filter-wraper">
        <div className="client-name mb-3 mb-lg-0 w-100 d-flex align-items-center contact-name-input">
          {isEdit ? (
            <>
              <AutoComplete
                value={client}
                suggestions={contactList}
                placeholder="Search Existing Contacts"
                completeMethod={searchAsync}
                loading={loading}
                itemTemplate={itemTemplate}
                selectedItemTemplate={itemTemplate}
                className="flex-grow-1 input-shadow w-100"
                inputClassName="w-100"
                onChange={(e) => setClient(e.value)}
                disabled={isDisabled}
              />
              <Button
                icon="pi pi-check"
                className="p-button-secondary mx-2"
                type="button"
                onClick={updateCaseContact}
                disabled={typeof client !== 'object'}
                loading={loading}
              />
              <Button
                icon="pi pi-times"
                className="p-button-secondary outline"
                type="button"
                onClick={() => {
                  setClient('');
                  toggleIsEdit();
                }}
                disabled={loading}
              />
            </>
          ) : (
            <>
              {clientDetails?.first_name || clientDetails?.title ? (
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="d-flex align-items-center" onClick={gotoContacts}>
                    <ImageComponent filePath={clientDetails.profile_image} fileName={clientDetails.first_name} fileSize="small.jpg" />
                    <a href className="contact-name-input redirection-link-no-text-transform">
                      {generateFullName(clientDetails)}
                    </a>
                    {!(clientDetails?.status === 'ACTIVE') ? (
                      <Tag className="m-1 ms-0 tag-cases border-0" value={clientDetails?.status} rounded></Tag>
                    ) : null}
                  </div>
                  {!isDisabled && (
                    <Button tooltip="Change Client" className="p-button-secondary outline" onClick={handleEditClient}>
                      Change
                    </Button>
                  )}
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  N/A {!isDisabled && <i className="icon-edit text-primary-main pointer ms-2" onClick={handleEditClient} />}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-12 col-lg-4 p-medium">
          Email
          {watch('is_create_client_portal') === true && <label className="text-danger">*</label>}
        </div>
        <div className="col-12 col-lg-8">
          <Controller
            name="email"
            control={control}
            rules={{
              required: watch('is_create_client_portal') ? 'Email is required.' : false,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Entered value does not match email format',
              },
              validate: { asyncValidation: (v) => emailValidation(v) },
            }}
            render={({ field }) => (
              <InputText
                disabled={isDisabled || isArchivedDisabled}
                placeholder="Email"
                id={field.name}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  if (!e.target.value) {
                    setValue('is_create_client_portal', false);
                  }
                }}
                onBlur={() => trigger('email')}
                className="input-shadow w-100 border-0"
              />
            )}
          />
          {getFormErrorMessage('email')}
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-12 col-lg-4 p-medium">Phone Number</div>
        <div className="col-12 col-lg-8">
          <Controller
            name="phone"
            control={control}
            rules={{
              validate: { asyncValidation: (v) => validatePhone(v) },
              minLength: { value: 10, message: 'Min length should be 10' },
            }}
            render={({ field }) => (
              <NumberFormat
                disabled={isDisabled || isArchivedDisabled}
                className="input-shadow w-100 p-2 border-0 number-format"
                placeholder="Type in"
                format="(###) ###-####"
                mask="_"
                name="phone"
                keyfilter={'pnum'}
                //onValueChange={(e) => setValue('phone', e.value)}
                onValueChange={(e) => {
                  setValue('phone', e.value);
                  trigger('phone'); // Trigger validation on value change
                }}
                value={field.value}
                //onBlur={() => trigger('phone')}
              />
            )}
          />
          {getFormErrorMessage('phone')}
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-12 col-lg-4">Street</div>
        <div className="col-12 col-lg-8">
          <Controller
            name="street"
            control={control}
            render={({ field }) => (
              <TextSnippetWrapper
                type="text"
                disabled={isDisabled || isArchivedDisabled}
                className="input-shadow w-100 border-0"
                placeholder="Street"
                {...field}
              />
            )}
          />
        </div>
      </div>

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-12 col-lg-4">City</div>
        <div className="col-12 col-lg-8">
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextSnippetWrapper
                type="text"
                disabled={isDisabled || isArchivedDisabled}
                className="input-shadow w-100 border-0"
                placeholder="City"
                {...field}
              />
            )}
          />
        </div>
      </div>

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-12 col-lg-4">State</div>
        <div className="col-12 col-lg-8">
          {watch('country') === 'United States' ? (
            <div>
              <Controller
                name="state"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Dropdown
                    className="input-shadow w-100 border-0"
                    placeholder="State"
                    value={value}
                    options={state_list}
                    onChange={(e) => onChange(e.value)}
                    filter
                    disabled={isDisabled || isArchivedDisabled}
                  />
                )}
              />
            </div>
          ) : (
            <div>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <TextSnippetWrapper
                    type="text"
                    disabled={isDisabled || isArchivedDisabled}
                    className="input-shadow w-100 border-0"
                    {...field}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-12 col-lg-4">Zip Code</div>
        <div className="col-12 col-lg-8">
          <Controller
            name="zip"
            control={control}
            render={({ field }) => (
              <InputText
                disabled={isDisabled || isArchivedDisabled}
                className="input-shadow w-100 border-0"
                placeholder="Zip"
                {...field}
                maxLength={5}
              />
            )}
          />
        </div>
      </div>

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-12 col-lg-4">Country</div>
        <div className="col-12 col-lg-8">
          <Controller
            name="country"
            control={control}
            defaultValue={'United States'}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                className="input-shadow w-100 border-0"
                placeholder="Country"
                value={value}
                options={countriesList}
                optionLabel="name"
                optionValue="name"
                onChange={(e) => onChange(e.value)}
                disabled={value || isDisabled || isArchivedDisabled}
              />
            )}
          />
        </div>
      </div>
      {clientDetails?.contact_type === 'person' && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-6 col-lg-4 p-medium" id="#detail_lawft_access">
            Enable Lawft Access
          </div>
          <div className="col-6 col-lg-8">
            <div className="" id="#detail_lawft_access">
              <Controller
                name="is_create_client_portal"
                control={control}
                render={({ field: { value, onChange, name } }) => (
                  <>
                    <InputSwitch
                      checked={value}
                      name={name}
                      id={'detail_lawft_access'}
                      onChange={(e) => onChange(e.value)}
                      className={`input-switch email-validatio-tip ${!watch('email') || isDisabled ? ' pe-none opacity-25' : ''}`}
                      disabled={!watch('email') || isDisabled || isArchivedDisabled}
                    />
                    {!watch('email') && (
                      <Tooltip target={`#detail_lawft_access`} position="right" className="case-tooltip">
                        {'Add Email To Enable Lawft Access'}
                      </Tooltip>
                    )}
                  </>
                )}
              />
            </div>
          </div>
        </div>
      )}
      {!isLeadSpecific && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-6 col-lg-4 p-medium" id="#detail_lawft_access">
            Primary Contact
          </div>
          <div className="col-6 col-lg-8">
            <Controller
              name="is_primary_contact"
              control={control}
              render={({ field: { value, name, onChange } }) => (
                <InputSwitch
                  checked={value}
                  name={name}
                  onChange={(e) => onChange(e.value)}
                  disabled={isDisabled}
                  className={`input-switch email-validatio-tip ${isDisabled ? ' pe-none opacity-25' : ''}`}
                />
              )}
            />
          </div>
        </div>
      )}
      {!isLeadSpecific && (
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-6 col-lg-4 p-medium" id="#detail_lawft_access">
            Billing Contact
          </div>
          <div className="col-6 col-lg-8">
            <Controller
              name="is_billing_contact"
              control={control}
              render={({ field: { value, name, onChange } }) => (
                <InputSwitch
                  checked={value}
                  name={name}
                  onChange={(e) => onChange(e.value)}
                  disabled={isDisabled}
                  className={`input-switch email-validatio-tip ${isDisabled ? ' pe-none opacity-25' : ''}`}
                />
              )}
            />
          </div>
        </div>
      )}
      <div className="row pe-3">
        <CustomFields
          field="custom_field"
          fieldDisable={isDisabled || isArchivedDisabled}
          control={control}
          setValue={setValue}
          watch={watch}
        />
      </div>
      <div className="footer-btn">
        <Button
          label="Save"
          disabled={isDisabled || client || (isLeadSpecific && isArchivedDisabled)}
          className="btn-outline p-button-secondary"
          type="button"
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </Card>
  );
}
