import { performRequest, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';

import { getTenantDetails } from 'utils/utils';

export const listEventTypes = (obj) => {
  const { pageLimit = 10, pagination_token = '', pagination_direction = 'forward' } = obj;

  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.listEventTypes}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const listEvents = (obj) => {
  const {
    event_type = '',
    attendee = '',
    category_filter = '',
    location = '',
    date_filter_param = '',
    range_end_param = '',
    case_id = '',
    limit = 1000,
    practice_area = '',
    user_timezone = '',
    data,
  } = obj;
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${
    apiEndPoints.calendar.listEvents
  }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&event_type=${event_type}&attendee=${attendee}&category_filter=${
    category_filter ? category_filter : ''
  }&practice_area=${
    practice_area ? practice_area : ''
  }&location=${location}&limit=${limit}&date_filter_param=${date_filter_param}&range_end_param=${range_end_param}&case_id=${
    case_id ? case_id : ''
  }&user_timezone=${user_timezone ? user_timezone : ''}`;
  return performRequest(apiEndPoints.methodType.POST, url, data);
};

export const calenderUserList = (obj) => {
  const { caseId, is_person, all_contacts = false, contact_id = false, is_team_member_list = false } = obj;
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.userList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${caseId ? `&case_id=${caseId}` : ''}${
    all_contacts ? `&all_contacts=${all_contacts}` : ''
  }${is_person ? `&is_person=${is_person}` : ''}${is_team_member_list ? `&is_team_member_list=${is_team_member_list}` : ''}`;
  if (contact_id) url = url + '&contact_id=' + contact_id;

  return performRequest(apiEndPoints.methodType.GET, url);
};

export const createEventType = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.createEventType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.POST, url, data);
};

export const getEvent = (event_id, selectedEvent) => {
  let sk = encodeURIComponent(selectedEvent?.extendedProps?.sk || '');
  let is_recurring = selectedEvent?.extendedProps?.is_recurring ?? '';
  let overriden_date = selectedEvent?.extendedProps?.meeting_start_time || '';
  let is_clone = selectedEvent?.extendedProps?.is_clone || '';

  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.getEvent}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}${
    event_id ? `&event_id=${event_id}` : ''
  }&overriden_date=${overriden_date}&is_recurring=${is_recurring}&is_clone=${is_clone}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const addEvent = (obj) => {
  const { data, case_id = '', is_lead = false, timeEntryData = '' } = obj;
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.createEvent}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequest(apiEndPoints.methodType.POST, url, data, timeEntryData);
};

export const updateEvent = (obj) => {
  const { reminder_updated, send_update_notification, data, case_id = '', is_lead = false, timeEntryData = '' } = obj;
  const [tenantId, tenantSk] = getTenantDetails();

  let url = `${
    apiEndPoints.calendar.updateEvent
  }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}&is_reminder_updated=${reminder_updated}&send_update_notification=${send_update_notification}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequest(apiEndPoints.methodType.PUT, url, data, timeEntryData);
};

export const deleteEvent = (obj) => {
  let { pk, is_lead = false, timeEntryData = '' } = obj;
  pk = pk ? pk.replaceAll('#', '%23') : '';
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.deleteEvent}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequest(apiEndPoints.methodType.DELETE, url, {}, timeEntryData);
};

export const updateCalendarSettings = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.updateSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.PUT, url, data);
};

export const getCalendarSettings = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.getSettings}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const getFreeTime = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.getFreeTime}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data, null, true, true);
};
