import React from 'react';
import { Controller } from 'react-hook-form';
import { Calendar } from 'primereact/calendar';
// import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';

// import { paymentTerms, statusOptions } from '../../configs/create_invoice_configs';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import ReminderComponent from 'modules/calendar/Components/CreateEventModal/Components/EventReminders';
import LabelName from 'components/UI/LabelName/LabelName';
import _ from 'lodash';

const InvoiceDetailsForm = (props) => {
  // TODO:MUST remove direct state update

  const {
    isSubscription,
    isContigencyFee,
    isPersonalInjuryPracticeArea,
    invoiceNumber,
    invoiceDate,
    paymentPlan,
    selectedPaymentTerm,
    dueDate,
    selectedStatus,
    checkDate,
    checkNumber,
    setSelectedPaymentTerm,
    setDueDate,
    setCheckDate,
    setSelectedStatus,
    setInvoiceDate,
    setCheckNumber,
    formErrors,
    setFormErrors,
    isChanged,
    control,
    watch,
    setValue,
    viewInvoice,
    invoiceDetails,
    setIsChanged = { setIsChanged },
  } = props || {};

  const scrollToPaymentPlan = () => {
    let divElement = document.getElementById('payment-plan');
    divElement?.scrollIntoView({
      top: divElement.scrollHeight, //scroll to the bottom of the element
      behavior: 'smooth', //auto, smooth, initial, inherit
    });
  };

  return (
    <div className="col-12 col-md-6 mt-3 mt-sm-0">
      <div className="row">
        <div className="col-12 caption-bold">INVOICE DETAILS</div>
      </div>
      <div className="shadow-small p-3">
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Invoice #<span className="text-danger">*</span>
          </div>
          <div className="col-12 col-md-6">
            <InputText className="input-shadow w-100 border-0 p-2" placeholder="Invoice ID" value={invoiceNumber} disabled />
            {formErrors?.invoice_id && <span className="text-danger">Required</span>}
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Invoice Date
            <span className="text-danger">*</span>
          </div>
          <div className="col-12 col-md-6">
            <Calendar
              className="input-shadow w-100 border-0 p-2 input-height"
              placeholder="Invoice Date"
              value={invoiceDate}
              onChange={(e) => {
                setInvoiceDate(e.value);
                isChanged.invoice = true;
                formErrors.invoice_date = '';
                setFormErrors({ ...formErrors });
              }}
              disabled={isSubscription}
            />
            {formErrors && formErrors.invoice_date && <span className="text-danger">Required</span>}
          </div>
        </div>

        {!isContigencyFee && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">
              Due Date
              <span className="text-danger">*</span>
            </div>
            <div className="col-12 col-md-6">
              {paymentPlan ? (
                <span role="button" onClick={scrollToPaymentPlan}>
                  <u>Payment Plan</u>
                </span>
              ) : (
                <Calendar
                  className="input-shadow input-height w-100 border-0 p-2"
                  placeholder="Due Date"
                  value={dueDate}
                  onChange={(e) => {
                    setDueDate(e.value);
                    isChanged.invoice = true;
                    formErrors.due_date = '';
                    setFormErrors({ ...formErrors });
                  }}
                  disabled={isSubscription}
                />
              )}

              {formErrors && formErrors.due_date && <span className="text-danger">Required</span>}
            </div>
          </div>
        )}

        {/* {!paymentPlan && !isContigencyFee && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">
              Payment Terms
            </div>
            <div className="col-12 col-md-6">
              <Dropdown
                className="input-shadow w-100 border-0"
                options={paymentTerms}
                placeholder="Select"
                onChange={(e) => {
                  setSelectedPaymentTerm(e?.value);
                  setIsChanged((prevVal) => {
                    return {
                      ...prevVal,
                      invoice: true,
                    };
                  });
                }}
                value={selectedPaymentTerm}
                disabled={isSubscription}
                filter
              />
            </div>
          </div>
        )} */}
        {!isContigencyFee && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-6 col-sm-5 p-medium mt-3">Reminders</div>
            <div className="col-md-6 col-sm-7 mt-2">
              {watch('reminder')?.length > 0 && (
                <div className="d-flex pt-2 flex-wrap w-100">
                  <div className="col-md-2 col-12">
                    <LabelName required={false}>Send to</LabelName>
                  </div>
                  <div className="col-md-10 col-12 d-flex">
                    <div className="d-flex align-items-center me-4">
                      <Controller
                        name="is_billing_contact"
                        control={control}
                        render={({ field }) => <Checkbox inputId="cb3" checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
                      />
                      <label htmlFor="cb3" className="ms-1 p-checkbox-label">
                        Billing Contact
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Controller
                        name="is_client_contact"
                        control={control}
                        render={({ field }) => <Checkbox inputId="cb4" checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
                      />
                      <label htmlFor="cb4" className="ms-1 p-checkbox-label">
                        Client Contact
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <ReminderComponent control={control} watch={watch} isGlobal={false} isFromInvoice isPayment setValue={setValue} />
            </div>
          </div>
        )}

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Status
            <span className="text-danger">*</span>
          </div>
          {/* <div className="col-12 col-md-6">
            <Dropdown
              className="input-shadow w-100 border-0"
              options={statusOptions}
              onChange={(e) => {
                setSelectedStatus(e.value);
                isChanged.invoice = true;
                formErrors.status = '';
                setFormErrors({ ...formErrors });
              }}
              placeholder="Select"
              value={selectedStatus}
              filter
            />
            {formErrors && formErrors.status && <span className="text-danger">Required</span>}
          </div> */}
          <div className="col-md-6 col-12 d-flex align-items-center edit-field">
            {
              <div className="w-100 h-100">
                {viewInvoice ? (invoiceDetails.invoice_status ? _.startCase(_.toLower(invoiceDetails.invoice_status)) : '') : 'Created'}
              </div>
            }
          </div>
        </div>
        {isContigencyFee && isPersonalInjuryPracticeArea && (
          <>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-12 col-md-6 p-medium">
                Check Date
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-md-6">
                <Calendar
                  value={checkDate}
                  onChange={(e) => {
                    setCheckDate(e.value);
                    isChanged.invoice = true;
                    formErrors.check_date = '';
                    setFormErrors({ ...formErrors });
                  }}
                  className="input-shadow w-100 border-0 p-2 input-height"
                  placeholder="Check Date"
                />
                {formErrors && formErrors.check_date && <span className="text-danger">Required</span>}
              </div>
            </div>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-12 col-md-6 p-medium">
                Check Number
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-md-6">
                <TextSnippetWrapper
                  type="text"
                  value={checkNumber}
                  onChange={(e) => {
                    setCheckNumber(e?.target?.value);
                    isChanged.invoice = true;
                    formErrors.check_number = '';
                    setFormErrors({ ...formErrors });
                  }}
                  className="input-shadow w-100 border-0 p-2 input-height"
                  placeholder="Check Number"
                />
                {formErrors && formErrors.check_number && <span className="text-danger">Required</span>}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetailsForm;
