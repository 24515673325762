import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { ModalHeader } from 'shared/ModalHeader';
import { parseRemiderPayload, parseReminderData } from 'utils/utils';
import { createPaymentReminder, updatePaymentReminder } from 'services/billingServices';
import ReminderComponent from 'modules/calendar/Components/CreateEventModal/Components/EventReminders';
import LabelName from 'components/UI/LabelName/LabelName';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

export const Reminders = (props) => {
  const { selectedRequest, onSuccess, onHide, isInvoice } = props || {};

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      reminder: parseReminderData(selectedRequest?.reminder) || [],
      is_billing_contact: selectedRequest?.invoice_assignee?.includes('billing_contact'),
      is_client_contact: selectedRequest?.invoice_assignee?.includes('main_client'),
    },
  });
  const { addToast } = useToast();

  const isEdit = selectedRequest?.reminder?.length > 0;

  const onSubmit = (formData) => {
    let data = {
      due_date: selectedRequest?.due_date,
      reminder: parseRemiderPayload(formData?.reminder) || [],
    };
    let invoice_assignee = [];
    if (isInvoice) {
      if (formData?.is_billing_contact) {
        invoice_assignee.push('billing_contact');
      }
      if (formData?.is_client_contact) {
        invoice_assignee.push('main_client');
      }
      data.invoice_assignee = invoice_assignee;
    }
    if (isEdit) {
      updatePaymentReminder(data, selectedRequest.sk, props?.isLeadSpecific ? props?.isLeadSpecific : selectedRequest?.is_lead)
        .then(() => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.REMINDER_UPDATE_SUCCESS);
          onSuccess();
          onHide();
        })
        .catch((err) => {
          console.log('err', err);
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.REMINDER_UPDATE_FAILED);
        });
    } else {
      createPaymentReminder(selectedRequest.sk, data, props?.isLeadSpecific ? props?.isLeadSpecific : selectedRequest?.is_lead)
        .then(() => {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.REMINDER_CREATE_SUCCESS);
          onSuccess();
          onHide();
        })
        .catch((err) => {
          console.log('err', err);
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.REMINDER_CREATE_FAILED);
        });
    }
  };

  return (
    <Dialog visible={props.show} onHide={onHide} className="new-task-popup" header={ModalHeader} footer={<></>}>
      <GlobalLoader />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-heading F-size16 text-bold mb-3">{isEdit ? 'Update Reminder' : 'Create Reminder'}</div>
        {isInvoice && (
          <div className="d-flex pt-2 flex-wrap w-100">
            <div className="col-md-4 col-12">
              <LabelName required={false}>Send to</LabelName>
            </div>
            <div className="col-md-8 col-12 d-flex">
              <div className="d-flex align-items-center me-4">
                <Controller
                  name="is_billing_contact"
                  control={control}
                  render={({ field }) => <Checkbox inputId="cb3" checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
                />
                <label htmlFor="cb3" className="ms-1 p-checkbox-label">
                  Billing Contact
                </label>
              </div>
              <div className="d-flex align-items-center">
                <Controller
                  name="is_client_contact"
                  control={control}
                  render={({ field }) => <Checkbox inputId="cb4" checked={field.value} onChange={(e) => field.onChange(e.checked)} />}
                />
                <label htmlFor="cb4" className="ms-1 p-checkbox-label">
                  Client Contact
                </label>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex py-2 flex-wrap w-100">
          <ReminderComponent
            setValue={setValue}
            control={control}
            watch={watch}
            isGlobal={false}
            isPayment={isInvoice}
            optionText="before due date"
          />
        </div>
        {selectedRequest?.due_amount <= 0 && <LabelName className={'text-danger'}>There is no due amount</LabelName>}
        <div className="footer-btn">
          <Button label="Cancel" onClick={onHide} className="p-button-secondary outline me-2" />
          <Button
            label={isEdit ? 'Update' : 'Save'}
            className="p-button-secondary"
            disabled={selectedRequest?.due_amount <= 0 || !isDirty}
            type="submit"
          />
        </div>
      </form>
    </Dialog>
  );
};
