import { useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function useBreadCrumbs() {
  const location = useLocation();

  const [breadCrumbs, setBreadCrumbs] = useState(
    location?.state?.breadCrumb ? [{ label: location?.state?.breadCrumb?.label, value: location?.state?.breadCrumb?.value }] : []
  );

  function handleBreadCrumbs(e) {
    setBreadCrumbs((prev) => [
      ...prev,
      {
        label: e.display_name,
        value: e.object_id,
      },
    ]);
  }

  function filterBreadCrumbs(folder_id) {
    let folderIndex = breadCrumbs?.findIndex((item) => item.value === folder_id);
    let _breadCrumbs = breadCrumbs;
    setBreadCrumbs(_breadCrumbs.splice(0, folderIndex + 1));
  }

  return { breadCrumbs, setBreadCrumbs, handleBreadCrumbs, filterBreadCrumbs };
}
