import React from 'react';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';

const BillingSummary = React.memo(({ billingSummary }) => {
  const { unpaid_expenses = 0, unpaid_payment_request = 0, unpaid_invoices = 0 } = billingSummary?.unpaid_info || {};

  return (
    <>
      <div className="caption-bold mb-2 black-600 mt-3">Status</div>
      <Card className="shadow-middle">
        <div className="row">
          <SummaryCard count={unpaid_expenses} label="Expenses" tagLabel="Uninvoiced" />
          <SummaryCard count={unpaid_payment_request} label="Requests" tagLabel="Unpaid" severity="danger" />
          <SummaryCard count={unpaid_invoices} label="Invoices" tagLabel="Paid" severity="success" />
        </div>
      </Card>
    </>
  );
});

const SummaryCard = ({ count, label, tagLabel, severity }) => (
  <div className="col-sm text-center col-6 mt-sm-20">
    <div className="text-bold">{count}</div>
    <div className="text-muted F-size12 pb-1 text-medium">{label}</div>
    <div className="text-muted F-size12">
      <Tag value={tagLabel} severity={severity} className="da-tag" />
    </div>
  </div>
);

export default BillingSummary;
