import React from 'react';
import { Card } from 'primereact/card';
import { dollarFormat } from 'utils/utils';

const MonthToDateSummary = React.memo(({ billingSummary }) => {
  const {
    month_to_date_automated_time_entries = 0,
    month_to_date_expenses = 0,
    month_to_date_invoice = 0,
    month_to_date_payment_history = 0,
    month_to_date_manual_time_entries = 0,
    month_to_date_payment_request = 0,
    month_to_date_temporary_fund = 0,
    month_to_date_trust_account = 0,
  } = billingSummary?.month_to_date_info || {};

  const summaryData = [
    { value: month_to_date_expenses, label: 'Expenses Amount' },
    { value: month_to_date_automated_time_entries, label: 'Automated Time Entries Amount' },
    { value: month_to_date_manual_time_entries, label: 'Manual Time Entries Amount' },
    { value: month_to_date_payment_request, label: 'Payment Request Total' },
    { value: month_to_date_invoice, label: 'Invoice Due' },
    { value: month_to_date_payment_history, label: 'Payment History Amount' },
    { value: month_to_date_trust_account, label: 'Trust Account Amount' },
    { value: month_to_date_temporary_fund, label: 'Temporary Fund Amount' },
  ];

  return (
    <>
      <div className="caption-bold mb-2 black-600 mt-3">MONTH TO DATE</div>
      <Card className="shadow-middle">
        <div className="row gy-3" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
          {summaryData.map((item, idx) => (
            <SummaryItem key={idx} value={item.value} label={item.label} />
          ))}
        </div>
      </Card>
    </>
  );
});

const SummaryItem = ({ value, label }) => (
  <div className="text-center col-auto mt-sm-20" style={{ width: 130 }}>
    <div className="text-bold">{dollarFormat(value)}</div>
    <div className="text-muted F-size12 pb-1 text-medium">{label}</div>
  </div>
);

export default MonthToDateSummary;
