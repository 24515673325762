import React, { useEffect } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { useToast } from 'context/ToastContext';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import useUpdateFiles from 'modules/file_manager/hooks/useUpdateFiles';

import CustomHeader from './CustomHeader';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { toastConstant } from 'constants/toastmessage';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

function RenameModal() {
  const { modals, selectedItem, isLeadFileManager, handleGetFiles, pageLimit } = useFileManagerContext();

  const { showRenameModal, setShowRenameModal } = modals;

  const { updateFiles } = useUpdateFiles();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const { addToast } = useToast();

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.entry_type === 'folder') {
        setValue('rename', selectedItem.display_name);
      } else {
        setValue('rename', selectedItem.display_name.split('.').slice(0, -1).join('.'));
      }
    }
  }, [selectedItem]);

  function renameHandler(data) {
    if (selectedItem.entry_type === 'file') {
      let file_extension = selectedItem?.file_name?.split('.')?.reverse()[0];
      data.rename = `${data.rename}.${file_extension}`;
    }

    let updated_file = {
      display_name: data.rename,
      file_name: data.rename,
      pk: selectedItem['pk'],
      sk: selectedItem['sk'],
      type: selectedItem['entry_type'],
    };

    let obj = {
      data: updated_file,
      is_lead: isLeadFileManager,
    };

    updateFiles(obj)
      .then(() => {
        handleGetFiles(pageLimit);
        setShowRenameModal(false);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_RENAME_SUCCESS);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const RenameFooter = (
    <div className="footer-btn">
      <Button label="Cancel" onClick={() => setShowRenameModal(false)} className="p-button p-button-secondary outline mx-2" />
      <Button label="Rename" onClick={handleSubmit(renameHandler)} className="p-button p-button-secondary" />
    </div>
  );

  return (
    <Dialog
      className="new-task-popup"
      header={<CustomHeader onHide={() => setShowRenameModal(false)} />}
      footer={RenameFooter}
      visible={showRenameModal}
      modal
      onHide={() => setShowRenameModal(false)}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{selectedItem.entry_type === 'folder' ? 'Rename Folder' : 'Rename File'}</div>
      </div>
      <Controller
        name="rename"
        control={control}
        defaultValue={''}
        rules={{ required: true }}
        render={({ field }) => (
          <TextSnippetWrapper
            type="text"
            {...field}
            onChange={(e) => {
              field.onChange(e);
            }}
            placeholder="File Name"
            className="mt-2 mb-2 w-100 input-shadow"
          />
        )}
      />
      {errors?.rename && <InputErrorMessage>Required</InputErrorMessage>}
    </Dialog>
  );
}

export default RenameModal;
