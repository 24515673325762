import { performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const createclientIntakeService = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.createClientForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${
    data?.form_type ? `&form_type=${data?.form_type}` : ''
  }${data?.form_sk_to_duplicate ? `&form_sk_to_duplicate=${encodeURIComponent(data?.form_sk_to_duplicate)}` : ''}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const updateclientIntakeService = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.updateClientForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.PUT, url, data);
};

export const listclientIntake = (params, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints?.intakeForm.listClientForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${params ? `&${params}` : ''}`;
  return performRequestThird(apiEndPoints?.methodType?.POST, url, data);
};

export const deleteclientIntakeService = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.deleteClientForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url, data);
};

export const getClientIntakeService = (params) => {
  let url = `${apiEndPoints.intakeForm.getClientForm}${params ? `?${params}` : ''}`;
  return performRequestThird(apiEndPoints.methodType.GET, url, null, null, false, true);
};

export const shareClientIntakeService = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.shareClientForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${params ? `&${params}` : ''}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const saveClientIntakeService = (data, params) => {
  let url = `${apiEndPoints.intakeForm.saveClientForm}${params ? `?${params}` : ''}`;
  return performRequestThird(apiEndPoints.methodType.PUT, url, data, null, false, false);
};

export const getCaseFormsService = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.listCaseForms}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${params ? `&${params}` : ''}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const viewCaseFormsService = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.viewCaseForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${params ? `&${params}` : ''}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const deleteCaseFormService = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.deleteCaseForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.DELETE, url, data);
};

export const resendCaseFormService = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.intakeForm.reSendForm}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${params ? `&${params}` : ''}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};
