import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';

import { useFirmUserList } from 'hooks/useFirmUserList';
import { generateFullName } from 'utils/generateFullNameUtils';
import { handleRedirection } from 'utils/utils';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import ImageComponent from 'shared/ImageComponent';

export default function TeamMember({ selectedList, setSelectedList }) {
  const history = useHistory();
  const { userList } = useFirmUserList();

  useEffect(() => {
    setSelectedList((preVal) => [
      ...preVal,
      ...userList?.filter((user) => !Boolean(preVal?.find((u) => u.firm_user_id === user?.firm_user_id))),
    ]);
  }, [userList]);

  const onChangeHandler = (e) => {
    setSelectedList((preVal) =>
      preVal?.map((val) => {
        if (val?.firm_user_id === e.value) {
          if (e.target.name === 'is_selected' || e.target.name === 'notify_case_updates') {
            if (e.target.name === 'notify_case_updates' && e.checked) {
              return { ...val, [e.target.name]: e.checked, is_selected: true };
            }
            if (e.target.name === 'is_selected' && !e.checked) {
              return {
                ...val,
                [e.target.name]: e.checked,
                is_lead_associate: false,
                is_lead_attorney: false,
                is_lead_paralegal: false,
                is_lead_case_manager: false,
                notify_case_updates: false,
              };
            }
            return { ...val, [e.target.name]: e.checked };
          } else {
            return {
              ...val,
              is_lead_associate: false,
              is_lead_attorney: false,
              is_lead_paralegal: false,
              is_lead_case_manager: false,
              [e.target.name]: true,
              is_selected: true,
            };
          }
        } else if (!(e.target.name === 'is_selected' || e.target.name === 'notify_case_updates')) {
          return { ...val, [e.target.name]: false };
        }
        return { ...val };
      })
    );
    // isChecked();
  };

  // const isChecked = () => {
  //   setSelectedList((preVal) =>
  //     preVal.map((val) => {
  //       if (
  //         !val.is_lead_attorney &&
  //         !val.is_lead_associate &&
  //         !val.is_lead_paralegal &&
  //         !val.is_lead_case_manager &&
  //         !val.notify_case_updates
  //       ) {
  //         return { ...val, is_selected: false };
  //       }
  //       return { ...val };
  //     })
  //   );
  // };

  const getSelectedItem = (name) => selectedList?.find((v) => v?.[name] === true)?.firm_user_id;

  const nameTemplate = (rowData) => {
    const { sk, pk, firm_user_id } = rowData;
    let url = '/settings/team-management';
    let stateToPass = {};

    if (firm_user_id) {
      url = firm_user_id ? `/settings/team-management/${firm_user_id}` : '/settings/team-management';
      stateToPass = rowData;
    }

    return (
      <div
        className="d-flex flex-column flex-sm-row w-100"
        onClick={() => {
          handleRedirection(history, url, stateToPass);
          handleSettingSkAndPkToLocalStorage(sk, pk);
        }}
      >
        <span className="p-column-title text-break d-none">Lead Attorney</span>
        <ImageComponent filePath={rowData?.profile_image} fileName={rowData?.first_name} fileSize="small.jpg" className="col-1 me-2" />
        <span className="d-flex align-items-center text-break redirection-link-no-text-transform">{generateFullName(rowData)}</span>
      </div>
    );
  };

  const notifyTemplate = (rowData) => {
    return (
      <div className="d-flex flex-column flex-sm-row w-100 justify-content-center">
        <span className="p-column-title text-break d-none">Enable Lawft Access</span>
        <Checkbox
          id="team_notify_checkbox"
          checked={rowData.notify_case_updates}
          name="notify_case_updates"
          value={rowData.firm_user_id}
          onChange={onChangeHandler}
          disabled={!(rowData?.status === 'ACTIVE')}
        />
      </div>
    );
  };
  const selectColumnTemplate = (rowData) => {
    return (
      <Checkbox
        id="is_selected"
        checked={rowData?.is_selected}
        name="is_selected"
        value={rowData.firm_user_id}
        onChange={onChangeHandler}
        disabled={!(rowData.status === 'ACTIVE')}
      />
    );
  };

  return (
    <Card className="shadow-middle billing-preferences F-size14">
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-4 p-medium">Lead Attorney</div>
        <div className="col-6">
          <Dropdown
            name="is_lead_attorney"
            optionLabel={(v) => generateFullName(v)}
            optionValue="firm_user_id"
            options={userList}
            onChange={onChangeHandler}
            className="input-shadow w-100 border-0"
            value={getSelectedItem('is_lead_attorney')}
          />
        </div>
      </div>

      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-4 p-medium">Lead Associate</div>
        <div className="col-6">
          <Dropdown
            name="is_lead_associate"
            optionLabel={(v) => generateFullName(v)}
            optionValue="firm_user_id"
            options={userList}
            onChange={onChangeHandler}
            className="input-shadow w-100 border-0"
            value={getSelectedItem('is_lead_associate')}
          />
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-4 p-medium">Lead Paralegal</div>
        <div className="col-6">
          <Dropdown
            name="is_lead_paralegal"
            optionLabel={(v) => generateFullName(v)}
            optionValue="firm_user_id"
            options={userList}
            onChange={onChangeHandler}
            className="input-shadow w-100 border-0"
            value={getSelectedItem('is_lead_paralegal')}
          />
        </div>
      </div>
      <div className="d-flex align-items-center py-2 flex-wrap">
        <div className="col-4 p-medium">Lead Case Manager</div>
        <div className="col-6">
          <Dropdown
            name="is_lead_case_manager"
            optionLabel={(v) => generateFullName(v)}
            optionValue="firm_user_id"
            options={userList}
            onChange={onChangeHandler}
            className="input-shadow w-100 border-0"
            value={getSelectedItem('is_lead_case_manager')}
          />
        </div>
      </div>
      <div className="d-flex mb-3">
        <div className="col-12">
          <DataTable
            value={selectedList}
            responsiveLayout="scroll"
            emptyMessage="No Data Found."
            selectionMode="checkbox"
            className="case-team-member"
          >
            <Column body={selectColumnTemplate} style={{ width: 25 }} headerStyle={{ width: 25 }} />
            <Column field="name" header="Name" className="add-contact-name" body={nameTemplate} />
            <Column
              field="notify"
              header="Notify About Case Updates"
              style={{ width: 100, textAlign: 'center' }}
              headerStyle={{ textAlign: 'center' }}
              body={notifyTemplate}
            />
          </DataTable>
        </div>
      </div>
    </Card>
  );
}
