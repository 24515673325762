import React from 'react';
import Router from './routers';
import ToastProvider from 'context/ToastContext';
import { UserDetailsProvider } from 'context/userDetailsContext';
import { TempStorageProvider } from 'context/tempStorageContext';
import { OutLookProvider } from 'context/OutlookContext';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { outlookConfig } from 'config/calendar_config.js';
import { GoogleProvider } from 'context/GoogleContext';
import { WebSocketConnectionProvider } from 'context/WebSocketConnectionContext';
import { NavBarContextProvider } from 'context/NavbarContext';
import { LoaderContext } from 'context/LoaderContext.jsx';
// import GlobalLoader from "components/GlobalLoader/GlobalLoader";
import { Helmet } from 'react-helmet-async';

const env = process.env.REACT_APP_ENV; // Read environment variable

const robotsValue = env === 'qa' || env === 'staging' || env === 'dev' ? 'noindex, nofollow' : 'index, follow';

function App() {
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: outlookConfig.appId,
      redirectUri: outlookConfig.redirectUri,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  });
  const accounts = msalInstance.getAllAccounts();
  if (accounts && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      // Set the active account - this simplifies token acquisition
      const authResult = event.payload;
      msalInstance.setActiveAccount(authResult.account);
    }
  });

  return (
    <>
      <Helmet>
        <meta name="robots" content={robotsValue} />
      </Helmet>

      <LoaderContext>
        <UserDetailsProvider>
          <ToastProvider>
            <NavBarContextProvider>
              <GoogleProvider>
                <WebSocketConnectionProvider>
                  <MsalProvider instance={msalInstance}>
                    <OutLookProvider>
                      <TempStorageProvider>
                        {/* <GlobalLoader /> */}
                        <Router />
                      </TempStorageProvider>
                    </OutLookProvider>
                  </MsalProvider>
                </WebSocketConnectionProvider>
              </GoogleProvider>
            </NavBarContextProvider>
          </ToastProvider>
        </UserDetailsProvider>
      </LoaderContext>
    </>
  );
}

export default App;
