import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { presignedURLGet } from '../services';
import { useNavbarContext } from 'context/NavbarContext';

export default function useFileDownload() {
  const { downloadFile: downloadAs } = useNavbarContext();
  const location = useLocation();

  const downloadFile = async (file) => {
    let file_path = file?.file_path;
    let file_name = file?.display_name ? file?.display_name : location?.state?.case_details?.display_name;
    let version_id = file?.version_id;
    let data = await presignedURLGet(file_path, version_id);
    let url = data?.data?.file_url;
    downloadAs(url, file_name);
  };

  const downloadCertFile = async (file) => {
    let file_path = file?.esignature_request?.cirtificate_url;
    let file_name = file?.esignature_request?.request_name;
    let version_id = '';
    let data = await presignedURLGet(file_path, version_id);
    let url = data?.data?.file_url;
    downloadAs(url, file_name);
  };

  return { downloadFile, downloadCertFile };
}
