import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { useEffect, useMemo, useState } from 'react';
import { listclientIntake, shareClientIntakeService } from 'services/intakeforms/clientIntakeService';
import { convertObjectToQuery } from 'utils/utils';
import { getClientUserList } from 'services/case/caseServices';
import { generateFullName } from 'utils/generateFullNameUtils';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';
// import { getCaseList } from 'services/case/caseServices';

export default function useShareIntakeForms(case_id, pa) {
  const { addToast } = useToast();
  const [clientData, setClientData] = useState([]);
  const { practiceAreaList } = usePracticeAreaList();

  const paSK = useMemo(() => practiceAreaList.filter((v) => v.practice_area_name === pa).map((v) => v?.sk), [practiceAreaList, pa]);

  const getContactList = (caseId) => {
    getClientUserList({
      caseId,
      is_mandatory_lawft_access: false,
      is_team_member_list: false,
      all_contacts: true,
      is_active: true,
      is_include_groups: false,
      active_groups: true,
    })
      .then((response) => setClientData(response.data?.map((v) => ({ fullName: generateFullName(v), ...v }))))
      .catch((error) => console.log(error));
  };

  const shareIntakeFrom = (params) => {
    return shareClientIntakeService(convertObjectToQuery(params))
      .then((res) => {
        if (res?.data?.recipient === 'no recipient/invalid email' || !res?.data?.recipient) {
          navigator.clipboard.writeText(res?.data?.lawft_link);
          addToast(false, toastConstant.toasterType.WARNING, '', toastConstant.message.CLIPBOARD_COPY_SUCCCESS);
        } else {
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.SHARE_INTAKE_SUCCESS);
        }
      })
      .catch((err) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.SHARE_INTAKE_FAILED);
      });
  };

  // const onCaseSearch = async (keyword = '') => {
  //   const list = await getCaseList({
  //     data: {
  //       practice_area: pa,
  //     },
  //     limit: 20,
  //     searchValue: keyword,
  //   });
  //   return (
  //     list?.data?.case.map((item) => {
  //       return {
  //         label:
  //           item && item.case_description && item.case_description.case_name
  //             ? `${item.unique_number ? `${item.unique_number} ` : ''}${item.case_description.case_name}`
  //             : 'TBD',
  //         case_name: item?.case_description?.case_name || 'TBD',
  //         practice_area: item?.case_description?.case_practice_area,
  //         id: item.case_id,
  //         is_lead: item?.is_lead ? item?.is_lead : false,
  //       };
  //     }) || []
  //   );
  // };

  const IntakeSearch = async (keyword = '') => {
    const qs = {
      limit: 20,
      form_type: 'contact_form',
      keyword,
    };
    const list = await listclientIntake(convertObjectToQuery(qs), { practice_area: paSK, within_case: true });
    return list?.data?.contact_forms || [];
  };

  useEffect(() => {
    if (case_id) {
      getContactList(case_id);
    }
  }, []);

  return { clientData, practiceAreaList, IntakeSearch, shareIntakeFrom, getContactList };
}
