import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import LabelName from 'components/UI/LabelName/LabelName';
import {
  invoiceReminderOptions,
  reminderChannels,
  reminderCountOptions,
  reminderCustomOptions,
  reminderInvoiceCustomOption,
  reminderOptions,
} from 'constants/dropdownOptions';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

function EventReminders({ watch, control, isGlobal, isFromInvoice, errors, setValue, isRequired, isPayment = false, optionText = '' }) {
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'reminder',
    rules: {
      validate: (value) => ((value && value.length > 0) || !isRequired ? true : 'Please add at least one reminder.'),
    },
  });

  function onAddReminder() {
    insert(fields.length, { value: isPayment ? '30#minutes#past_due' : '30#minutes#', reminder_channel_type: 'inapp_notification' });
  }
  function onDeleteReminder(index) {
    remove(index);
  }

  const caseId = watch('case') || watch('case_id');

  return (
    <div className="d-flex py-2 flex-wrap w-100">
      {!isFromInvoice && (
        <div className="col-md-4 col-12 mt-3">
          <LabelName required={isRequired}>Reminders</LabelName>
        </div>
      )}
      <div className={isFromInvoice ? 'w-100' : 'col-md-8 col-12 mt-2'}>
        {fields?.length > 0 &&
          fields.map((data, index) => {
            let reminderValue = watch(`reminder.${index}.value`);
            return (
              <div
                className="d-flex input-shadow align-items-center justify-content-center p-2 mb-2 bg-grey"
                style={{ borderRadius: 12 }}
                key={data.id}
              >
                <div className="w-100">
                  <div>
                    <Controller
                      name={`reminder.${index}.reminder_channel_type`}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          placeholder="Channel"
                          name={field.name}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          options={
                            reminderChannels?.filter((item) => !(item?.value === 'secure_message' && !Boolean(caseId) && isGlobal)) || []
                          }
                          className="w-100"
                          filter
                        />
                      )}
                    />
                  </div>
                  <div className="mt-2">
                    <Controller
                      name={`reminder.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          placeholder="Add"
                          name={field.name}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                            if (e.value === 'custom') {
                              setValue(`reminder.${index}.customValue`, 1);
                              setValue(`reminder.${index}.minutes`, 'minutes');
                              setValue(`reminder.${index}.type`, 'minutes');
                              setValue(`reminder.${index}.past_due`, '');
                            } else {
                              setValue(`reminder.${index}.customValue`, null);
                              setValue(`reminder.${index}.minutes`, null);
                              setValue(`reminder.${index}.type`, null);
                              setValue(`reminder.${index}.past_due`, null);
                            }
                          }}
                          options={
                            isPayment
                              ? invoiceReminderOptions
                              : reminderOptions?.map((item) => ({
                                  ...item,
                                  label: item?.value === 'custom' ? item.label : `${item?.label} ${optionText}`,
                                }))
                          }
                          className="w-100"
                          filter
                        />
                      )}
                    />
                  </div>

                  {reminderValue === 'custom' && (
                    <div className="d-flex flex-column">
                      <div className="d-flex mt-2 gap-2">
                        <Controller
                          name={`reminder.${index}.customValue`}
                          control={control}
                          defaultValue={1}
                          render={({ field }) => (
                            <Dropdown
                              placeholder="Add"
                              name={field.name}
                              id={field.name}
                              className="w-100"
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={reminderCountOptions}
                              filter
                            />
                          )}
                        />

                        <Controller
                          name={`reminder.${index}.type`}
                          control={control}
                          defaultValue="minutes"
                          render={({ field }) => (
                            <Dropdown
                              placeholder="Select Type"
                              name={field.name}
                              id={field.name}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={reminderCustomOptions}
                              filter
                            />
                          )}
                        />
                      </div>
                      {isPayment && (
                        <Controller
                          name={`reminder.${index}.past_due`}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Dropdown
                              placeholder="Select any"
                              name={field.name}
                              id={field.name}
                              value={field.value}
                              onChange={(e) => field.onChange(e.value)}
                              options={reminderInvoiceCustomOption}
                              filter
                              className="w-100 mt-2"
                            />
                          )}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div
                  onClick={(e) => {
                    onDeleteReminder(index);
                  }}
                  className="pointer"
                  style={{ marginLeft: '0.5rem' }}
                >
                  <i className="pi icon-delete"></i>
                  <Tooltip content="Delete Reminder" position="top" target=".icon-delete" showDelay={500} />
                </div>
              </div>
            );
          })}
        <div className="mt-2 ">
          <span className="pointer text-primary" onClick={onAddReminder}>
            + Add Reminder
          </span>
        </div>
      </div>
      <div className="offset-md-4">
        {errors?.reminder?.root?.type === 'validate' && <InputErrorMessage>{errors?.reminder?.root?.message}</InputErrorMessage>}
      </div>
    </div>
  );
}

export default EventReminders;
