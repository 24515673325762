import React, { useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import { classNames } from 'primereact/utils';
import { debounce } from 'lodash';

import { useUserDetailsContext } from 'context/userDetailsContext';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';
import Message from './Message';
import Loader from './Loader';

export const InfiniteList = ({ listRef, messages, loading, fetchNextItems }) => {
  const { userDetails, profileData } = useUserDetailsContext();
  const rowHeights = useRef({});

  const isMessageLoaded = (index) => index < messages.length;

  const onItemsRendered = debounce(({ scrollOffset }) => {
    if (scrollOffset === 0) {
      fetchNextItems();
    }
  }, 200);

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index) {
    const message = messages[index];
    const t1 = handleDateTimeOffset(userDetails?.timezone, message?.time, constants.month_date_year_format);

    const t2 = handleDateTimeOffset(userDetails?.timezone, messages[index - 1]?.time, constants.month_date_year_format);
    const isGroupHeader = t1 !== t2;
    return rowHeights.current[index] + (isGroupHeader ? 33 : 1) || 150;
  }

  const Row = ({ index, style }) => {
    if (!isMessageLoaded(index)) {
      return null;
    }
    const message = messages[index];

    const myself = profileData?.client_cognito_username === message?.created_by_user_id;

    const t1 = handleDateTimeOffset(userDetails?.timezone, message?.time, constants.month_date_year_format);

    const t2 = handleDateTimeOffset(userDetails?.timezone, messages[index - 1]?.time, constants.month_date_year_format);

    const isGroupHeader = t1 !== t2;

    return (
      <div
        style={{ ...style, borderBottom: 'none' }}
        className={classNames({ 'msg-container': true, 'mb-0 pt-0 pb-0': true })}
        id="msgContainer"
        key={index}
      >
        {isGroupHeader && (
          <div className="text-center msg-filter">
            <span>{handleDateTimeOffset(userDetails.timezone, message?.time, constants.month_date_year_format)}</span>
          </div>
        )}
        <Message {...{ message, index, setRowHeight, myself, userDetails }} />
      </div>
    );
  };

  let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

  return (
    <div>
      {loading && <Loader />}
      <List
        height={vh - 250}
        itemCount={loading ? messages.length + 1 : messages.length}
        itemSize={getRowHeight}
        onScroll={onItemsRendered}
        ref={listRef}
        layout="vertical"
        className="sidebar-list"
        initialScrollOffset={messages.length * 30} // Set initial scroll offset to show the bottom of the list
        initialScrollOffsetAlignment="end" // Align initial scroll offset to the bottom
        overscanCount={5}
      >
        {Row}
      </List>
    </div>
  );
};
