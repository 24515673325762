import React from 'react';
import { Link } from 'react-router-dom';

import { AutoComplete } from 'primereact/autocomplete';
import { Card } from 'primereact/card';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';

import useCompanionCase from 'modules/cases/hooks/useCompanionCase';

export default function CompanionCases(props) {
  const { caseDetails, isCaseClosed } = props;
  const { loading, results, companionList, field, setField, searchAsync, createCompanion, deleteCompanion } = useCompanionCase(
    caseDetails?.case_id
  );

  const createLInk = () => {
    createCompanion(field?.map((v) => v?.case_id));
  };

  const confirmDelete = (case_id) => {
    confirmDialog({
      message: 'Are you sure want to delete this companion case?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteCompanion(case_id),
    });
  };

  return (
    <>
      <div className="caption-bold mb-3">Companion Case</div>
      <Card className="mb-4 shadow-middle F-size14">
        <div className="row">
          <div className="d-flex align-items-top F-size14 flex-wrap">
            <div className="col-md-12 col-12 align-items-center edit-field">
              <div className="mb-3 d-flex align-items-end flex-column">
                <AutoComplete
                  placeholder="Search Existing Case"
                  style={{ width: '100%' }}
                  inputStyle={{ width: '100%' }}
                  completeMethod={searchAsync}
                  value={field}
                  multiple
                  suggestions={results}
                  loading={loading}
                  field="case_description.case_name"
                  onChange={(e) => setField(e.value)}
                  className="flex-grow-1 input-shadow"
                  disabled={isCaseClosed}
                />
                <Button disabled={field?.length === 0} className="p-button p-button-secondary mt-2" onClick={createLInk}>
                  Link
                </Button>
              </div>
              <div className="mb-2">
                {!!companionList?.length &&
                  companionList.map((data) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <Link to={`/cases/${data?.case_id}`} className="a text-break pointer F-size14 call-back text-decoration-none">
                          {data?.case_name}
                        </Link>
                        <span role="button" className={props.isCaseClosed ? 'pe-none opacity-50' : ''}>
                          <i className="icon-delete ms-2" onClick={(e) => confirmDelete(data?.case_id)} id="delete">
                            <Tooltip target={'.icon-delete'} position={'bottom'} className="case-tooltip mb-3" showDelay={500}>
                              Delete
                            </Tooltip>
                          </i>
                        </span>
                      </div>
                    );
                  })}
                {companionList?.length === 0 && <>No Companion Cases</>}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
}
