import React, { useState, useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { SelectButton } from 'primereact/selectbutton';
import { MultiSelect } from 'primereact/multiselect';

import { listUsers } from 'services/users/userServices';
import { createNotes } from 'services/CaseNoteServices';
import { getNestedProperty, formatContactList, sortByKey } from 'utils/utils';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { totalCountEvent } from 'services/generalServices';
import { generateFullName } from 'utils/generateFullNameUtils';
import { ModalHeader } from 'shared/ModalHeader';
import { listContacts } from 'services/lead/leadServices';
import { useCaseList } from '../hooks/useCaseList';
import { callTypeOptions, noteTypeOptions } from 'constants/dropdownOptions';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import { ChangeCaseUserData } from '../Helpers/ChangeCaseUserData';
import { formatCreateNote } from '../Helpers/formatCreateNote';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const itemTemplate = (e) => generateFullName(e);

const caseTemplate = (c) => c.case_description?.case_name;
const autocompleteValidation = (e) => (typeof e === 'object' ? true : 'Field Required');

const NotesCreate = (props) => {
  const {
    isLeadSpecific,
    caseDetails,
    isFromCaseSpecificModule,
    isFromDashboard,
    show,
    setIsGlobal,
    setShow,
    fetchCaseNotes,
    onMountUnMount,
    onOperationBreak,
  } = props;
  let { id } = useParams();
  const { addToast } = useToast();
  const history = useHistory();
  const case_id = caseDetails?.case_id || id;

  const [userList, setUserList] = useState([]);

  const { caseList, loadCaseList } = useCaseList();
  const [filteredClients, setFilteredClients] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('create-note', 'mounted');
    return () => {
      isFromCaseSpecificModule && typeof onOperationBreak === 'function' && onOperationBreak();
    };
  }, []);

  useEffect(() => {
    loadUserList();
  }, [case_id]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]); // to remove the dropdown scroll issue in dialog dropdown

  const handleClose = () => {
    setIsGlobal(false);
    setShow(false);
  };

  const loadUserList = () => {
    totalCountEvent('user').then((response) => {
      listUsers({ is_include_groups: true, is_confirmed_user: true, status: 'ACTIVE', limit: response.data, active_groups: true })
        .then((response) => setUserList(response.data?.firm_users || []))
        .catch((error) => console.log(error));
    });
  };

  const formatUserList = useCallback(
    (isGroup) => {
      if (isGroup) {
        const formattedList = formatContactList(userList);
        const sortedList = formattedList?.map((item) =>
          item?.code === 'Users' ? { ...item, items: sortByKey(item.items, 'first_name') } : item
        );
        return ChangeCaseUserData(sortedList || []);
      } else {
        const filterDataWithoutGroups = userList?.filter((item) => item?.res_type !== 'user-group') || [];
        return ChangeCaseUserData(sortByKey(filterDataWithoutGroups, 'first_name'));
      }
    },
    [userList]
  );

  const searchCase = (event) => loadCaseList({ limit: 20, filter: { keyWord: event?.query } });

  const handleSaveNote = (data) => {
    setLoading(true);
    const caseName = caseDetails?.case_description?.case_name || data?.case_id?.case_description?.case_name;
    let _case_id = case_id || data?.case_id?.case_id;
    createNotes({ data: formatCreateNote({ ...data, case_id: _case_id, case_name: caseName }), is_lead: isLeadSpecific })
      .then((response) => {
        isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('create-note', 'unmounted');
        fetchCaseNotes({});
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.NOTE_SUCCESS);
        isFromDashboard && history.push(`/cases/${_case_id}/notes`);
        handleClose();
      })
      .catch((error) => {
        isFromCaseSpecificModule && typeof onOperationBreak === 'function' && onOperationBreak();
        console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const searchAsync = async (e) => {
    try {
      const response = await listContacts({ contactType: '', keyWord: e?.query, pageLimit: 20, excludeFromLoading: true, in_lead: true });
      setFilteredClients(response?.data?.contacts);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
    trigger,
    reset,
  } = useForm({ defaultValues: { note_type: 'case_note' } });

  const noteOnchange = () => {
    const values = getValues();
    reset({
      note_type: values?.note_type === 'case_note' ? 'call_log' : 'case_note',
      title: values?.title,
      description: values?.description,
    });
    trigger();
  };

  const RequiredValidation = ({ field, message = 'Field Required' }) => {
    return getNestedProperty(errors, field) ? (
      <InputErrorMessage>{getNestedProperty(errors, field)?.message || message}</InputErrorMessage>
    ) : null;
  };

  const assignToItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {option?.assignee_name}
      </>
    );
  };

  const assignToCallLogItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {option?.label}
      </>
    );
  };

  const assignToCallForItemTemplate = (option) => {
    return (
      <>
        {option?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
        {option?.label}
      </>
    );
  };

  return (
    <Dialog className="new-task-popup" visible onHide={handleClose} header={ModalHeader} footer={<></>}>
      <div className="row">
        <div className="col-12 col-lg-12 d-flex justify-content-between pb-3">
          <div className="d-flex align-items-center justify-content-start">
            <h5 className="sub-title mb-0">Create New Note</h5>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12 d-flex align-items-center p-medium">Select Note Type</div>
          <div className="col-md-8 col-12">
            <Controller
              name="note_type"
              control={control}
              rules={{ required: true }}
              defaultValue={'case_note'}
              render={({ field: { value } }) => (
                <SelectButton
                  className="btn-switch"
                  value={value}
                  options={noteTypeOptions}
                  onChange={noteOnchange}
                  optionLabel="name"
                  unselectable={false}
                />
              )}
            />
          </div>
        </div>

        {isFromDashboard && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 d-flex align-items-center p-medium">
              Case
              <span className="alert-star"> *</span>
            </div>
            <div className="col-md-8 col-12">
              <Controller
                name="case_id"
                control={control}
                rules={{ required: 'Case is required' }}
                render={({ field: { value, onChange } }) => (
                  <AutoComplete
                    placeholder="Select Case"
                    suggestions={caseList || []}
                    completeMethod={searchCase}
                    itemTemplate={caseTemplate}
                    selectedItemTemplate={caseTemplate}
                    className="w-100 new-task-popup input-shadow"
                    onChange={(e) => onChange(e.value)}
                    value={value}
                  />
                )}
              />
              <RequiredValidation field={'case_id'} />
            </div>
          </div>
        )}

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-md-4 col-12  p-medium">
            Title <span className="alert-star"> *</span>
          </div>
          <div className="col-md-8 col-12">
            <Controller
              name="title"
              control={control}
              rules={{ required: 'Title is Required' }}
              render={({ field }) => (
                <TextSnippetWrapper id={field.name} placeholder="Title" type="text" className="w-100 input-shadow" {...field} />
              )}
            />
            <RequiredValidation field={'title'} />
          </div>
        </div>

        <div className="d-flex align-items-start py-2 flex-wrap">
          <div className="col-md-4 col-12 p-medium">
            Details<span className="alert-star"> *</span>
          </div>
          <div className="col-sm-8 col-12">
            <Controller
              name="description"
              control={control}
              rules={{ required: 'Description is Required' }}
              render={({ field }) => (
                <TextSnippetWrapper
                  type="textarea"
                  rows="5"
                  cols={30}
                  placeholder="Details"
                  className="w-100 input-shadow"
                  id="description"
                  {...field}
                />
              )}
            />
            <RequiredValidation field={'description'} />
          </div>
        </div>

        {watch('note_type') === 'case_note' ? (
          <>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-sm-4 p-medium">
                <label htmlFor="cb1" className="p-checkbox-label">
                  Need Resolution
                </label>
              </div>
              <div className="col-sm-8 d-flex align-items-center ml-sm-10">
                <Controller
                  name="resolution_status"
                  control={control}
                  defaultValue={false}
                  render={({ field: { name, ref, onChange, value }, fieldState }) => (
                    <Checkbox
                      inputId={name}
                      ref={ref}
                      onChange={(e) => onChange(e.checked)}
                      checked={value}
                      className={{ 'p-invalid': fieldState.invalid }}
                    />
                  )}
                />
              </div>
            </div>

            {/* CASENOTE 1 */}
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-sm-4 col-12 p-medium">
                Assign to Firm User{watch('resolution_status') && <span className="alert-star"> *</span>}
              </div>
              <div className="col-sm-8 col-12">
                <Controller
                  name="assign_to"
                  control={control}
                  rules={{ required: watch('resolution_status') ? 'Assign To is Required' : false }}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelect
                      options={formatUserList(true) ?? []}
                      className="input-shadow w-100"
                      filter
                      placeholder="Select"
                      display="chip"
                      value={value}
                      onChange={(e) => {
                        onChange(e.value);
                      }}
                      optionValue={(v) => v}
                      optionGroupLabel="assignee_name"
                      optionLabel="assignee_name"
                      optionGroupChildren="items"
                      resetFilterOnHide
                      emptyFilterMessage="No records found"
                      showSelectAll={false}
                      itemTemplate={assignToItemTemplate}
                    />
                  )}
                />
                <RequiredValidation field={'assign_to'} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-sm-4 col-12 d-flex align-items-center p-medium">
                Call Type <span className="alert-star"> *</span>
              </div>
              <div className="col-sm-8 col-12">
                <Controller
                  name="call_type"
                  control={control}
                  defaultValue="incoming"
                  rules={{ required: 'Call Type is Required' }}
                  render={({ field: { name, value, onChange } }) => (
                    <Dropdown
                      id={name}
                      options={callTypeOptions}
                      onChange={(e) => onChange(e.value)}
                      value={value}
                      placeholder="Select a Call Type"
                      className="w-100"
                      filter
                    />
                  )}
                />
                <RequiredValidation field={'call_type'} />
              </div>
            </div>

            {watch('call_type') === 'incoming' && (
              <>
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-sm-4 col-12  p-medium">
                    Caller Name<span className="alert-star"> * </span>
                  </div>
                  <div className="col-sm-8 col-12">
                    <Controller
                      name="caller_name"
                      control={control}
                      rules={{ required: 'Caller Name is required', validate: autocompleteValidation }}
                      render={({ field: { value, onChange } }) => (
                        <AutoComplete
                          style={{ width: '100%' }}
                          inputStyle={{ width: '100%' }}
                          placeholder="Caller Name"
                          suggestions={filteredClients}
                          itemTemplate={itemTemplate}
                          selectedItemTemplate={itemTemplate}
                          completeMethod={searchAsync}
                          className="w-100 input-shadow"
                          label="first_name"
                          onChange={(e) => onChange(e.value)}
                          value={value}
                          onBlur={() => typeof value !== 'object' && onChange('')}
                        />
                      )}
                    />
                    <RequiredValidation field={'caller_name'} />
                  </div>
                </div>

                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-sm-4 col-12  p-medium">
                    Call For <span className="alert-star"> *</span>
                  </div>
                  <div className="col-sm-8 col-12">
                    <Controller
                      name="call_for"
                      control={control}
                      rules={{ required: 'Call For is Required' }}
                      render={({ field: { value, onChange } }) => (
                        <MultiSelect
                          options={formatUserList() ?? []}
                          className="w-100 input-shadow"
                          filter
                          placeholder=""
                          filterPlaceholder="Search"
                          display="chip"
                          optionLabel="label"
                          optionValue={(v) => v}
                          value={value}
                          emptyFilterMessage="No records found"
                          onChange={(e) => onChange(e.value)}
                          resetFilterOnHide
                          showSelectAll={false}
                          itemTemplate={assignToCallForItemTemplate}
                        />
                      )}
                    />
                    <RequiredValidation field={'call_for'} />
                  </div>
                </div>
              </>
            )}

            {watch('call_type') === 'outgoing' && (
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-sm-4 col-12 p-medium">
                  Person Called<span className="alert-star"> *</span>
                </div>
                <div className="col-sm-8 col-12">
                  <Controller
                    name="person_called"
                    control={control}
                    rules={{ required: 'Person Called is Required', validate: autocompleteValidation }}
                    render={({ field: { onChange, value } }) => (
                      <AutoComplete
                        placeholder="Person Called"
                        style={{ width: '100%' }}
                        inputStyle={{ width: '100%' }}
                        suggestions={filteredClients}
                        itemTemplate={itemTemplate}
                        selectedItemTemplate={itemTemplate}
                        completeMethod={searchAsync}
                        field="first_name"
                        className="w-100 input-shadow"
                        onChange={(e) => onChange(e.value)}
                        value={value}
                        onBlur={() => typeof value !== 'object' && onChange('')}
                      />
                    )}
                  />
                  <RequiredValidation field={'person_called'} />
                </div>
              </div>
            )}

            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-sm-4 p-medium">
                <label htmlFor="cb1" className="p-checkbox-label">
                  Need Resolution
                </label>
              </div>
              <div className="col-sm-8 d-flex align-items-center ml-sm-2">
                <Controller
                  name="resolution_status"
                  control={control}
                  defaultValue={false}
                  render={({ field: { ref, name, onChange, value }, fieldState }) => (
                    <Checkbox
                      inputId={name}
                      ref={ref}
                      onChange={(e) => onChange(e.checked)}
                      checked={value}
                      className={{ 'p-invalid': fieldState.invalid }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-sm-4 col-12 p-medium">
                Assign to Firm User {watch('resolution_status') && <span className="alert-star"> *</span>}
              </div>
              <div className="col-sm-8 col-12">
                <Controller
                  name="assign_to"
                  control={control}
                  rules={{ required: watch('resolution_status') ? 'Assignee is Required' : false }}
                  render={({ field: { value, onChange } }) => (
                    <MultiSelect
                      options={formatUserList() ?? []}
                      className="w-100 input-shadow"
                      filter
                      filterPlaceholder="Search"
                      placeholder="Select"
                      display="chip"
                      optionLabel="label"
                      optionValue={(v) => v}
                      value={value}
                      onChange={(e) => onChange(e.value)}
                      resetFilterOnHide
                      showSelectAll={false}
                      emptyFilterMessage="No records found"
                      itemTemplate={assignToCallLogItemTemplate}
                    />
                  )}
                />
                <RequiredValidation field={'assign_to'} />
              </div>
            </div>

            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-sm-4 col-12 p-medium">
                Call Date<span className="alert-star"> *</span>
              </div>
              <div className="col-sm-8 col-12">
                <Controller
                  name="call_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, value, onChange } }) => (
                    <Calendar
                      className="w-100 input-shadow"
                      monthNavigator
                      yearNavigator
                      yearRange="1990:3000"
                      id={name}
                      value={value}
                      onChange={(e) => onChange(e.value)}
                      showButtonBar
                      showTime
                    />
                  )}
                />
                <RequiredValidation field={'call_date'} />
              </div>
            </div>

            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-sm-4 col-12 p-medium">
                Phone Number<span className="alert-star"> *</span>
              </div>
              <div className="col-sm-8 col-12">
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    minLength: { value: 10, message: 'Min length should be 10' },
                    required: 'Phone number is required',
                  }}
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      className="input-shadow w-100 p-2 border-0 number-format"
                      placeholder="Phone"
                      format="(###) ###-####"
                      mask="_"
                      name="phone"
                      onValueChange={(e) => onChange(e.value)}
                      value={value}
                    />
                  )}
                />
                <RequiredValidation field={'phone'} />
              </div>
            </div>
          </>
        )}

        <div className="footer-btn">
          <Button label="Cancel" className="p-button-secondary outline" onClick={handleClose} />
          <Button
            type="button"
            label="Save"
            className="p-button p-component p-button p-button-secondary ms-2"
            onClick={handleSubmit(handleSaveNote)}
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default NotesCreate;
