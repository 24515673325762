import React from 'react';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function ViewChanger() {
  const {
    currentView,
    setCurrentView,
    fileManagerFilters,
    getFiles,
    pageLimit,
    isGlobalFileManager,
    caseDetails,
    isClientFileManager,
    query,
  } = useFileManagerContext();

  return (
    <div className="d-flex align-items-center justify-content-end p-2 pe-0 change-view">
      <Button
        onClick={() => {
          setCurrentView('list');
          let obj = {
            case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters?.case?.id : caseDetails?.case_id,
            selectedFolder: fileManagerFilters.selectedFolder,
            selectedType: fileManagerFilters.fileType,
            tag: fileManagerFilters.tag,
            query: query,
          };
          getFiles(obj, 'list', pageLimit);
        }}
        className={`list-view mx-2 ${currentView === 'list' ? 'highlight' : ''}`}
      >
        <Tooltip content="List view" position="top" target=".list-view" showDelay={500} />
        <i className="icon-List-view"></i>
      </Button>

      <Button
        onClick={() => {
          setCurrentView('grid');
          let obj = {
            case_id: isGlobalFileManager || isClientFileManager ? fileManagerFilters?.case?.id : caseDetails.case_id,
            selectedFolder: fileManagerFilters.selectedFolder,
            selectedType: fileManagerFilters.fileType,
            tag: fileManagerFilters.tag,
            query: query,
          };
          getFiles(obj, 'grid', pageLimit);
        }}
        className={`grid-view me-2 ${currentView === 'grid' ? 'highlight' : ''}`}
      >
        <Tooltip content="Grid view" position="top" target=".grid-view" showDelay={500} />
        <i className="icon-grid-view"></i>
      </Button>
    </div>
  );
}

export default ViewChanger;
