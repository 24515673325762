import constants from 'constants/index';
import React from 'react';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { handleRedirection } from 'utils/utils';
import { useHistory } from 'react-router-dom';

function LastEditedTemplate(props) {
  const { rowData, userContext, isClientFileManager } = props;
  const history = useHistory();
  let IsoFormattedDate = rowData?.updated || rowData?.version_uploaded || '';

  //TODO:If client is sending a file from their side, they should be able to edit that file. This functionality is not available now in client portal. Need to check and change the response structure.

  // const isClient = rowData?.updated_by_details?.access_level?.toLowerCase() === 'client';
  // let url = '';

  // if (isClient) {
  //   const contactId = rowData?.updated_by_details?.updated_by_contact_id;
  //   url = contactId ? `/contacts/${contactId}` : '/contacts';
  // } else {
  //   const firmUserId = rowData?.updated_by_details?.updated_by_firm_user_id;
  //   url = firmUserId ? `/settings/team-management/${firmUserId}` : '/settings/team-management';
  // }

  const firmUserId = rowData?.updated_by_firm_user_id;
  const url = firmUserId ? `/settings/team-management/${firmUserId}` : '/settings/team-management';

  const onClickUpdatedBy = (e) => {
    e.preventDefault();
    if (!isClientFileManager) {
      handleSettingSkAndPkToLocalStorage(rowData?.sk, rowData?.pk);
      handleRedirection(history, url, rowData);
    }
  };

  return (
    <>
      <span className="p-column-title text-break">Last Edited</span>
      <div className="d-inline-table file-manager-ellipsis-text">
        <span>
          <small
            title={handleDateTimeOffset(userContext?.userDetails?.timezone, IsoFormattedDate, constants?.month_date_year_time_12_format)}
          >
            {handleDateTimeOffset(userContext?.userDetails?.timezone, IsoFormattedDate, constants?.month_date_year_time_12_format)}
          </small>
        </span>
        <div
          className={`text-hint file-manager-ellipsis-text ${!isClientFileManager ? 'cursor-pointer' : ''}`}
          onClick={onClickUpdatedBy}
          title={rowData?.updated_by_details || ''}
        >
          {rowData?.updated_by_details || ''}
        </div>
      </div>
    </>
  );
}

export default LastEditedTemplate;
