import { Button } from 'primereact/button';
import React from 'react';

const TemporaryFundFormButton = (props) => {
  const { handleHideModal, modalType, isUpdateScreen, loading } = props;
  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-end mt-2">
        <Button
          className="p-button-secondary outline"
          label="Cancel"
          onClick={(e) => {
            e.preventDefault();
            handleHideModal();
          }}
        />
        <Button
          className="p-button-secondary ms-2"
          label={isUpdateScreen ? 'Update' : modalType}
          type="submit"
          // onClick={onSubmit}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TemporaryFundFormButton;
